import NavBarTransparent from '../../../components/NavBarTransparent';
import { ChevronRightSvg } from '../../../resources/svg/ChevronRightSvg';
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import { LinkedInSvg } from '../../../resources/svg/LinkedinSvg';
import { TwitterSvg } from '../../../resources/svg/TwitterSvg';
import { LinkSvg } from '../../../resources/svg/LinkSvg';
import React from 'react';
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';
import '../../../resources/css/articles.scss';

const BeatTheHeat = () => {
  const [fbHover, setFbHover] = React.useState('#ADADAD');
  const [liHover, setLiHover] = React.useState('#ADADAD');
  const [twtHover, setTwtHover] = React.useState('#ADADAD');
  const [linkHover, setLinkHover] = React.useState('#ADADAD');

  return (
    <>
      <NavBarTransparent />

      <div className="hero_sec">
        <img
          src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Cool+Climate+Destinations/00+COVER+-+Sebastian+Herrmann+via+Unsplash.jpeg"
          alt=""
          loading="lazy"
          onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
        />

        <div className="gradient_overlay">
          <h1>Travel Tips</h1>

          <div className="bread_crumbs">
            <a href="/#/blogs">Blogs</a>
            <ChevronRightSvg _color="#ffffff" _width={20} _height={20} />
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
              Beat the Heat! Explore the Coldest Places in the Philippine
            </a>
          </div>
        </div>
      </div>

      <div className="articleBody">
        <div className="articleHeader">
          <h1>Beat the Heat! Explore the Coldest Places in the Philippines</h1>

          <div className="info">
            <p className="authorSec">
              By <u>Zoe Alcazaren</u>
            </p>
            <p className="separator">•</p>
            <p className="articleDate">Nov 14, 2023</p>
            <p className="separator">•</p>
            <p className="article-read">6 min read</p>
          </div>

          <div className="shareBtnContainer">
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setFbHover('#006FA9')} onMouseLeave={() => setFbHover('#ADADAD')}>
              <FacebookSvg _color={fbHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLiHover('#006FA9')} onMouseLeave={() => setLiHover('#ADADAD')}>
              <LinkedInSvg _color={liHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setTwtHover('#006FA9')} onMouseLeave={() => setTwtHover('#ADADAD')}>
              <TwitterSvg _color={twtHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLinkHover('#006FA9')} onMouseLeave={() => setLinkHover('#ADADAD')}>
              <LinkSvg _color={linkHover} _width={24} _height={24} />
            </a>
          </div>
        </div>

        <div className="content">
          <div className="articleDescription">
            <p>
              When shortlisting holiday destinations in the Philippines, we typically gravitate towards white-sand beaches and tropical watering holes. However, you’ll be surprised to discover that
              the Philippines is home to many #SweaterWeather holiday recommendations.
            </p>
            <p>
              If you’re looking for somewhere to beat the heat without leaving the country, you’ve come to the right place! We’ve shortlisted the coldest places in the Philippines where your unused
              arsenal of winter clothing can stop gathering dust.
            </p>
          </div>

          <div className="textual_story">
            <h2>What is the weather in the Philippines like?</h2>
            <p>
              The Philippines generally experiences a tropical maritime climate, which means it is typically warm and humid throughout the year. While it isn’t known for experiencing cold weather,
              there are a few places and circumstances where temperatures can be cooler compared to the rest of the country.
            </p>
            <p>
              For example, higher-elevation areas like Baguio in Luzon or parts of Mindanao can have cooler temperatures, especially at night. However, even in these areas, the term "cold" is
              relative, and temperatures rarely drop below what most people from colder climates consider cold.
            </p>
          </div>

          <div className="storieswithTitleImageParagraphs">
            <div className="head">
              <h2>Top 10 coldest places in the Philippines</h2>

              <div className="head_desc">
                <p>Are you ready for amihan season? Add these cold weather destinations of your places to visit during the Ber months!</p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Baguio</h2>
                <div className="img_container">
                  <img
                    className="image"
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Cool+Climate+Destinations/01+Baguio+-+CornerMonkey+via+Unsplash.png"
                    alt="Baguio"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by CornerMonkey via Unsplash</p>
                </div>
              </div>

              <div className="info-grp">
                <p>
                  Known as the City of Pines, Baguio is the Philippines' most popular cold weather destination. Its temperatures average 18 degrees on a January evening, with its weather dipping to an
                  all-time low in February 2021 at 9 degrees.
                </p>
                <p>While Baguio has become significantly urbanized, it still provides a much-needed rural atmosphere for vacationers who want to escape the hustle and bustle of daily Manila life.</p>
                <p>Christmas season is the best time to reap what Baguio has to sow, including its iconic parks, the BenCab museum, and cafes along Session Road.</p>
              </div>

              <div className="bullet_grp">
                <div className="bullet_list">
                  <p>
                    <b>Where to Stay</b>
                  </p>
                  <ul>
                    <li>Camp John Hay</li>
                    <li>Diplomat Hotel</li>
                    <li>Chalet Baguio Hotel</li>
                    <li>Grand Sierra Pines Baguio</li>
                    <li>The Plaza Lodge Baguio</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Sagada</h2>
                <div className="img_container">
                  <img
                    className="image"
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Cool+Climate+Destinations/02+Sagada+-+Ruby+Jane+Motilla.png"
                    alt="Sagada"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by Ruby Jane Motilla via Unsplash</p>
                </div>
              </div>

              <div className="info-grp">
                <p>
                  Sagada is your ideal destination if you’re looking for somewhere more remote. This tranquil town rests atop the Mountain Province of the Cordillera Administrative Region, with
                  evening temperatures dropping to 10 degrees year-round.
                </p>
                <p>
                  Here, you’ll wake up to sunny yet chilly mornings, perfect for a cup of locally brewed coffee or hot chocolate. Regarding what Sagada offers, it’s an inexplicable haven for being one
                  with nature, chock-full of scenic mountain valleys, rock formations, caves, waterfalls, and rice terraces.
                </p>
                <p>Perhaps Sagada’s most alluring attraction is its hanging coffins, evidence of 2,000-year-old Igorot burial traditions that you’ll have to see to believe.</p>
              </div>

              <div className="bullet_grp">
                <div className="bullet_list">
                  <p>
                    <b>Where to Stay</b>
                  </p>
                  <ul>
                    <li>The Shire of Sagada</li>
                    <li>Coffee Heritage House and Hostel</li>
                    <li>Dagdag Village Homestay</li>
                    <li>Aniduwan Lodge Sagada</li>
                    <li>Sagada Hideaway House</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Sagada</h2>
                <div className="img_container">
                  <img
                    className="image"
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Cool+Climate+Destinations/03+Davao+-+Vic+Calag+via+Unsplash.png"
                    alt="Davao"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by Vic Calag via Unsplash</p>
                </div>
              </div>

              <div className="info-grp">
                <p>
                  While not as cool as Baguio or Sagada, Davao temperatures reach a breezy 22 degrees—perfect for exploring its mountains and green landscapes. Davao City is home to Mt. Apo, the
                  highest peak in the country that only veteran trekkers dare climb.
                </p>
                <p>
                  Mt. Apo’s cool winds seep into the territory below it, so you can easily enjoy the fresh breeze without having to conquer all 9,000 feet of its glory. Instead, you can visit the
                  Philippine Eagle Center, home to dozens of forest-dwelling birds, the Roxas Night Market for a midnight snack, or Crocodile Park, which speaks for itself.
                </p>
              </div>

              <div className="bullet_grp">
                <div className="bullet_list">
                  <p>
                    <b>Where to Stay</b>
                  </p>
                  <ul>
                    <li>Dusit Thani Residence Davao</li>
                    <li>Seda Abreeza</li>
                    <li>The Pinnacle Hotel and Suites</li>
                    <li>Hotel Tropika Davao</li>
                    <li>dusitD2 Davao</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Tagaytay</h2>
                <div className="img_container">
                  <img
                    className="image"
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Cool+Climate+Destinations/04+Tagaytay+-+Jannel+Ivory+via+Unsplash.png"
                    alt="Tagaytay"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by Jannel Ivory from Unsplash</p>
                </div>
              </div>

              <div className="info-grp">
                <p>
                  Tagaytay is an evident fan favorite, as it’s just a hop and skip away from Metro Manila by car. It’s ideal for weekend getaways and the occasional whiff of fresh air. Much like
                  Baguio, Tagaytay is highly urbanized, but is relatively freer of the chaotic energy in Metro Manila.
                </p>
                <p>
                  Here, you can enjoy spectacular views of Taal Volcano while having lunch at The Teahouse at Qi Wellness, Taza Fresh Table, Leslie’s, Buon Giorno Caffe and Bistro, and Bulalo Point
                  Turo-Turo.
                </p>
                <p>Tagaytay is now also home to Sky Ranch, an amusement park with thrilling rides like the Drop Tower and Log Coaster.</p>
                <p>Art lovers may want to detour to the Museo Orlina, a privately owned display of pioneering glassworks by Ramon Orlina.</p>
                <p>
                  If you’re looking for something “quirky,” Puzzle Mansion might capture your interest, as it houses 1,028 completed sets—one of the most extensive jigsaw puzzle collections globally.
                </p>
              </div>

              <div className="bullet_grp">
                <div className="bullet_list">
                  <p>
                    <b>Where to Stay</b>
                  </p>
                  <ul>
                    <li>Anya Resort and Residences</li>
                    <li>Twin Lakes Hotel</li>
                    <li>Hotel Monticello</li>
                    <li>Escala Tagaytay</li>
                    <li>Taal Vista Hotel</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Don Salvador Benedicto</h2>
                <div className="img_container">
                  <img
                    className="image"
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Cool+Climate+Destinations/05+Don+Salvador+Benedicto+-+Kent+Tupas+via+Unsplash.png"
                    alt="Don Salvador Benedicto"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by Kent Tupas via Unsplash</p>
                </div>
              </div>

              <div className="info-grp">
                <p>
                  If you’re looking for the feel of Baguio without the overwhelming number of tourists, Don Salvador Benedicto (occasionally known as Little Baguio) is the place to be. Temperatures
                  drop to 16 degrees, and the aroma of pine trees will surround you.
                </p>
                <p>
                  You can also visit the Malatan-og Falls and take a dip in its challengingly chilly waters or view it from one of DSB’s viewing decks. Another excellent spot for sightseeing is Jomax
                  Peak, a campsite and picnic spot.
                </p>
                <p>If you’re up for a workout, consider trekking to Mayana Peak, a 15-minute drive from DSB’s city center.</p>
              </div>

              <div className="bullet_grp">
                <div className="bullet_list">
                  <p>
                    <b>Where to Stay</b>
                  </p>
                  <ul>
                    <li>Vacation Villa KM55</li>
                    <li>Balai Ramirez</li>
                    <li>Jomax Peak House</li>
                    <li>Coco’s Ridge Tavern Winery and Cafe</li>
                    <li>Stonepeak Cafe</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Canlaon City</h2>
                <div className="img_container">
                  <img
                    className="image"
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Cool+Climate+Destinations/06+Canlaon+City+-+Nun+Duletin+via+Unsplash.png"
                    alt="Canlaon City"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by Nin Duletin from Unsplash</p>
                </div>
              </div>

              <div className="info-grp">
                <p>
                  Regarding cool weather destinations, Canalon City gives DSB a run for its money. This “vegetable basket of Negros” is abundant in agricultural harvests and home to Mt. Kanlaon, the
                  city’s most popular attraction.
                </p>
                <p>You can get up close and personal with the awe-inspiring mountain by hiking along the Makawiwili Ridge or the “Saddle in the Sky.”</p>
                <p>Visitors who make time to go to the Organization for Industrial, Spiritual, and Cultural Advancement (OISCA) Farm can behold the famous1,300-year-old balete tree.</p>
              </div>

              <div className="bullet_grp">
                <div className="bullet_list">
                  <p>
                    <b>Where to Stay</b>
                  </p>
                  <ul>
                    <li>Planta Centro Bacolod Hotel and Residences</li>
                    <li>O Hotel</li>
                    <li>Seda Capitol Central</li>
                    <li>Palmas del Mar Conference Resort Hotel</li>
                    <li>Kalaparan Farm House by HiveRooms</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Lake Sebu</h2>
                <div className="img_container">
                  <img
                    className="image"
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Cool+Climate+Destinations/07+Lake+Sebu+-+Mubarak+Tahir+via+Unsplash.png"
                    alt="Lake Sebu"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by Mubarak Tahir via Unsplash</p>
                </div>
              </div>

              <div className="info-grp">
                <p>
                  Lake Sebu is a highland town in South Cotabato recognized by UNESCO as a cultural landscape. This postcard-worthy vacation destination boasts year-round temperatures of 25 degrees
                  and below, and it’s adorned with waterfalls, rivers, springs, and, of course, lakes.
                </p>
                <p>The town has three lakes: Lake Sebu, Lake Lahit, and Lake Seloton. The lakes are connected by seven waterfalls, two bridged via zipline.</p>
                <p>For early-morning risers, we recommend renting a boat on the lake for a spectacular view of the pink lotus flowers and lily pads.</p>
              </div>

              <div className="bullet_grp">
                <div className="bullet_list">
                  <p>
                    <b>Where to Stay</b>
                  </p>
                  <ul>
                    <li>Sunrise Garden Lake Resort</li>
                    <li>El Galong Waterpark Resort</li>
                    <li>Mezza Hotel</li>
                    <li>Hotel Giorgio</li>
                    <li>Dolores Lake Resort</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Itbayat</h2>
                <div className="img_container">
                  <img
                    className="image"
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Cool+Climate+Destinations/08+Lake+Sebu+-+Mubarak+Tahir+via+Unsplash.png"
                    alt="Itbayat"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by Mubarak Tahir via Unsplash</p>
                </div>
              </div>

              <div className="info-grp">
                <p>
                  Unlike most other cold weather destinations in the Philippines, Itbayat isn’t in a high-altitude setting. Instead, it’s a collection of gargantuan, uplifted coral reefs, creating a
                  unique landscape of rolling hills and inviting temperatures as low as 7 degrees.
                </p>
                <p>Itbayat is reminiscent of English moors, removing you from any typical “Filipino” environment. You can visit Paganaman Port to see the small lagoon and several swimming holes.</p>
                <p>
                  The town’s sole beach, Bach of Kaxobcan, was formed after a landslide occurred on one side of the area’s cliffs. It’s a small, white-sand beach rife with natural swimming holes—truly
                  picturesque and one-of-a-kind.
                </p>
              </div>

              <div className="bullet_grp">
                <div className="bullet_list">
                  <p>
                    <b>Where to Stay</b>
                  </p>
                  <ul>
                    <li>Villa de Babat</li>
                    <li>R&E Bed and Breakfast</li>
                    <li>Bernardo’s Hotel</li>
                    <li>Boulder Bay Residences</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Lantapan</h2>
              </div>

              <div className="info-grp">
                <p>
                  Lantapan is the coldest area in Bukidnon, located 4,000 feet above sea level. Its provincial capital, Malaybalay, is yet another Little Baguio, sporting beautiful trees and cold fog.
                </p>
                <p>
                  Climbers can access Mount Dulang Dulang from Barangay Songco within Lantapan. If you’re more interested in forestry, the Binahon Agroforestry Farm (BAFF) provides opportunities to
                  learn about sustainable ecological agriculture and enjoy delicious local delicacies.
                </p>
                <p>
                  Another must-visit just a few minutes away from the city center is Chukoohills Cafe and Resto, located next to beautiful rice fields, mountain ranges, and fruit and vegetable farms.
                </p>
              </div>

              <div className="bullet_grp">
                <div className="bullet_list">
                  <p>
                    <b>Where to Stay</b>
                  </p>
                  <ul>
                    <li>Hotel de Susana and Resort</li>
                    <li>Double M Hotel</li>
                    <li>Pinegrove Mountain Lodge</li>
                    <li>Saddle Ridge Camp</li>
                    <li>Dahilayan Gardens and Resort</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Batanes</h2>
                <div className="img_container">
                  <img
                    className="image"
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Cool+Climate+Destinations/09+Batanes+-+SJ+via+unsplash.png"
                    alt="Batanes"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by SJ via Unsplash</p>
                </div>
              </div>

              <div className="info-grp">
                <p>
                  Batanes isn’t just the Cow Capital of the Philippines—it’s cool-aired, with temperatures averaging 22 degrees. It’s located in the northernmost part of the Philippines, so you’ll
                  feel a relaxing breeze throughout the day.
                </p>
                <p>You can tour the city by bike—a perfect way to enjoy the cold air. Most city bikes cost 25 pesos an hour.</p>
                <p>If you are, in fact, a fan of the region’s many cows, you can walk the rolling hills of Naidi and Vayang, enjoying a beautiful expanse of pasture lands.</p>
                <p>
                  In Batanes, there’s no better way to wind down at the end of the day than by enjoying local delicacies. You’ll get the best bang for your buck at Pension Ivatan Restaurant, which
                  highlights the best of Ivatan cuisine. We recommend ordering the Ivatan Platter and Diban (flying fish).
                </p>
              </div>

              <div className="bullet_grp">
                <div className="bullet_list">
                  <p>
                    <b>Where to Stay</b>
                  </p>
                  <ul>
                    <li>Troy Lodge</li>
                    <li>Fundacion Pacita</li>
                    <li>Villa Hontomin</li>
                    <li>Residencia du Basco</li>
                    <li>Bernardo’s Hotel</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="footnote">
            <div className="title_container">
              <h2>Where to chill next?</h2>
            </div>

            <div className="info">
              <p>
                Metro Manila’s sweltering heat is inescapable and often unbearable. Fortunately, you won’t have to spend the entire summer in an air-conditioned room. This handful of cold-weather
                destinations can provide some relief year-round.
              </p>
              <p>
                If you need a hand in planning your journey around the Philippines, GalaGO! is here to help. We know all about the country’s best-kept secrets and make your holiday one for the books.
                Contact us to chill out and travel with GalaGO!
              </p>
            </div>
          </div>
        </div>

        <hr />

        <div className="featured_blogs">
          <h2>Featured Blogs</h2>
          <div className="card_container">
            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg"
                  alt="Discover the Best Things to Do in Bohol All in One Tour!"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Discover the Best Things to Do in Bohol All in One Tour!</h3>

                <a href="/#/discover-the-best-things-to-do-in-bohol-all-in-one-tour" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#006FA9" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>

            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg"
                  alt="Guide to Travel Requirements in the Philippines"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Guide to Travel Requirements in the Philippines</h3>

                <a href="/#/guide-to-travel-requirements-in-the-philippines" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#006FA9" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BeatTheHeat;
