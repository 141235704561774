import NavBarTransparent from '../../../components/NavBarTransparent';
import { ChevronRightSvg } from '../../../resources/svg/ChevronRightSvg';
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import { LinkedInSvg } from '../../../resources/svg/LinkedinSvg';
import { TwitterSvg } from '../../../resources/svg/TwitterSvg';
import { LinkSvg } from '../../../resources/svg/LinkSvg';
import React from 'react';
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';
import '../../../resources/css/articles.scss';

const IslandEscape = () => {
  const [fbHover, setFbHover] = React.useState('#ADADAD');
  const [liHover, setLiHover] = React.useState('#ADADAD');
  const [twtHover, setTwtHover] = React.useState('#ADADAD');
  const [linkHover, setLinkHover] = React.useState('#ADADAD');

  return (
    <>
      <NavBarTransparent />
      <div className="hero_sec">
        <img
          src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Hotels+in+El+Nido/0+cover+cauayan+island+resort.jpeg"
          alt=""
          loading="lazy"
          onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
        />
        <div className="gradient_overlay">
          <h1>Top Destination</h1>

          <div className="bread_crumbs">
            <a href="/#/blogs">Blogs</a>
            <ChevronRightSvg _color="#ffffff" _width={20} _height={20} />
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
              Need an Island Escape? Check Into These Hotels in El Nido
            </a>
          </div>
        </div>
      </div>

      <div className="articleBody">
        <div className="articleHeader">
          <h1>Need an Island Escape? Check Into These Hotels in El Nido</h1>

          <div className="info">
            <p className="authorSec">
              {' '}
              By <u>Anthea Reyes</u>
            </p>
            <p className="separator">•</p>
            <p className="articleDate">Nov 7, 2023</p>
            <p className="separator">•</p>
            <p className="article-read">8 min read</p>
          </div>

          <div className="shareBtnContainer">
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setFbHover('#006FA9')} onMouseLeave={() => setFbHover('#ADADAD')}>
              <FacebookSvg _color={fbHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLiHover('#006FA9')} onMouseLeave={() => setLiHover('#ADADAD')}>
              <LinkedInSvg _color={liHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setTwtHover('#006FA9')} onMouseLeave={() => setTwtHover('#ADADAD')}>
              <TwitterSvg _color={twtHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLinkHover('#006FA9')} onMouseLeave={() => setLinkHover('#ADADAD')}>
              <LinkSvg _color={linkHover} _width={24} _height={24} />
            </a>
          </div>
        </div>

        <div className="content">
          <div className="articleDescription">
            <p>Planning a tropical escape to El Nido, Palawan, and wondering where to stay? Look no further!</p>
            <p>We've handpicked five hotels that will make your trip to this paradise destination nothing short of perfection.</p>
            <p>From breathtaking views to impeccable service, these El Nido gems have it all. Pack your bags, and get ready to experience a vacation that’s true bliss.</p>
          </div>

          <div className="extensive_story">
            <h2>Where to stay in El Nido: Resorts and hotels you'll never want to leave</h2>
            <div className="info_grp">
              <p>
                El Nido is a destination where people go to enjoy the outdoors. Folks from all around the world make their way here to go island hopping, unwind on scenic beaches, and just generally
                have fun under the sun.
              </p>
              <p>
                With most El Nido vacation itineraries filled with day-long explorations, many travelers end up treating their hotel choices as an afterthought during vacation planning. This is an
                easy mistake to make.
              </p>
              <p>
                Some people think that a hotel is just the place you go to sleep, especially in destinations as naturally abundant and beautiful as El Nido. This is a traveling mindset worth
                revisiting.
              </p>
              <p>Finding and booking the right hotel can make or break your vacation.</p>
              <p>
                Your chosen stay can be a true respite in between your hectic and physically challenging El Nido tours. If you find a hotel near the beach, it can make your pick-up and drop-off more
                convenient. It can turn lull times and/or island hopping breaks into a time of indulgence and beachside lounging.
              </p>
              <p>The right hotel can be the pièce de résistance of your El Nido stay. And we’ve picked out five El Nido hotels and resorts that we know will be up to the task.</p>
              <p>
                These five hotels, each with their unique charm, will ensure that your stay in El Nido’s tropical paradise is not only comfortable but also rejuvenating. Whether you seek adventure,
                relaxation, or a mix of both, this list will have something for you.
              </p>
            </div>
          </div>

          <div className="hotel_container">
            <div className="hotel_details">
              <div className="contacts">
                <h2>Bayview Country Inn</h2>
                <p>El Nido National Highway, Sitio Marimegmeg, Barangay Corong-Corong, El Nido, 5313 Palawan.</p>
              </div>

              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Hotels+in+El+Nido/01+bayview.png"
                  alt=""
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />
                <p>Photo by Bayview Country Inn Hotel</p>
              </div>

              <div className="info_grp">
                <p>Nestled amidst lush greenery and overlooking shimmering blue waters, Bayview Country Inn is a cozy haven for nature-loving travelers.</p>
                <p>This charming bed and breakfast boasts simple and homey rooms, each offering picturesque terrace views of El Nido's stunning land and seascapes.</p>

                <div className="bullet_grp">
                  <div className="bullet_list">
                    <p>This hotel is for you if you're into...</p>
                    <ul>
                      <li>
                        <span>Breathtaking views.</span> Wake up to breathtaking sunrises and unwind with unforgettable sunsets right from your room's balcony.
                      </li>
                      <li>
                        <span>An ideal location.</span> Situated at the heart of El Nido, a stay here will keep you close to famous natural attractions like the Big Lagoon, the Small Lagoon, and
                        Nacpan Beach.
                      </li>
                      <li>
                        <span>Warm hospitality.</span> The friendly staff at Bayview Country Inn will make you feel right at home, ready to assist with any request.
                      </li>
                      <li>
                        <span>Delicious dining experiences.</span> With an in-house restaurant and convenient proximity to El Nido Town’s colorful dining options, this cozy retreat makes it easy for
                        you to enjoy mouthwatering local and international cuisine during your stay.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="hotel_details">
              <div className="contacts">
                <h2>S Hotel</h2>
                <p>Rizal Street, Barangay Buena Suerte, El Nido, 5313 Palawan</p>
              </div>

              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Hotels+in+El+Nido/02+S+Hotel.png"
                  alt=""
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />
                <p>Photo by S Resort El Nido via Facebook</p>
              </div>

              <div className="info_grp">
                <p>If modern luxury and stunning sea views are what you’re looking for, S Hotel is the choice for you.</p>
                <p>
                  Every room in this stylish hotel has its own balcony with panoramic views of an islandic skyline. The staff is always at the ready with professionally attentive service. And its
                  location places you at the center of El Nido’s vibrant life and culture.
                </p>
                <p>With a chic and indulgent ambiance, this is a stay that will realize and elevate your dream El Nido vacation.</p>

                <div className="bullet_grp">
                  <div className="bullet_list">
                    <p>Book a stay at the S Hotel if you're looking for...</p>
                    <ul>
                      <li>
                        <span>A private getaway with a contemporary design.</span> The hotel's contemporary design and decor create an elegant and comfortable atmosphere, making it perfect for
                        individuals or couples looking for a private respite.
                      </li>
                      <li>
                        <span>An infinity pool with sunset views.</span> The stunning infinity pool that seemingly blends into the sea invites you to an Instagram-worthy leisurely swim.
                      </li>
                      <li>
                        <span>A much-needed spa retreat.</span> Pamper yourself with a rejuvenating spa treatment to complete your relaxation in paradise.
                      </li>
                      <li>
                        <span>Exceptional service.</span> The attentive and courteous staff at S Hotel ensure a memorable stay from check-in to check-out.
                      </li>
                      <li>
                        <span>A hotel in a convenient location.</span> Also located at the very center of El Nido, choosing this hotel puts you nearby sightseeing highlights like El Nido Beach, Caalan
                        Beach, and Palawan Divers Dive Center.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="hotel_details">
              <div className="contacts">
                <h2>Charlie's El Nido</h2>
                <p>Located at KM 279 National Highway, Barangay Villa Libertad, El Nido, 5313 Palawan</p>
              </div>

              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Hotels+in+El+Nido/03+Charlie's.png"
                  alt=""
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />
                <p>Photo by @smilelikenina via Charlie's El Nido on Facebook</p>
              </div>

              <div className="info_grp">
                <p>For those seeking a balance between comfort and adventure, Charlie's El Nido offers a refreshing oasis for you.</p>
                <p>
                  Set on artfully landscaped grounds with a tropical theme, this boutique hotel provides high-quality but cozy rooms. They also offer a vast array of amenities that will coax you into
                  that laid back island vibe.
                </p>
                <p>With an ideal location near the El Nido Airport, Charlie’s makes for an excellent base for exploring El Nido's natural wonders.</p>

                <div className="bullet_grp">
                  <div className="bullet_list">
                    <p>Charlie's El Nido is the place to be if you want...</p>
                    <ul>
                      <li>
                        <span>A relaxed atmosphere.</span> After an action-packed day, you can enjoy a peaceful night's rest at Charlie’s tranquil and tropical-themed rooms. Each stay here was
                        designed to help you enjoy El Nido without breaking a sweat. There are rooms with direct access to the pool, and a four-bedroom villa with a private outdoor plunge pool.
                      </li>
                      <li>
                        <span>Islandish amenities.</span> Live out your island girl/guy fantasies in style with Charlie’s indulgent amenities. There’s a cafe and bar where you can recharge with
                        delectable food and drinks, an outdoor pool where you can enjoy a lazy swim with a view, and a yoga studio where you can center your spirit in the middle of nature’s beauty.
                      </li>
                      <li>
                        <span>Local insights.</span> The knowledgeable staff readily provide insider tips on how to go on off-the-beaten-path adventures and dining experiences.
                      </li>
                      <li>
                        <span>Beach-y neighbors.</span> Aside from convenient proximity to the airport, Charlie’s is also a comfortable walking distance to scenic spots like Dalimatan Beach along El
                        Nido Bay and Bulalacao Waterfalls.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="hotel_details">
              <div className="contacts">
                <h2>Cuna Hotel</h2>
                <p>Osmeña Street, Barangay Buena Suerte, El Nido, 5313 Palawan</p>
              </div>

              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Hotels+in+El+Nido/04+cuna+hotel.png"
                  alt=""
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />
                <p>Photo by Cuna El Nido via Facebook</p>
              </div>

              <div className="info_grp">
                <p>Located along Osmeña Street in Barangay Buena Suerte, Cuna Hotel is a slice of paradise right in the heart of El Nido Town.</p>
                <p>This charmingly chic hotel offers a blend of modern comfort and classic El Nido hospitality.</p>
                <p>With easy access to the town's vibrant culture, it’s an excellent choice for both relaxation and exploration.</p>

                <div className="bullet_grp">
                  <div className="bullet_list">
                    <p>Choose the Cuna Hotel to stay somewhere with...</p>
                    <ul>
                      <li>
                        <span>A central location.</span> This boutique hotel has the double advantage of being situated amidst a beautiful northern tropical landscape as well as within walking
                        distance of El Nido's best restaurants, shops, and nightlife.
                      </li>
                      <li>
                        <span>A rooftop bar.</span> If dining while sightseeing is something you want to do, Cuna Hotel has a refreshing rooftop bar that overlooks the mesmerizing island and islets of
                        Bacuit Peninsula. At this bar, you can take in panoramic views and get those Insta-worthy shots while sipping your favorite cocktail.
                      </li>
                      <li>
                        <span>State-of-the-art rooms.</span> Relax in spacious and well-furnished rooms designed to emulate the serene and stylish getaway you have in mind.
                      </li>
                      <li>
                        <span>Friendly staff.</span> The attentive staff at Cuna Hotel are always ready to assist you with any inquiries or special requests.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="hotel_details">
              <div className="contacts">
                <h2>Lime Resort</h2>
                <p>Sitio Lugada, Barangay Corong-Corong, El Nido, 5313 Palawan</p>
              </div>

              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Hotels+in+El+Nido/05+lime+hotel.png"
                  alt=""
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />
                <p>Photo by Lime Resort El Nido via Facebook</p>
              </div>

              <div className="info_grp">
                <p>If you've always dreamt of staying right on the beach, Lime Resort will make your dream come true.</p>
                <p>This exquisite beachfront retreat conveniently near the El Nido Airport offers the perfect setting for a romantic getaway or a tranquil escape.</p>

                <div className="bullet_grp">
                  <div className="bullet_list">
                    <p>Why You Should Choose Lime Resort:</p>
                    <ul>
                      <li>
                        <span>Beachfront Bliss:</span> At this resort, one step out of your room brings you directly onto the pristine beach, where you can lounge, swim, and enjoy water sports to your
                        heart's content.
                      </li>
                      <li>
                        <span>Private Villas:</span> Lime Resort offers private villas with all the modern amenities you need for a comfortable stay, including a private pool and a terrace with island
                        views.
                      </li>
                      <li>
                        <span>Exceptional Dining:</span> The resort also boasts an on-site restaurant where you can savor fresh seafood and international cuisine while enjoying stunning sea views.
                      </li>
                      <li>
                        <span>Romantic Ambiance:</span> Whether it's a honeymoon or a romantic getaway, Lime Resort with its splendor and top-tier facilities sets the stage for an unforgettable
                        experience.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="storieswithTitleImageParagraphs">
            <div className="head">
              <h2>El Nido tours you need to get on</h2>
              <div className="head_desc">
                <p>El Nido is an effortlessly enchanting destination. Every inch of this place is a sight to behold.</p>
                <p>But to truly immerse yourself in its natural wonders, there are essential El Nido tours you simply can't miss out on.</p>
                <p>
                  To help you along, we’ve made a quick list of things to do in El Nido. Explore these essential El Nido tours to uncover the magic that has drawn travelers from around the world to
                  this tropical paradise.
                </p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>
                  <b>Discover the enigma of the Secret Lagoon</b>
                </h2>
                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Hotels+in+El+Nido/06+secret+lagoon.png"
                    alt=""
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by Jackson Groves via journeyera.com</p>
                </div>
              </div>

              <div className="info-grp">
                <p>Unravel the allure of El Nido's hidden gem, the Secret Lagoon, on a tour that promises mystery and awe.</p>
                <p>Begin your journey with a scenic boat ride through crystal-clear waters, surrounded by imposing limestone karst rock formations.</p>
                <p>Your destination? A hidden cove concealed behind towering cliffs.</p>
                <p>As you step onto the soft sands, you'll find yourself at the entrance of the Secret Lagoon, a natural wonder that captivates the senses.</p>
              </div>

              <div className="bullet_grp">
                <div className="bullet_list">
                  <p>
                    <b>What to expect:</b>
                  </p>
                  <ul>
                    <li>
                      <span>Majestic rock formations.</span> The towering limestone cliffs serve as a dramatic backdrop, creating a sense of wonder and exclusivity as you swim in the waters.
                    </li>
                    <li>
                      <span>Swimming and snorkeling.</span> Dive into the inviting waters and swim through a narrow crevice. You’ll be rewarded with the tranquility of the lagoon's secluded oasis.
                    </li>
                    <li>
                      <span>Lush greenery.</span> The surrounding foliage lends an air of enchantment, adding to the otherworldly ambiance of the Secret Lagoon.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>
                  <b>Snorkel through the tropical playground of Shimizu Island</b>
                </h2>
                <div className="head_desc">
                  <p>If underwater wonders are what you’re looking for, the Shimizu Island tour promises a snorkeling paradise like no other.</p>
                  <p>Dive into the kaleidoscope of colors beneath the waves as you explore vibrant coral gardens teeming with marine life.</p>
                  <p>This tour offers an opportunity to witness the rich biodiversity that makes El Nido a diving and snorkeling hotspot worldwide.</p>
                </div>
              </div>

              <div className="bullet_grp">
                <div className="bullet_list">
                  <p>
                    <b>What to expect:</b>
                  </p>
                  <ul>
                    <li>
                      <span>Coral gardens.</span> Encounter an underwater wonderland of vivid corals, where schools of fish dart among the crevices, showcasing the raw beauty of marine life.
                    </li>
                    <li>
                      <span>Snorkeling adventures.</span> With crystal-clear waters, you'll have unparalleled visibility, allowing you to observe the marine ecosystem in all its glory.
                    </li>
                    <li>
                      <span>Marine encounters.</span> Keep an eye out for sea turtles, tropical fish, and other fascinating aquatic creatures that call this underwater realm home.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>
                  <b>Bask in paradise found at Seven Commandos Beach</b>
                </h2>
                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Hotels+in+El+Nido/07+seven+commandos.png"
                    alt=""
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by Jackson Groves via journeyera.com</p>
                </div>
              </div>

              <div className="info-grp">
                <p>For those seeking tranquility and relaxation, the Seven Commandos Beach tour promises a serene escape.</p>
                <p>Step onto the soft, powdery sands of this pristine beach, framed by lush greenery and crystal-clear waters.</p>
                <p>This destination is the epitome of tranquility, a haven where you can soak in the sun, swim in azure waters, or simply enjoy the sound of gentle waves.</p>
              </div>

              <div className="bullet_grp">
                <div className="bullet_list">
                  <p>
                    <b>What to expect:</b>
                  </p>
                  <ul>
                    <li>
                      <span>Secluded beauty.</span> Unlike more crowded beaches, Seven Commandos offers a more intimate experience, perfect for those who crave a quiet time with nature.
                    </li>
                    <li>
                      <span>Beach activities.</span> This beach offers plenty of shore space where you can engage in beach volleyball, build sandcastles, or embark on leisurely walks by the water.
                    </li>
                    <li>
                      <span>Natural shelters.</span> Palm trees provide natural shade, offering a spot to sit back and enjoy a picnic while taking in the breathtaking scenery.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>
                  <b>Enjoy a slice of secluded paradise Payong Payong Beach</b>
                </h2>
                <div className="head_desc">
                  <p>For travelers who yearn to discover lesser-known gems, the Payong Payong Beach tour is a must.</p>
                  <p>
                    A hidden treasure accessible only by boat, this beach is a sanctuary of untouched beauty, offering an idyllic escape and a chance to immerse yourself in the tranquility of nature.
                  </p>
                </div>
              </div>

              <div className="bullet_grp">
                <div className="bullet_list">
                  <p>
                    <b>What to expect:</b>
                  </p>
                  <ul>
                    <li>
                      <span>Secluded solitude.</span> With limited visitors, Payong Payong Beach guarantees a serene environment where you can unwind and reconnect with nature.
                    </li>
                    <li>
                      <span>Clear waters.</span> The gentle waves and transparent waters make it ideal for swimming, snorkeling, or simply wading along the shoreline.
                    </li>
                    <li>
                      <span>Photographer's paradise.</span> The unspoiled landscape, framed by lush greenery and azure waters, provides a picture-perfect setting for capturing lasting memories.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Hotels+in+El+Nido/08+outro.png"
              alt=""
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by charlesdeluvio via Unsplash</p>
          </div>

          <div className="footnote">
            <div className="title_container">
              <h2>The GalaGO! deal to go for</h2>
            </div>

            <div className="info">
              <p>Three days and two nights is just the right amount of time to get a proper introduction to the beauty and splendor of El Nido.</p>
              <p>Luckily, if you’re stealing away to El Nido for this amount of time, GalaGO! offers an introductory package that works with your schedule.</p>
              <p>Book your three-days-two-nights (3D2N) stay with us at any of the hotels mentioned above and your package comes with the tours previously mentioned.</p>

              <div className="bullet_grp">
                <div className="bullet_list">
                  <p>Here's what's included in your travel package:</p>
                  <ul>
                    <li>your hotel stay for three days and two nights,</li>
                    <li>daily breakfast,</li>
                    <li>gadget protection,</li>
                    <li>airport transfer (if necessary),</li>
                    <li>a vetted tour guide, and</li>
                    <li>coverage for all entrance fees needed.</li>
                  </ul>
                  <p>All you’ll have to worry about would be your airfare, other meals throughout the day, and the occasional environmental fee for certain tourist spots (usually around PHP 200).</p>
                </div>

                <div className="bullet_list">
                  <p>Other things to note about our El Nido introductory package are:</p>
                  <ul>
                    <li>The package rates are quoted per person.</li>
                    <li>The package isn’t valid during PEAK SEASONS.</li>
                    <li>Our Child Policy means children three to six years old get a 20% discount.</li>
                    <li>Rates are only valid until further notice.</li>
                    <li>Room reservation is subject to room availability.</li>
                  </ul>
                  <p>Once you’ve booked your reservation, you can trust that we’ll take care of everything. You can experience the highlights of El Nido — no fuss, no muss.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="featured_blogs">
          <h2>Featured Blogs</h2>
          <div className="card_container">
            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg"
                  alt="Discover the Best Things to Do in Bohol All in One Tour!"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Discover the Best Things to Do in Bohol All in One Tour!</h3>

                <a href="/#/discover-the-best-things-to-do-in-bohol-all-in-one-tour" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#006FA9" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>

            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg"
                  alt="Guide to Travel Requirements in the Philippines"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Guide to Travel Requirements in the Philippines</h3>

                <a href="/#/guide-to-travel-requirements-in-the-philippines" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#006FA9" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IslandEscape;
