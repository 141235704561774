import React from 'react';
import { SvgProps } from './SvgProps';

export const Star = ({ _width = 12, _height = 13, _color = '#006FA9' }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={_width} height={_height} viewBox="0 0 14 15" fill="none">
      <path
        d="M13.963 5.52573C13.919 5.39614 13.838 5.28226 13.73 5.19817C13.6221 5.11408 13.4918 5.06345 13.3554 5.05253L9.36467 4.73543L7.63776 0.912703C7.58277 0.789581 7.49331 0.685007 7.38019 0.611602C7.26708 0.538197 7.13513 0.499098 7.00029 0.499024C6.86544 0.49895 6.73345 0.537904 6.62025 0.611185C6.50706 0.684466 6.41749 0.788942 6.36236 0.912003L4.63545 4.73543L0.644722 5.05253C0.51064 5.06315 0.382457 5.11217 0.275508 5.19374C0.16856 5.2753 0.087374 5.38595 0.0416638 5.51245C-0.00404629 5.63895 -0.0123452 5.77594 0.0177599 5.90702C0.0478651 6.03811 0.1151 6.15775 0.21142 6.25163L3.16054 9.12655L2.11753 13.643C2.08586 13.7797 2.09601 13.9228 2.14666 14.0536C2.19732 14.1845 2.28614 14.2971 2.40159 14.3769C2.51705 14.4567 2.65382 14.4999 2.79414 14.5009C2.93447 14.502 3.07188 14.4609 3.18854 14.3829L7.00006 11.8419L10.8116 14.3829C10.9308 14.462 11.0714 14.5028 11.2145 14.4997C11.3576 14.4965 11.4963 14.4496 11.612 14.3653C11.7276 14.281 11.8147 14.1633 11.8614 14.028C11.9082 13.8928 11.9124 13.7464 11.8735 13.6087L10.5932 9.12865L13.7684 6.27123C13.9763 6.08363 14.0526 5.79103 13.963 5.52573Z"
        fill={_color}
      />
    </svg>
  );
};
