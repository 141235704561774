import React from 'react';
// css
import '../../resources/css/search-page-WTP.scss';
import 'react-calendar/dist/Calendar.css';
import '../../resources/css/calendar.scss';
import { SearchForm } from './SearchForm';
import { AuthenticationHooks } from '../../hooks/AuthenticationHooks';
import { SearchFormWTP } from './SearchFormWTP';

export const SearchPage = () => {
  const { loginGuestHook } = AuthenticationHooks();
  // const urlParams = new URLSearchParams(window.location.search);
  // const explicit = urlParams.get('explicit');

  React.useEffect(() => {
    loginGuestHook();
    // eslint-disable-next-line
  }, []);

  return (
    <div id="search-container">
      <SearchFormWTP/>
    </div>
  );
};
