import React from 'react';
import { EmailSentStore } from '../../store/EmailSentStore';
import { AlertBox } from '../../components/AlertBox';
import { GalagoButton } from '../../components/Button';
import { EmailDialogStore } from '../../store/EmailDialogStore';
import { EmailSvg } from '../../resources/svg/EmailSvg';
import EmailHook from '../../hooks/EmailHook';

export default function EmailSentDialog() {
  const { resendEmailApi } = EmailHook();
  const { showEmailSentDialog, setShowEmailSentDialog } = EmailSentStore();
  const { userEmail } = EmailDialogStore();
  const [disableResend, setDisableResend] = React.useState(false);

  const [timeLeft, setTimeLeft] = React.useState(0);

  const handleResendEmail = async () => {
    setDisableResend(true);
    const response = await resendEmailApi(userEmail as string);
    const { statusCode } = response.data;
    setTimeLeft(60);
    if (statusCode === 200) {
      setDisableResend(false);
      // setShowEmailSentDialog(false); // disabled the dialog closing after resending email
    }
  };

  React.useEffect(() => {
    if (showEmailSentDialog === false) {
      setTimeLeft(0);
    }
  }, [showEmailSentDialog]);

  React.useEffect(() => {
    setDisableResend(true);
    if (showEmailSentDialog === false) return;
    if (!timeLeft) {
      setDisableResend(false);
      return;
    }
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, showEmailSentDialog]);

  return (
    <AlertBox size={'lg'} show={showEmailSentDialog} onHide={(): void => setShowEmailSentDialog(false)}>
      <div className="d-flex flex-column align-items-center py-5 px-8">
        <EmailSvg _width={140} />
        <p className="font-medium fs-3 text-center mb-4 mt-4">Verification Link Sent!</p>
        <p className="text-center">
          You've got mail at <span style={{ color: '#006FA9' }}>{userEmail}.</span> Check your inbox (or spam) and confirm your email address to continue your signup.
        </p>
        <div className="d-flex flex-column pl-4 pr-4 pt-8 gap-2 w-[70%] relative">
          <GalagoButton onClick={() => handleResendEmail()} disabled={disableResend} label={`${timeLeft !== 0 ? 'Resend in ' + timeLeft + 's' : 'Resend Email'}`} className="" />
          <GalagoButton onClick={() => setShowEmailSentDialog(false)} label={'Close'} className="text-decoration-none" btn="link" />
        </div>
      </div>
    </AlertBox>
  );
}
