import { SvgProps } from './SvgProps';

export const CheckInSvg = ({ _width = 12, _height = 13, _color = '#333333' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.66634 13.9947C4.29967 13.9947 3.9859 13.8643 3.72501 13.6034C3.46412 13.3425 3.33345 13.0285 3.33301 12.6614V5.32806C3.33301 4.9614 3.46367 4.64762 3.72501 4.38673C3.98634 4.12584 4.30012 3.99518 4.66634 3.99473H5.99967V2.6614C5.99967 2.29473 6.13034 1.98095 6.39167 1.72006C6.65301 1.45918 6.96679 1.32851 7.33301 1.32806H8.66634C9.03301 1.32806 9.34701 1.45873 9.60834 1.72006C9.86967 1.9814 10.0001 2.29518 9.99967 2.6614V3.99473H11.333C11.6997 3.99473 12.0137 4.1254 12.275 4.38673C12.5363 4.64806 12.6668 4.96184 12.6663 5.32806V12.6614C12.6663 13.0281 12.5359 13.3421 12.275 13.6034C12.0141 13.8647 11.7001 13.9952 11.333 13.9947C11.333 14.1836 11.269 14.3421 11.141 14.4701C11.013 14.5981 10.8548 14.6618 10.6663 14.6614C10.4779 14.661 10.3197 14.597 10.1917 14.4694C10.0637 14.3418 9.99967 14.1836 9.99967 13.9947H5.99967C5.99967 14.1836 5.93567 14.3421 5.80767 14.4701C5.67967 14.5981 5.52145 14.6618 5.33301 14.6614C5.14456 14.661 4.98634 14.597 4.85834 14.4694C4.73034 14.3418 4.66634 14.1836 4.66634 13.9947ZM4.66634 12.6614H11.333V5.32806H4.66634V12.6614ZM5.99967 11.9947H7.33301V5.99473H5.99967V11.9947ZM8.66634 11.9947H9.99967V5.99473H8.66634V11.9947ZM7.33301 3.99473H8.66634V2.6614H7.33301V3.99473Z"
        fill="#006FA9"
   
        
      />
    </svg>
  );
};
