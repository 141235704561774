import React from 'react';
import '../../../resources/css/articles.scss';
import NavBarTransparent from '../../../components/NavBarTransparent';
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';
import { ChevronRightSvg } from '../../../resources/svg/ChevronRightSvg';
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import { LinkedInSvg } from '../../../resources/svg/LinkedinSvg';
import { TwitterSvg } from '../../../resources/svg/TwitterSvg';
import { LinkSvg } from '../../../resources/svg/LinkSvg';

function TypesOfTraveler() {
  const [fbHover, setFbHover] = React.useState('#ADADAD');

  const [liHover, setLiHover] = React.useState('#ADADAD');

  const [twtHover, setTwtHover] = React.useState('#ADADAD');
  const [linkHover, setLinkHover] = React.useState('#ADADAD');

  return (
    <>
      <NavBarTransparent />

      <div className="hero_sec">
        <img
          src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/blogHeaderV2_Which+Type+of+Traveler+are+You.jpg"
          alt="What to Do in Cebu: Top 10 Best Cebu Tours"
          loading="lazy"
          onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
        />
        <div className="gradient_overlay">
          <h1>Top Destination</h1>
          <div className="bread_crumbs">
            <a href="#/blogs">Blogs</a>
            <ChevronRightSvg _color="#ffffff" _width={20} _height={20} />
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
              There are 3 Types of Travelers: Which One Are You?
            </a>
          </div>
        </div>
      </div>

      <div className="articleBody">
        <div className="articleHeader">
          <h1>There are 3 Types of Travelers: Which One Are You?</h1>

          <div className="info">
            <p className="authorSec">
              {' '}
              By <u>Anthea Reyes</u>
            </p>
            <p className="separator">•</p>
            <p className="articleDate">Oct 20, 2023</p>
            <p className="separator">•</p>
            <p className="article-read">3 min read</p>
          </div>

          <div className="shareBtnContainer">
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setFbHover('#006FA9')} onMouseLeave={() => setFbHover('#ADADAD')}>
              <FacebookSvg _color={fbHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLiHover('#006FA9')} onMouseLeave={() => setLiHover('#ADADAD')}>
              <LinkedInSvg _color={liHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setTwtHover('#006FA9')} onMouseLeave={() => setTwtHover('#ADADAD')}>
              <TwitterSvg _color={twtHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLinkHover('#006FA9')} onMouseLeave={() => setLinkHover('#ADADAD')}>
              <LinkSvg _color={linkHover} _width={24} _height={24} />
            </a>
          </div>
        </div>

        <div className="content">
          <div className="articleDescription">
            <p>The world of travel is rich and diverse. Just so, travelers come in diverse flavors, each with distinct motivations and preferences.</p>
            <p>
              Discovering and embracing which flavor or type of traveler you are can help you be more intentional during your travel planning. It can help you curate the perfect itinerary that will
              suit your temperament or push you out of your comfort zone at your own pace.
            </p>
            <p>If you’re traveling in a group, knowing which type your travel buddies are can also help in creating an agenda that will accommodate everyone. Keep the journey smooth sailing.</p>
            <p>
              So let's dive into the three key types of travelers: the <span> Business Traveler </span>, the <span> Leisure Traveler </span>, and the fascinating blend of both known as{' '}
              <span>Bleisure Travel</span>.
            </p>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="head">
              <h2>The Business Traveler</h2>

              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/What+type+of+traveler/Business+Avi+Richards+unsplash.png"
                  alt="The Business Traveler"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />
                <p>Photo by Avi Richards via Unsplash</p>
              </div>
            </div>

            <div className="info_grp">
              <p>Business Travelers are the businessmen and women who come and go with a very strict agenda.</p>

              <p>Their compass points to boardrooms, conferences, and client meetings, so for them, time is money, and efficiency is the name of the game.</p>

              <p>Because of this, they’re usually psychocentric tourists who search out popular and/or familiar tourist destinations.</p>

              <p>Cultural differences are not just interesting for this type. They’re essential for their networking and building global connections.</p>
            </div>
          </div>

          <div className="storieswithTitleImageParagraphs">
            <div className="head">
              <h2>The Leisure Traveler</h2>

              <div className="head_desc">
                <p>Leisure Travelers are the escape artists. They're on a quest for either one of two things: rest and relaxation, or adventure and exploration.</p>
                <p>Under the leisure type of traveler, there are two subtypes — the adventurer and the vacationer.</p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Leisure Traveler Type A: The Adventurer</h2>
                <div className="img_container">
                  <img
                    className="image"
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/What+type+of+traveler/Adventurer+-+Holly+Mandarich.png"
                    alt="Leisure Traveler Type A: The Adventurer"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by Holly Mandarich via Unsplash</p>
                </div>
              </div>

              <div className="info-grp">
                <p>On one end of the spectrum, you will find the adventurers.</p>
                <p>They are the allocentric tourists, venturing to unfamiliar territories, driven by the pursuit of discovering new sights and engaging with different cultures.</p>
                <p>For this type, cultural differences aren’t a challenge. They’re a source of intrigue, enriching their experiences and making the travel much more worth it.</p>
                <p>You are sure to encounter an adventurer while traversing hiking trails, spelunking tours, and other thrill-inducing endeavors.</p>
              </div>

              <div className="galaGuide">
                <h3>GalaGuide!</h3>
                <p>
                  Fancy yourself an activity-packed adventure?{' '}
                  <a href="/where-to-stay-in-bohol:-best-hotels-and-resorts" target="_blank" rel="noreferrer">
                    Discover the best of Bohol
                  </a>{' '}
                  with our exclusive package—complete with a hotel stay and a tour of Bohol's best sites!
                </p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Leisure Traveler Type B: The Vacationer</h2>
                <div className="img_container">
                  <img
                    className="image"
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/What+type+of+traveler/vacationer+Oveth+Martinez.png"
                    alt="vacationer Oveth Martinez"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by Oveth Martinez via Unsplash</p>
                </div>
              </div>

              <div className="info-grp">
                <p>On the other end, you have the vacationer who's also a psychocentric kind of tourist, much like the business traveler.</p>
                <p>The only difference is, where the business traveler is driven by business schedules, the vacationer is motivated by avoiding any kind of structure or stress.</p>
                <p>They want the familiar and the relaxing. No critical thinking required, please. Vibes only.</p>
                <p>Because of this, you will often find vacationers in spas, speakeasies, a daybed by the beach — anywhere comfortable and low-effort, basically.</p>
              </div>

              <div className="galaGuide">
                <h3>GalaGuide!</h3>
                <p>
                  Are you the Vacationer type? Treat yourself to a{' '}
                  <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
                    beach escape in Boracay!
                  </a>{' '}
                  Sun, white sand, and sea await!
                </p>
              </div>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="head">
              <h2>The Bleisure Traveler</h2>
              <div className="img_container">
                <img
                  className="image"
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/What+type+of+traveler/bleisure+-+Guilherme+Stecanella+unsplash.png"
                  alt="bleisure - Guilherme Stecanella unsplash"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />
                <p>Photo by Guilherme Stecanella via Unsplash</p>
              </div>
            </div>

            <div className="info_grp">
              <p>Now, enter the mid-centric tourists, the Bleisure Travelers, embodying the best of both worlds. (cue Miley Cyrus)</p>
              <p>This type of traveler seizes any and every opportunity to combine business and leisure in one single trip. Cost-efficiency, we love her.</p>
              <p>
                These are the kind of people who could and would turn a conference in Tokyo into a sushi feast and temple visit, an ocular in Vietnam into a street food crawl and sand dune adventure.
              </p>
              <p>
                These folks are cultural chameleons, quick to adapt to any situation — strange or familiar, for work or for leisure. They have their set itineraries BUT they will make time should a
                more interesting experience come their merry way.
              </p>
            </div>

            <div className="galaGuide">
              <h3>GalaGuide!</h3>
              <p>
                Need a workcation? Work from paradise and squeeze in an island-hopping experience with our exclusive{' '}
                <a href="/need-an-island-escape-check-into-these-hotels-in-el-nido" target="_blank" rel="noreferrer">
                  El Nido travel package!
                </a>
              </p>
            </div>
          </div>

          <div className="footnote">
            <div className="title_container">
              <h2>Soooo, which of these different types of travelers are you?</h2>
            </div>

            <div className="info">
              <p>In conclusion, the world of travel encompasses a spectrum of preferences and purposes.</p>
              <p>
                Whether you're a psychocentric Business Traveler, an allocentric Leisure Traveler, or a mid-centric Bleisure Traveler, each type brings a unique perspective to the ever-evolving
                landscape of global exploration.
              </p>
              <p>
                Understanding the nuances of these traveler types can help you better appreciate the diverse ways people engage with the world. It can help you better appreciate the way you experience
                the world.
              </p>
              <p>So to all the different traveler types, we say no shame. Embrace your own kind of traveling so you can create longer-lasting and more fulfilling memories on your journey.</p>
            </div>
          </div>
        </div>

        <hr />

        <div className="featured_blogs">
          <h2>Featured Blogs</h2>
          <div className="card_container">
            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg"
                  alt="Discover the Best Things to Do in Bohol All in One Tour!"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Discover the Best Things to Do in Bohol All in One Tour!</h3>

                <a href="/#/discover-the-best-things-to-do-in-bohol-all-in-one-tour" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#006FA9" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>

            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg"
                  alt="Guide to Travel Requirements in the Philippines"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Guide to Travel Requirements in the Philippines</h3>

                <a href="/#/guide-to-travel-requirements-in-the-philippines" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#006FA9" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TypesOfTraveler;
