import NavBarTransparent from '../../../components/NavBarTransparent';
import { ChevronRightSvg } from '../../../resources/svg/ChevronRightSvg';
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import { LinkedInSvg } from '../../../resources/svg/LinkedinSvg';
import { TwitterSvg } from '../../../resources/svg/TwitterSvg';
import { LinkSvg } from '../../../resources/svg/LinkSvg';
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';
import React from 'react';
import '../../../resources/css/articles.scss';

const TipsForASpooky = () => {
  const [fbHover, setFbHover] = React.useState('#ADADAD');
  const [liHover, setLiHover] = React.useState('#ADADAD');
  const [twtHover, setTwtHover] = React.useState('#ADADAD');
  const [linkHover, setLinkHover] = React.useState('#ADADAD');

  return (
    <>
      <NavBarTransparent />
      <div className="hero_sec">
        <img
          src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/blogHeaderV2_Ghost+Tours+B.jpg"
          alt=""
          loading="lazy"
          onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
        />
        <div className="gradient_overlay">
          <h1>Travel Tips</h1>

          <div className="bread_crumbs">
            <a href="/#/blogs">Blogs</a>
            <ChevronRightSvg _color="#ffffff" _width={20} _height={20} />
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
              Tips for a Spooky But Safe Ghost-Hunting Adventure
            </a>
          </div>
        </div>
      </div>

      <div className="articleBody">
        <div className="articleHeader">
          <h1>Tips for a Spooky But Safe Ghost-Hunting Adventure</h1>

          <div className="info">
            <p className="authorSec">
              {' '}
              By <u>Anthea Reyes</u>
            </p>
            <p className="separator">•</p>
            <p className="articleDate">Oct 26, 2023</p>
            <p className="separator">•</p>
            <p className="article-read">2 min read</p>
          </div>

          <div className="shareBtnContainer">
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setFbHover('#006FA9')} onMouseLeave={() => setFbHover('#ADADAD')}>
              <FacebookSvg _color={fbHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLiHover('#006FA9')} onMouseLeave={() => setLiHover('#ADADAD')}>
              <LinkedInSvg _color={liHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setTwtHover('#006FA9')} onMouseLeave={() => setTwtHover('#ADADAD')}>
              <TwitterSvg _color={twtHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLinkHover('#006FA9')} onMouseLeave={() => setLinkHover('#ADADAD')}>
              <LinkSvg _color={linkHover} _width={24} _height={24} />
            </a>
          </div>
        </div>

        <div className="content">
          <div className="articleDescription">
            <p>Getting into the spirit of Halloween?</p>
            <p>There’s nothing like seeking out all things mysterious and supernatural this time of the year.</p>
            <p>
              For most, this translates to sharing scary ghost stories with friends and binging horror movies. For the brave and the bold, the true Halloween aficionados, getting into the spirit of
              the season goes as far as going out there and daring a real-life ghost-hunting adventure.
            </p>
            <p>
              If you’re the latter, know that the folks here at GalaGO! Applaud and support you! But before you go out there and face the restless spirits, here are some reminders to make sure you get
              home safe. And without any unwanted, invisible company.
            </p>
          </div>

          <div className="extensive_story">
            <div className="tip_container">
              <div className="tip">
                <h3>1. Come Prepared with Ghost-Hunting Gear</h3>
                <p>
                  Before embarking on your ghostly adventure, ensure you have the right equipment. Basic gear includes an EMF (Electromagnetic Field) meter, digital voice recorder, camera, and a
                  flashlight.
                </p>
                <p>These tools can help you capture evidence of paranormal activity and make your experience more immersive.</p>
              </div>

              <div className="tip">
                <h3>2. Show Respect to the Spirits</h3>
                <p>Respect is key when interacting with the supernatural. Remember that these are souls from another realm.</p>
                <p>Approach haunted sites with reverence and an open mind.</p>
                <p>
                  Avoid provoking spirits, and always seek permission if conducting any rituals or séances. Showing respect not only ensures a more positive experience but also honors the history of
                  the location.
                </p>
              </div>

              <div className="tip">
                <h3>3. Go in a Group</h3>
                <p>Safety in numbers is a must in the world of ghost hunting. Going with a group of friends or fellow enthusiasts not only adds to the fun but also enhances safety.</p>
                <p>You can rely on each other for support and share the thrill of unearthing the paranormal.</p>
              </div>

              <div className="tip">
                <h3>4. Stay Overnight</h3>
                <p>For a more immersive experience, consider staying overnight at haunted locations.</p>
                <p>The veil between the living and the dead is often thinner during the nighttime hours, making it an ideal time for paranormal encounters.</p>
                <p>Remember to bring sleeping bags, provisions, and a healthy dose of courage.</p>
              </div>
            </div>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Ghost+tour+tips/Diplomat_Hotel_in_Baguio_City.png"
              alt=""
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo via Amyscrypt.com</p>
          </div>

          <div className="extensive_story">
            <div className="tip_container">
              <div className="tip">
                <h3>5. Stop By for Pagpag Before Going Home</h3>
                <p>In Filipino folklore, "pagpag" refers to a superstition of shaking off negative energy before returning home after visiting a wake or a haunted place.</p>
                <p>While not mandatory, it's a gesture of respect to ensure that no lingering spirits tag along with you.</p>
                <p>It's a fascinating cultural ritual to consider after your ghost-hunting adventure, and a reassuring one too. Better safe than sorry!</p>
              </div>
            </div>
          </div>

          <div className="footnote">
            <div className="title_container">
              <h2>What other ghost-hunting tips do you want to share?</h2>
              <div className="info">
                <p>
                  Ghost-hunting adventures can be thrilling and eye-opening, but they require preparation, respect, and a dash of courage. With the right gear, a group of like-minded friends, and an
                  open heart, you can embark on a memorable journey into the paranormal.
                </p>
                <p>Just remember to show respect to the spirits you encounter, stay safe, and, when in the Philippines, consider doing a little "pagpag" before heading home.</p>
                <p>Got other tips to know before you ghost hunt? Share them with us in the comments!</p>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="featured_blogs">
          <h2>Featured Blogs</h2>
          <div className="card_container">
            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg"
                  alt="Discover the Best Things to Do in Bohol All in One Tour!"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Discover the Best Things to Do in Bohol All in One Tour!</h3>

                <a href="/galago-guide:-things-to-do-in-bohol" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#006FA9" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>

            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg"
                  alt="Guide to Travel Requirements in the Philippines"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Guide to Travel Requirements in the Philippines</h3>

                <a href="/#/guide-to-travel-requirements-in-the-philippines" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#006FA9" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TipsForASpooky;
