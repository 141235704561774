import React, { useRef, useState } from 'react';
import { SearchSvg } from '../../../resources/svg/SearchSvg';
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';
import { GalagoButton } from '../../../components/Button';
import { FloatingIconFormControl } from '../../../components/FormControl';
import Calendar from 'react-calendar';
import { UserSvg } from '../../../resources/svg/UserSvg';
import { ChildSvg } from '../../../resources/svg/ChildSvg';
import { InfantSvg } from '../../../resources/svg/InfantSvg';
import { LocationSvg } from '../../../resources/svg/LocationSvg';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { SearchPageHook } from '../../../hooks/SearchPageHook';
import { PassengersStore } from '../../../store/PassengersStore';
import { CalendarSvg } from '../../../resources/svg/CalendarSvg';
import moment from 'moment';
import { COLOR } from '../../../custom-theme';

type FlightResultsSearchFormType = {
  searchType: 'one-way' | 'round-trip' | 'multi-city';
  searchCriteria: { [index: string]: any };
  // setSelectedFlight: (e: any) => void;
  // setResetSelectedFlight?: (e: any) => void;
  // setDepartureTrip: (e: any) => void;
  // setReturnTrip: (e: any) => void;
  setFlightInfo: (e: any) => void;
  setShowReturnFlights: (e: any) => void;
};

const FlightResultsSearchFormMobile = ({
  searchType,
  searchCriteria,
  // setSelectedFlight,
  // setResetSelectedFlight,
  // setDepartureTrip,
  // setReturnTrip,
  setFlightInfo,
  setShowReturnFlights,
}: FlightResultsSearchFormType) => {
  const { airportLocations } = SearchPageHook();
  const passengerStore = PassengersStore();
  const { passengers, fromCityName, toCityName, departingDate, returnDate } = searchCriteria;
  const isOneWay = searchType === 'one-way' ? true : false;

  React.useEffect(() => {
    // set default passenger state if the page is refreshed
    passengerStore.setPassengers({
      ...{
        adults: passengers.adults,
        minors: passengers.minors,
        children: passengers.children,
        infants: passengers.infants,
      },
    });
    // eslint-disable-next-line
  }, []);

  // form defaultValues
  const [flyingFromInput, setFlyingFromInput] = useState('');
  const [flyingToInput, setFlyingToInput] = useState('');
  const [departingInput, setDepartingInput] = useState('');
  const [returnDateInput, setReturnDateInput] = useState('');
  const [adultsInput, setAdultsInput] = useState(1);
  const [childrenInput, setChildrenInput] = useState(0);
  const [infantsInput, setInfantsInput] = useState(0);
  const [cabinInput, setCabinInput] = useState('economy');

  // eslint-disable-next-line
  const [departingValue, setDepartingValue] = useState('');
  // eslint-disable-next-line
  const [returnValue, setReturnValue] = useState('');

  const defaultFlightTypeRadioValue = searchType === 'one-way' ? 'one-way' : 'round-trip';
  const [flightTypeRadioValue, setFlightTypeRadioValue] = useState(defaultFlightTypeRadioValue);

  const [airportOptions, setAirportOptions] = useState<{ [index: string]: any }>();
  const [fromDetails, setFromDetails] = useState<{ [index: string]: any }>();
  const [toDetails, setToDetails] = useState<{ [index: string]: any }>();

  const fromRef = useRef<any>(null);
  const toRef = useRef<any>(null);

  // const [maxDate, setMaxDate] = useState<string>();
  const [showSearchOptions, setShowSearchOptions] = useState(false);
  const [showPassengerForm, setShowPassengerForm] = useState(false);
  const [showDepartingCalendar, setShowDepartingCalendar] = useState(false);
  const [showReturnCalendar, setShowReturnCalendar] = useState(false);

  const maxPassengers = 9;
  const [maxPassengerCount, setMaxPassengerCount] = useState(0);
  React.useEffect(() => {
    const count = adultsInput + childrenInput + infantsInput;
    setMaxPassengerCount(count);
    // console.log(count, maxPassengerCount);
  }, [adultsInput, childrenInput, infantsInput]);

  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState(true);

  React.useEffect(() => {
    setIsSearchButtonDisabled(true);
    if (flightTypeRadioValue === 'one-way') {
      if (flyingFromInput !== '' && flyingToInput !== '' && departingInput !== '') {
        setIsSearchButtonDisabled(false);
      }
    }
    if (flightTypeRadioValue === 'round-trip') {
      if (flyingFromInput !== '' && flyingToInput !== '' && departingInput !== '' && returnDateInput !== '') {
        setIsSearchButtonDisabled(false);
      }
    }
  }, [flyingFromInput, flyingToInput, departingInput, returnDateInput, flightTypeRadioValue]);

  return (
    <div className="mobile-edit">
      <div className="upper-row">
        <div className="destinations">
          <strong>{fromCityName}</strong>
          <ArrowRightSvg _color="#006FA9" />
          <strong>{toCityName}</strong>
        </div>
        <GalagoButton
          label={showSearchOptions ? 'Cancel' : 'EDIT'}
          // _className={showSearchOptions ? 'edit-button' : 'edit-button'}
          className="edit-button"
          onClick={() => setShowSearchOptions((prev: boolean) => !prev)}
        />
      </div>
      <div className="lower-row">
        <span className="date-container">{departingDate}</span>

        {isOneWay === false ? (
          <>
            <span className="date-container">&nbsp;&nbsp;{returnDate}</span>
          </>
        ) : null}
        <span className="passenger-count">
          {passengers.adults} {passengers.adults > 1 ? 'Adults' : 'Adult'}
          {passengers.children > 0 ? (passengers.children > 1 ? ` ${passengers.children} Children` : ` ${passengers.children} Child`) : null}
          {passengers.infants > 0 ? (passengers.infants > 1 ? ` ${passengers.infants} Infants` : ` ${passengers.infants} Infant`) : null}
        </span>
      </div>
      {showSearchOptions && (
        <>
          <div className="mobile-only-edit py-3">
            <div className="mobile-trips-button">
              <div className="form-check">
                <input
                  className="form-check-input bg-light"
                  type="radio"
                  name="flight-type"
                  value={'one-way'}
                  defaultChecked={isOneWay ? true : false}
                  onChange={(e) => setFlightTypeRadioValue(e.target.value)}
                  id="flight-type-one-way"
                />
                <label className="form-check-label" htmlFor="flight-type-one-way" style={{ transform: 'unset' }}>
                  One Way
                </label>
              </div>
              <div className="form-check">
                <input
                  defaultChecked={isOneWay ? false : true}
                  className="form-check-input"
                  type="radio"
                  name="flight-type"
                  value={'round-trip'}
                  onChange={(e) => setFlightTypeRadioValue(e.target.value)}
                  id="flight-type-round-trip"
                />
                <label className="form-check-label" htmlFor="flight-type-round-trip" style={{ transform: 'unset' }}>
                  Round Trip
                </label>
              </div>
            </div>

            {/* flying from */}
            <div className="col">
              <label htmlFor="" className="form-label">
                From
              </label>
              <span className="position-relative d-block">
                <LocationSvg _color={flyingFromInput ? '#4FA2B4' : '#ADADAD'} className="location-svg position-absolute" style={{ zIndex: 1, left: '12px', top: '18px' }} />
                <AsyncTypeahead
                  ref={fromRef}
                  id="async-flying-from"
                  isLoading={false}
                  onSearch={(query: string) => {
                    airportLocations(query).then((data: { [index: string]: any }) => {
                      setAirportOptions(data);
                    });
                  }}
                  onChange={(e: { [index: string]: any }) => {
                    setFlyingFromInput(`${e[0]?.airportCode}`);
                    setFromDetails(e);
                  }}
                  onFocus={() => {}}
                  labelKey={(option: any) => {
                    return `${option?.cityName} (${option?.airportCode})`;
                  }}
                  placeholder="Country, City or Airport"
                  options={airportOptions?.tag}
                  minLength={3}
                  filterBy={() => true}
                  renderMenuItemChildren={(e: any) => {
                    return (
                      <span>
                        {e.airportCode} - {e.airportName}
                      </span>
                    );
                  }}
                  style={{
                    border: flyingFromInput ? '1px solid #4FA2B4' : '1px solid #dee2e6',
                    borderRadius: '12px',
                  }}
                />
              </span>
            </div>
            {/* flying to */}

            <div className="col">
              <label htmlFor="" className="form-label">
                To
              </label>
              <span className="position-relative d-block">
                <LocationSvg _color={flyingToInput ? '#4FA2B4' : '#ADADAD'} className="location-svg position-absolute" style={{ zIndex: 1, left: '12px', top: '18px' }} />
                <AsyncTypeahead
                  ref={toRef}
                  id="async-flying-to"
                  isLoading={false}
                  onSearch={(query: string) => {
                    airportLocations(query).then((data: { [index: string]: any }) => {
                      setAirportOptions(data);
                    });
                  }}
                  onChange={(e: { [index: string]: any }) => {
                    setFlyingToInput(`${e[0]?.airportCode}`);
                    setToDetails(e);
                  }}
                  onFocus={() => {}}
                  labelKey={(option: any) => `${option?.cityName} (${option?.airportCode})`}
                  placeholder="Country, City or Airport"
                  options={airportOptions?.tag}
                  minLength={3}
                  filterBy={() => true}
                  renderMenuItemChildren={(e: any) => (
                    <span>
                      {e.airportCode} - {e.countryCode}
                    </span>
                  )}
                  style={{
                    border: flyingToInput ? '1px solid #4FA2B4' : '1px solid #dee2e6',
                    borderRadius: '12px',
                  }}
                />
              </span>
            </div>

            {/* departure */}

            <div className="col">
              <FloatingIconFormControl
                value={departingInput}
                onClick={(e) => {
                  setShowDepartingCalendar(true);
                  setShowReturnCalendar(false);
                  setShowPassengerForm(false);
                }}
                type={'text'}
                label={'Departure'}
                icon={<CalendarSvg _color={departingValue ? '#4FA2B4' : '#ADADAD'} />}
                placeholder="Date of Departure"
                autoComplete="off"
                style={{
                  border: departingValue ? '1px solid #4FA2B4' : '1px solid #dee2e6',
                }}
              />
              {showDepartingCalendar && (
                <div className="position-absolute" onMouseLeave={() => setShowDepartingCalendar(false)}>
                  <Calendar
                    onChange={(e: any) => {
                      setDepartingInput(moment(e).format('YYYY/MM/DD'));
                      setShowDepartingCalendar(false);
                    }}
                    value={departingInput}
                    minDate={new Date()}
                  />
                </div>
              )}
            </div>

            {/*  */}
            {flightTypeRadioValue === 'round-trip' ? (
              <div className="col">
                <FloatingIconFormControl
                  value={returnDateInput}
                  onClick={(e) => {
                    setShowReturnCalendar(true);
                    setShowDepartingCalendar(false);
                    setShowPassengerForm(false);
                  }}
                  type={'text'}
                  label={'Return Date'}
                  icon={<CalendarSvg _color={returnValue ? '#4FA2B4' : '#ADADAD'} />}
                  placeholder="Date of Return"
                  autoComplete="off"
                  style={{
                    border: returnValue ? '1px solid #4FA2B4' : '1px solid #dee2e6',
                  }}
                />
                {showReturnCalendar && (
                  <div className="position-absolute" onMouseLeave={() => setShowReturnCalendar(false)}>
                    <Calendar
                      onChange={(e: any) => {
                        setReturnDateInput(moment(e).format('YYYY/MM/DD'));
                        setShowDepartingCalendar(false);
                        setShowReturnCalendar(false);
                      }}
                      value={returnDateInput}
                      minDate={departingInput ? new Date(departingInput) : new Date()}
                    />
                  </div>
                )}
              </div>
            ) : null}

            {/*  */}

            <div className="col position-relative">
              <label htmlFor="" className="form-label">
                Passengers
              </label>
              <input
                className="form-control"
                onClick={(e) => {
                  setShowDepartingCalendar(false);
                  setShowReturnCalendar(false);
                  setShowPassengerForm(true);
                }}
                value={`${adultsInput} Adult - ${childrenInput} Child - ${infantsInput} Infant`}
              />
              {showPassengerForm && (
                <div
                  id="passenger-details-holder"
                  className="position-absolute bg-body d-flex flex-column align-items-start justify-content-center"
                  style={{
                    left: '10px',
                    right: 0,
                    marginRight: 'auto',
                    width: '250px',
                  }}
                  onMouseLeave={() => setShowPassengerForm((prev) => !prev)}
                >
                  <div className="d-flex justify-content-between align-items-center align-self-stretch" style={{ padding: '8px 0px' }}>
                    <div className="gap-2 d-flex justify-content-center align-items-center">
                      <div>
                        <UserSvg />
                      </div>
                      <div className="passenger-count-label">
                        <span className="w-100 d-inline-block">Adults</span>
                        <span className="w-100 d-inline-block">Ages 18 and above</span>
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '12px' }}>
                      <button
                        className="passenger-count-btn adult-control"
                        disabled={adultsInput === 1 ? true : false}
                        onClick={() => setAdultsInput((prev) => prev - 1)}
                        style={{
                          borderColor: adultsInput === 1 ? 'transparent' : COLOR.primary,
                        }}
                      >
                        -
                      </button>
                      <span style={{ width: '10px' }}>{adultsInput}</span>
                      <button
                        className="passenger-count-btn adult-control"
                        disabled={adultsInput === 9 || maxPassengerCount === maxPassengers ? true : false}
                        onClick={() => setAdultsInput((prev) => prev + 1)}
                        style={{
                          color: maxPassengerCount === maxPassengers ? '#000' : COLOR.primary,
                          borderColor: maxPassengerCount === maxPassengers ? 'transparent' : COLOR.primary,
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center align-self-stretch" style={{ padding: '8px 0px' }}>
                    <div className="gap-2 d-flex justify-content-center align-items-center">
                      <div>
                        <ChildSvg />
                      </div>
                      <div className="passenger-count-label">
                        <span className="w-100 d-inline-block">Children</span>
                        <span className="w-100 d-inline-block">Ages 2 to 12</span>
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '12px' }}>
                      <button
                        className="passenger-count-btn"
                        disabled={childrenInput === 0 ? true : false}
                        onClick={() => setChildrenInput((prev) => prev - 1)}
                        style={{
                          borderColor: childrenInput === 0 ? 'transparent' : COLOR.primary,
                        }}
                      >
                        -
                      </button>
                      <span style={{ width: '10px' }}>{childrenInput}</span>
                      <button
                        className="passenger-count-btn"
                        disabled={childrenInput === 9 || maxPassengerCount === maxPassengers ? true : false}
                        onClick={() => setChildrenInput((prev) => prev + 1)}
                        style={{
                          color: maxPassengerCount === maxPassengers ? '#000' : COLOR.primary,
                          borderColor: maxPassengerCount === maxPassengers ? 'transparent' : COLOR.primary,
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center align-self-stretch" style={{ padding: '8px 0px' }}>
                    <div className="gap-2 d-flex justify-content-center align-items-center">
                      <div>
                        <InfantSvg />
                      </div>
                      <div className="passenger-count-label">
                        <span className="w-100 d-inline-block">Infants</span>
                        <span className="w-100 d-inline-block">Under 2 years old</span>
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '12px' }}>
                      <button
                        className="passenger-count-btn"
                        disabled={infantsInput === 0 ? true : false}
                        onClick={() => setInfantsInput((prev) => prev - 1)}
                        style={{
                          borderColor: infantsInput === 0 ? 'transparent' : COLOR.primary,
                        }}
                      >
                        -
                      </button>
                      <span style={{ width: '10px' }}>{infantsInput}</span>
                      <button
                        className="passenger-count-btn"
                        disabled={infantsInput === 9 || maxPassengerCount === maxPassengers ? true : false}
                        onClick={() => setInfantsInput((prev) => prev + 1)}
                        style={{
                          color: maxPassengerCount === maxPassengers ? '#000' : COLOR.primary,
                          borderColor: maxPassengerCount === maxPassengers ? 'transparent' : COLOR.primary,
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* cabin class */}

            <div className="col">
              <label htmlFor="" className="form-label">
                Cabin Class
              </label>
              <select
                className="form-select w-75"
                id="cabin-class"
                aria-label="Default select example"
                onClick={() => {
                  setShowPassengerForm(false);
                  setShowDepartingCalendar(false);
                  setShowReturnCalendar(false);
                }}
                onChange={(e) => {
                  setCabinInput(e.target.value);
                }}
                defaultValue={'economy'}
              >
                <option value="economy">Economy</option>
                <option value="business">Business</option>
                <option value="first">First Class</option>
                <option value="premiumEconomy">Premium Economy</option>
              </select>
            </div>

            {/* button */}

            <div className="col-12 pt-4">
              <button
                className="btn btn-primary w-full"
                disabled={isSearchButtonDisabled}
                onClick={() => {
                  const urlGetParams = `?originCity=${fromDetails?.[0]?.cityName}&destinationCity=${toDetails?.[0]?.cityName}&originCode=${flyingFromInput}&destinationCode=${flyingToInput}&departureDate=${departingInput}&returnDate=${returnDateInput}&class=${cabinInput}&adults=${adultsInput}&minors=&children=${childrenInput}&infants=${infantsInput}`;
                  let newUrl = `/flights/one-way${urlGetParams}`;
                  if (flightTypeRadioValue === 'round-trip') newUrl = `/flights/round-trip${urlGetParams}`;
                  // console.log(newUrl);
                  // navigate(newUrl);
                  window.location.href = newUrl;
                }}
              >
                <div className="d-flex flex-row align-content-center justify-content-center">
                  <span className="me-1 d-flex align-items-center">
                    <SearchSvg _color="#fff" />
                  </span>{' '}
                  SEARCH
                </div>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FlightResultsSearchFormMobile;
