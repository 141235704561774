import React from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { NavBar } from '../../components/NavBar';
import Footer from '../../components/Footer';
import { CheckCircle2, ChevronRight } from 'lucide-react';
import pluralize from 'pluralize';
import '../../resources/css/booking-history.scss';
import '../../resources/css/booking-history-details.scss';
import { HotelHistoryInformationHooks } from '../../hooks/HotelHistoryInformationHooks';
import moment from 'moment';
import { HotelStars } from '../../components/HotelStars';
import { formatPriceNoRound, titleCase } from '../../utils/data.utils';
import { CheckSvg } from '../../resources/svg/CheckSvg';
import { PaymentMethodLogo } from './PaymentMethodLogo';
import { RoomInclusion } from './RoomInclusion';
import { CancellationPolicy } from '../../components/CancellationPolicy';
import { AlertBox } from '../../components/AlertBox';
import { UserSvg } from '../../resources/svg/UserSvg';
import { DoorOpenSvg } from '../../resources/svg/DoorSvg';

export const BookingHistoryHotelInformation = () => {
  const navigate = useNavigate();
  const [param] = useSearchParams();
  const id = param.get('id') as string;

  const { hotelDetails } = HotelHistoryInformationHooks(id);
  const { customerDetails, paymentDetails, rooms } = hotelDetails;
  const paymentMethod = paymentDetails?.paymentGateway;

  const [showMoreAmenities, setShowMoreAmenities] = React.useState(false);
  const [allAmenities, setAllAmenities] = React.useState<string[]>();

  let totalRoomPrices: number = 0;
  let totalTaxes: number = 0;
  rooms?.forEach((room: any) => {
    totalRoomPrices += room.TotalFare;
    totalTaxes += room.TotalTax;
  });

  return (
    <>
      <NavBar />
      <main id="hotel-history-page">
        <div className="container">
          <div className="row pb-5 justify-content-center">
            <div className="col-10 history-breadcrumb generalsans-medium" id="">
              <ul>
                <li>
                  <a
                    href="#/"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                  >
                    Bookings
                  </a>
                </li>
                <li>
                  <ChevronRight color="#0B5B6C" width={16} height={16} />
                </li>
                <li>Booking#{id}</li>
              </ul>
            </div>

            <div className="col-10" id="hotel-information">
              <p style={{fontSize: 22, fontWeight: 700}}>Review your booking</p>

              <div className="hotel-info mt-4">
              <div
                  id="hotel-picture"
                  className=""
                  style={{
                    backgroundImage: `url(${hotelDetails?.hotelImage})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    objectFit: 'cover',
                    
                  }}
                ></div>
                <div id="hotel-name" className="w-[80%]">
                  <div id="hotel-name-top">
                    <div>
                      <p style={{fontSize: 28 , fontWeight: 700}}>
                        {hotelDetails?.nights} {pluralize('Night', hotelDetails?.nights)} at {hotelDetails?.hotelName}
                      </p>
                      <span>{titleCase(hotelDetails?.status ?? '')}</span>
                    </div>
                    <div>
                      <span className="flex items-center">
                        <HotelStars stars={hotelDetails?.hotelRating} color="#0C5C6C" /> {hotelDetails?.hotelRating} - star hotel
                      </span>
                      <span className="flex items-center gap-1">
                        <UserSvg _width={10} _height={10} _color='#0C5C6C'/> {`${hotelDetails?.guestsCount} ${pluralize('Guest', hotelDetails?.guestsCount)}`}
                      </span>
                      <span className="flex items-center gap-1">
                        <DoorOpenSvg /> {`${hotelDetails?.roomCount} ${pluralize('Room', hotelDetails?.roomCount)}`}
                      </span>
                    </div>
                  </div>
                  <div id="hotel-name-bottom">
                    <div id="check-in">
                      <span>Check-in</span>
                      <span>
                        <span className="text-[#156474]">{moment(hotelDetails?.checkInDate).format('MMMM DD, YYYY')}</span>
                        {` after ${hotelDetails?.checkInTime}`}
                      </span>
                    </div>
                    <div id="check-out">
                      <span>Check-out</span>
                      <span>
                        <span className="text-[#156474]">{moment(hotelDetails?.checkOutDate).format('MMMM DD, YYYY')}</span> {`until ${hotelDetails?.checkOutTime}`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="galago-card mt-5" id="hotel-guests">
                <div className="galago-card-title">Room Information</div>
                <div className="galago-card-body p-0">
                  <table id="table-room-history" className="table-auto w-100">
                    <thead>
                    <tr>
                      <td className="border-l-0 border-t-0 text-center p-2">Room Name</td>
                      <td className="border-t-0 text-center p-2">Room Amenities</td>
                      <td className="border-r-0 border-t-0 text-center p-2">Cancellation Policy</td>
                    </tr>
                    </thead>
                    <tbody>
                      {rooms?.map((room: any, index: React.Key) => (
                        <tr key={index}>
                          <td className="border-l-0 w-[33.33%] border-b-0">
                            <b>{room.Name[0]}</b>
                            <br />
                            <RoomInclusion inclusion={room.Inclusion} />
                          </td>
                          <td className="border-b-0 w-[33.33%]">
                            {room.Amenities.map((amenity: any, index: React.Key) => {
                              // only display six amenities
                              if (+index > 5) return <></>;
                              return (
                                <div key={index} className="mr-4 inline-block">
                                  <span className="flex items-center">
                                    <CheckSvg /> {amenity}
                                  </span>
                                </div>
                              );
                            })}
                            <span
                              onClick={() => {
                                setShowMoreAmenities((prev) => !prev);
                                setAllAmenities(room.Amenities);
                              }}
                              className="block mt-2 text-[#006FA9]] cursor-pointer" style={{ fontSize: '12px', fontWeight: 500}}
                            >
                              See More
                            </span>
                          </td>
                          <td className="border-r-0 border-b-0 w-[33.33%]">
                            <CancellationPolicy room={room} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="galago-card mt-5" id="guest-information">
                <div className="galago-card-title">Guest Information</div>
                <div className="galago-card-body flex justify-between">
                  <div className="flex flex-column gap-2 w-[50%] py-4">
                    <span className="text-sm">Lead Guest</span>
                    <span className="text-medium">{`${customerDetails?.title}. ${titleCase(customerDetails?.name || '')}`}</span>
                  </div>

                  <div className="flex flex-column gap-2 w-[50%] pl-8 py-4 border-l-1 border-color-[#DBDBDB]">
                    <span className="text-sm">Email</span>
                    <span className="text-medium">{customerDetails?.email}</span>
                  </div>
                  {customerDetails?.mobileNumber && !customerDetails?.mobileNumber.includes('null') && customerDetails?.mobileNumber.length >= 3 && (
                    <div className="flex flex-column gap-2 w-[50%] pl-8 py-4 border-l-1 border-color-[#DBDBDB]">
                      <span className="text-sm">Contact Number</span>
                      <span className="text-medium">{customerDetails?.mobileNumber}</span>
                    </div>
                  )}
                </div>
              </div>

              <div className="galago-card mt-5" id="price-overview">
                <div className="galago-card-title">Price Overview</div>
                <div className="galago-card-body p-4">
                  <div className="flex justify-between items-center">
                    <span className="font-medium">
                      Total Room Price
                      <br />
                      {`(${hotelDetails?.roomCount} ${pluralize('room', hotelDetails?.roomCount)} x ${hotelDetails?.nights} ${pluralize('night', hotelDetails?.nights)})`}
                    </span>
                    <span className="text-right">PHP {formatPriceNoRound(totalRoomPrices - totalTaxes)}</span>
                  </div>
                  <div className="flex justify-between item-center pb-3 mt-3 border-b-1 border-color-[#E3D6E8]">
                    <span  style={{ fontSize: '16px', fontWeight: '500'}}>Taxes and Fees</span>
                    <span className="text-right"  style={{ fontSize: '16px', fontWeight: '500'}}>PHP {formatPriceNoRound(totalTaxes)}</span>
                  </div>

                  <div className="pt-3 pb-4 flex justify-between">
                    <div className="text-lg font-semibold">Total Charges</div>
                    <div className="flex flex-column text-right">
                      <span className="text-[#0C5C6C]" style={{ fontSize: '20px', fontWeight: '900'}}>{`PHP ${formatPriceNoRound(totalRoomPrices)}`}</span>
                      <span className="flex items-center justify-end gap-2 align-middle">
                        paid via <PaymentMethodLogo method={paymentMethod === '' ? 'others' : paymentMethod} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <AlertBox
        show={showMoreAmenities}
        title="Facilities and Amenities"
        onHide={() => setShowMoreAmenities((prev) => !prev)}
        children={
          <div className="py-2 px-4 overflow-x-auto h-[600px]">
            {/* <div className="flex flex-wrap gap-2">
              {allAmenities?.map((amenity: string, index: React.Key) => (
                <span key={index} className="bg-[#E3D6E8] p-2 rounded-xl">
                  {amenity}
                </span>
              ))}
            </div> */}
            <div className="">
              {allAmenities?.map((amenity: string, index: React.Key) => (
                <div key={index} className="flex items-center gap-2 border-b-1 border-color-[#DBDBDB] py-2">
                  <CheckCircle2 />
                  <span>{amenity}</span>
                </div>
              ))}
            </div>
          </div>
        }
      />

      <Footer />
    </>
  );
};
