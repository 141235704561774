import NavBarTransparent from "../../../components/NavBarTransparent";
import { ChevronRightSvg } from "../../../resources/svg/ChevronRightSvg";
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import { LinkedInSvg } from '../../../resources/svg/LinkedinSvg';
import {TwitterSvg} from '../../../resources/svg/TwitterSvg';
import {LinkSvg} from '../../../resources/svg/LinkSvg';
import React from "react";
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';


function SurviveNAIAImmigration(){
const [fbHover, setFbHover] = React.useState('#ADADAD');
const [liHover, setLiHover] = React.useState('#ADADAD');
const [twtHover, setTwtHover] = React.useState('#ADADAD');
const [linkHover, setLinkHover] = React.useState('#ADADAD');

return (
  <>
    <NavBarTransparent />

    <div className="hero_sec">
      <img
        src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/How+to+Survive+NAIA+Immigration.jpg"
        alt="How to Survive NAIA Immigration"
        loading="lazy"
        onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
      />
      <div className="gradient_overlay">
        <h1>Travel Tips</h1>

        <div className="bread_crumbs">
          <a href="/#/blogs">Blogs</a>
          <ChevronRightSvg _color="#ffffff" _width={20} _height={20} />
          <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
            How to Survive NAIA Immigration
          </a>
        </div>
      </div>
    </div>

    <div className="articleBody">
      <div className="articleHeader">
        <h1>How to Survive NAIA Immigration</h1>

        <div className="info">
          <p className="authorSec">
            By <u>Zoe Alcazaren</u>
          </p>
          <p className="separator">•</p>
          <p className="articleDate">Nov 14, 2023</p>
          <p className="separator">•</p>
          <p className="article-read">9 min read</p>
        </div>

        <div className="shareBtnContainer">
          <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setFbHover('#006FA9')} onMouseLeave={() => setFbHover('#ADADAD')}>
            <FacebookSvg _color={fbHover} _width={24} _height={24} />
          </a>

          <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLiHover('#006FA9')} onMouseLeave={() => setLiHover('#ADADAD')}>
            <LinkedInSvg _color={liHover} _width={24} _height={24} />
          </a>

          <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setTwtHover('#006FA9')} onMouseLeave={() => setTwtHover('#ADADAD')}>
            <TwitterSvg _color={twtHover} _width={24} _height={24} />
          </a>

          <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLinkHover('#006FA9')} onMouseLeave={() => setLinkHover('#ADADAD')}>
            <LinkSvg _color={linkHover} _width={24} _height={24} />
          </a>
        </div>
      </div>

      <div className="content">
        <div className="notice_container">
          <div className="notice">
            <p>
              As of September 3, 2023, the Inter-Agency Council Against Traffic (IACAT) revised departure rules for outbound Filipino citizens. All information in this article is accurate from the
              time of writing.
            </p>
          </div>

          <div className="noticeDescription">
            <p>
              Whether{' '}
              <a href="/flying-for-the-first-time-here-are-some-helpful-tips-for-you" target="_blank" rel="noreferrer">
                traveling out of Manila for the first time
              </a>{' '}
              or slightly out of practice when navigating one of Ninoy Aquino International Airport’s four terminals, brushing up on your airport and immigration know-how is essential to a smooth
              travel experience. After all, you don’t want your first departure experience to be memorable for the wrong reasons!
            </p>
            <p>From gathering the appropriate documents to preparing for the much-dreaded security check, this guide will tell you what you need to know to survive NAIA immigration.</p>
          </div>
        </div>

        <div className="storywithTitleImageParagraph">
          <div className="head">
            <h2>How to get to NAIA?</h2>
          </div>

          <div className="info_grp">
            <p>
              NAIA terminals 1, 2, 3, and 4 are located between Parañaque and Pasay, with Terminal 3 being the most congested of the four. You can travel to each terminal via car or through the
              following public transportation options like ride-hailing apps, airport shuttles, point-to-point (P2P) buses, and metered taxis.
            </p>
            <p>
              Airport shuttle buses cost between PHP 200 and PHP 300, depending on your pickup point. Ride-hailing apps and metered taxis will comparably cost more—between PHP 350 and PHP 1,000,
              depending on the pickup point, time of day, and available app discounts. Each terminal has parking options, with overnight rates starting from PHP 300. Unfortunately, you can’t reserve
              parking slots, as each lot functions on a first-come, first-serve basis.
            </p>
            <p>Should your terminal’s parking lot be at total capacity, you can opt for a Park ‘N Fly slot, which starts at PHP 375 and has valet services.</p>
          </div>

          <div className="galaGuide">
            <h3>GalaGUIDE!</h3>
            <p>
              Leave early for the airport and prepare yourself for Metro Manila’s notoriously unpredictable traffic. It’s especially bad near NAIA. To be safe, double check on Metro Manila traffic
              reports via Google Maps, Waze, or the official MMDA platforms.
            </p>
          </div>
        </div>

        <div className="extensive_story">
          <h2>Know before you go: Not getting offloaded</h2>
          <p>
            Adventurers know that traveling doesn’t come cheap, and often there’s the occasional mess of documents. However, knowing the following requirements can simplify your journey and survival
            through NAIA.
          </p>

          <div className="galaGuide">
            <h3>GalaGUIDE!</h3>
            <p>
              It always helps to be prepared! Familiarize yourself with the most{' '}
              <a href="/guide-to-travel-requirements-in-the-philippines" target="_blank" rel="noreferrer">
                updated travel requirements in the Philippines
              </a>
              .
            </p>
          </div>

          <div className="bullet_grp">
            <div className="bullet_list">
              <p className="title">Fees</p>

              <p className="title_desc">
                If you’ve ever felt the disappointment of your flight costs skyrocketing after tax, you aren’t alone. Traveling comes with its exorbitant fees—and sometimes there are lots of them.
                Here’s what you should prepare!
              </p>

              <ul>
                <li>Travel tax: PHP 1,620</li>
                <li>Terminal fee: PHP 50 to PHP 300 (domestic), PHP 650 to PHP 850 (international)</li>
              </ul>
            </div>

            <div className="bullet_list">
              <p className="title_desc">
                You can pay these fees online when purchasing your flight ticket or upon check-in. However, you might enjoy an exception if you belong to the following categories:
              </p>

              <ul>
                <li>An OFW or Filipino citizen residing abroad permanently</li>
                <li>A foreign diplomatic representative in the Philippines</li>
                <li>A U.N. employee</li>
                <li>U.S. military personnel</li>
                <li>A member of an international carrier crew</li>
                <li>A foreign service employee</li>
                <li>A Philippine government employee traveling on official business</li>
                <li>A student on a scholarship</li>
                <li>An employee of a multinational company</li>
              </ul>
            </div>

            <p>If you are a dependent of an OFW, your travel tax will be PHP 300.</p>

            <div className="galaGuide">
              <h3>Take Note</h3>
              <p>You must apply for a reduction or exemption before your flight at the nearest TIEZA office. The standard reduced travel tax is roughly PHP 810.</p>
            </div>
          </div>

          <div className="requirement">
            <p className="title">Check-in and boarding</p>
            <p className="title_desc">
              Check-in counters open roughly an hour before domestic flights and two hours before international flights. You must present a physical or digital copy of your{' '}
              <span>boarding pass, itinerary, and passport or valid primary ID</span>.
            </p>
          </div>

          <div className="bullet_grp">
            <div className="bullet_list">
              <p className="title">Travel requirements and documents</p>

              <p className="title_desc">
                Domestic flights <span>do not require passports</span>. Instead, you can choose from the following valid government IDs:
              </p>

              <ul>
                <li>Driver’s license</li>
                <li>PWD/Senior Citizen card</li>
                <li>National ID or UMID</li>
                <li>Postal ID</li>
                <li>SSS ID</li>
                <li>GSIS ID/e-card</li>
                <li>Government office ID</li>
                <li>TIN card</li>
                <li>NBI clearance (non-expired)</li>
                <li>Police clearance</li>
                <li>PhilHealth ID</li>
                <li>OWWA ID</li>
                <li>OFW ID</li>
                <li>Integrated Bar of the Philippines ID</li>
                <li>Voter’s ID</li>
                <li>Seaman’s book</li>
                <li>Alien Certification of Registration</li>
                <li>Company ID (must be regulated by BSP, SEC, or IC)</li>
                <li>National Council of Disability Affairs ID</li>
                <li>Philippine Regulations Commission ID</li>
              </ul>
            </div>

            <div className="bullet_list">
              <p>
                International or outbound flights require a passport on the other hand. At immigration and at check-in, travelers must present the following basic travel documents to the immigration
                officer to avoid being offloaded:
              </p>
              <ul>
                <li>A passport with at least six months of validity from the date of departure</li>
                <li>Your boarding pass</li>
                <li>A confirmed return or roundtrip ticket</li>
                <li>A valid visa, if applicable</li>
              </ul>
            </div>
          </div>

          <div className="requirement">
            <p className="title">Additional supporting documents</p>
            <p className="title_desc">
              Occasionally, immigration officers will interview you regarding your purpose of travel after inspecting your travel documents. Besides the basic travel documents mentioned above, you may
              be required to show additional supporting documents like your travel itinerary, proof of employment, and proof of financial capacity to support your travel. Here are the supporting
              documents you may need to bring and present to immigration:
            </p>
          </div>

          <div className="bullet_grp">
            <div className="bullet_list">
              <p className="title">For Filipino tourists self-funding their travel:</p>
              <ul>
                <li>A confirmed return or roundtrip ticket</li>
                <li>Proof of your hotel booking or accommodation</li>
                <li>Proof of your financial capacity or source of income (e.g. bank statements) – make sure these are consistent with your declared purpose of travel</li>
                <li>Proof of employment</li>
              </ul>
            </div>

            <div className="bullet_list">
              <p className="title_desc">
                Presenting employment documents to immigration can be tricky for freelancers. If you don’t have a company ID or a certificate of work, you can instead present the following documents:
              </p>
              <ul>
                <li>A copy of your ITR from the last 12 months</li>
                <li>Any indication from a client that you’re in business with them, similar to an official certificate of employment</li>
                <li>A business registration certificate, if you’re a sole proprietor</li>
              </ul>
            </div>

            <div className="bullet_list">
              <p className="title_desc">
                Meanwhile, if you’re <span>a fresh graduate and currently unemployed,</span> your best option is to find a sponsor for your trip—likely a family member. Then, you must present the
                following documents:
              </p>
              <ul>
                <li>Authenticated affidavit of support that indicates your relationship to the sponsor</li>
                <li>Authenticated affidavit of undertaking</li>
              </ul>
            </div>

            <div className="bullet_list">
              <p className="title">For sponsored tourists:</p>
              <ul>
                <li>A confirmed return or roundtrip ticket</li>
                <li>An original PSA-issued birth certificate or marriage certificate showing the exact relationship between the passenger and sponsor</li>
                <li>Copy of the sponsor’s valid passport</li>
                <li>
                  Copy of the sponsor’s valid work visa/permit, residence permit or any equivalent document; or (for OFW sponsors), an Overseas Employment Certificate (OEC), e-receipt, OFW clearance
                </li>
              </ul>
            </div>

            <p>
              If your sponsor is <span>a relative up to the fourth civil degree</span>, you’ll additionally need them to provide an Original Affidavit of Support and Guarantee (AOSG) duly notarized by
              the Philippine Embassy, Consulate, or Honorary Consulate. If your sponsor isn’t a relative, you’ll need the AOSG plus substantial proof of your relationship.
            </p>
            <p>
              Always review international destination requirements before traveling. We know it’s a lot. To ease your movement through the airport, we suggest putting all of these in a handy passport
              and travel document organizer!
            </p>
          </div>
        </div>

        <div className="img_container">
          <img
            src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/How+to+survive+NAIA+Immigration/02+ConvertKit.png"
            alt="First Aid Kit"
            loading="lazy"
            onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
          />
          <p>Photo by ConvertKit via Unsplash</p>
        </div>

        <div className="extensive_story">
          <h2>NAIA Immigration and Departure Survival Guide</h2>
          <p>Now that you know the fees and requirements of traveling from NAIA, it’s time to dissect the departure process.</p>

          <div className="requirement">
            <p className="title">Arrival and Security Check</p>
            <p className="title_desc">
              As a rule of thumb, never underestimate the number of people waiting at the airport entrance, regardless of your terminal. Much less, Metro Manila traffic. Play it safe.
            </p>
          </div>

          <div className="galaGuide">
            <h3>GalaGUIDE!</h3>
            <p>For domestic travelers, you’ll want to arrive at the airport 2 hours before your scheduled departure time. For international travelers, be there 3 hours ahead.</p>
          </div>

          <div className="info_grp">
            <p>
              Prepare to present your boarding pass and passport or valid ID to security upon entrance. Keeping these documents in a small, accessible pouch can keep you from fishing for them inside
              your backpack.
            </p>
            <p>Avoid wearing too many accessories! You’ll have to place them in a basket upon the initial security check.</p>
          </div>

          <div className="requirement">
            <p className="title">Travel Tax and Terminal Fee</p>
            <p className="title_desc">
              Remember, you can pay your travel tax and terminal fee online! It's most often included in your air ticket, too. But if not, we recommend doing so, as tax counters in NAIA terminals are
              typically jam-packed with people.
            </p>
            <p>
              If paying in person, <span>always pay your fees first</span>, as you’ll have to present a receipt at the check-in counter.
            </p>
          </div>

          <div className="requirement">
            <p className="title">Check-In</p>
            <p>
              Checking in is the most straightforward process on your list of travel to-dos. Find your airline’s dedicated check-in counter, which should be indicated on an e-board near the entrance.
              Present your valid ID, passport, boarding pass, itinerary, and tax and terminal fee receipt.
            </p>
            <p>
              Then, check in any baggage you have. Most airlines cap checked baggage weights at 32 kg. Otherwise, you’ll have to repack your luggage or pay an excess baggage fee to distribute this
              weight.
            </p>
            <p>Excess baggage fees can range between PHP 8,400 and PHP 11,300 (charged in USD). To be safe, weigh your luggage once you’ve packed all your stuff if you can!</p>
          </div>

          <div className="bullet_grp">
            <div className="bullet_list">
              <p className="title">Immigration</p>

              <p className="title_desc">
                After checking in your baggage, the officer should hand you a departure card. Use a black ballpoint pen to fill out this form. We recommend filling this out in line, as you won’t want
                to hold up the people behind you. Write as legibly as you can to avoid having to re-write your answers.
              </p>

              <p className="title_desc">Once at the officer window, you’ll have to answer simple questions similar to the following:</p>

              <ul>
                <li>Where are you going and for how long?</li>
                <li>Who are you traveling with?</li>
                <li>Where will you be staying?</li>
                <li>What is the purpose of your visit?</li>
              </ul>
            </div>

            <div className="bullet_list">
              <p className="title_desc">
                Remember, immigration officers won’t sound as friendly as you want them to—don’t take this personally! Answer accurately and succinctly. Deep breaths. Don’t panic. Immigration officers
                typically subject passengers to additional questioning based on <span>age, educational attainment, and financial capability</span>. If they question you further, it may be due to the
                following circumstances:
              </p>

              <ul>
                <li>You’re a first-time traveler heading to a less popular destination.</li>
                <li>You don’t have a steady source of income.</li>
              </ul>

              <p>If these circumstances apply to you, scroll back up and take note of the supporting documents you may need to have at the ready!</p>
            </div>
          </div>

          <div className="requirement">
            <p className="title">Security Check</p>
            <p className="title_desc">
              Once you get your passport stamped, you’ll proceed to the final security check, in which you’ll place your belongings in a tray (including your shoes) and walk through a metal detector.
            </p>

            <p className="title_desc">
              Unless you’re carrying prohibited items (such as corrosive substances, radioactive or toxic materials, devices that discharge projectiles, objects with sharp points, etc), you should go
              through without a problem.
            </p>
          </div>

          <div className="requirement">
            <p className="title">Boarding</p>
            <p className="title_desc">
              Next, you’ll head to your assigned boarding gate and wait for your boarding time. Typically, officers will board passengers according to seat numbers. Priority passengers include{' '}
              <span>PWDs, seniors, and those traveling with young children</span>.
            </p>

            <p className="title_desc">Be ready to present your passport and boarding pass to the crew before boarding.</p>
          </div>
        </div>

        <div className="img_container">
          <img
            src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/How+to+survive+NAIA+Immigration/03+Anete+Lusina.png"
            alt="Travel Matters"
            loading="lazy"
            onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
          />
          <p>Photo by Anete Lūsiņa via Unsplash</p>
        </div>

        <div className="extensive_story">
          <h2>Other Practical Tips</h2>
          <p>You may think all the preparation you need to enjoy a safe flight happens at the airport, but there is much to do beforehand.</p>

          <div className="bullet_grp">
            <div className="bullet_list">
              <p className="title">Be prepared!</p>

              <p className="title_desc">A successful flight begins with ample preparation. If you’re forgetful, make a checklist of things you need. These include:</p>

              <ul>
                <li>Passport</li>
                <li>Tickets</li>
                <li>Travel itinerary</li>
                <li>Additional identification</li>
                <li>Other essential documents</li>
                <li>Clothes and other necessities</li>
                <li>Toiletries and first aid.</li>
              </ul>

              <p className="title_desc">
                Pack your bag strategically. Do not place non-essential items in your carry-on, as you won’t want to shoulder additional weight through long immigration lines. Place frequently needed
                items in easy-to-access pockets.
              </p>
            </div>

            <div className="bullet_list">
              <p className="title_desc">
                Get as much rest as possible if you’re going on a long-haul flight—never underestimate the power of a good night’s sleep! If you live far from the airport, consider hotels near NAIA to
                reduce travel time and get the rest you need. Some nearby hotels include the following:
              </p>

              <ul>
                <li>Hotel 101 Manila</li>
                <li>Sheraton Manila Hotel</li>
                <li>Hilton Manila</li>
                <li>Hyatt Regency Manila</li>
                <li>Savoy Hotel Manila</li>
                <li>Belmont Hotel Manila</li>
                <li>Conrad Manila</li>
              </ul>
            </div>
          </div>

          <div className="info_grp">
            <p>Most of these hotels are located near shopping districts, so you can pick up items you may have forgotten to pack. Don’t forget entertainment to keep yourself occupied on the plane.</p>
            <p>If you’re traveling to an international destination and haven’t yet converted your pesos, NAIA has stalls where you can change local bills to dozens of currencies.</p>
          </div>

          <div className="requirement">
            <p className="title">Know your rights... just in case!</p>
            <p className="title_desc">
              Understanding your rights as a traveler can save you a lot of frustration. Familiarize yourself with airline policies, security procedures, and, yes, horror stories.
            </p>
            <p className="title_desc">
              NAIA is notorious for its long lines, primarily through security and immigration. If possible, arrive earlier than needed and know what you can bring in your carry-on bag. Awareness of
              your rights can help you navigate flight delays, cancellations, lost baggage, or offloading.
            </p>
          </div>

          <div className="requirement">
            <p className="title">Don’t be suspicious</p>
            <p className="title_desc">
              While security measures are in place to ensure everyone's safety, being overly suspicious or exhibiting nervous behavior can draw unnecessary attention. Remember, immigration officers
              don’t want to offload you—they must be stern to get the answers they need.
            </p>
            <p className="title_desc">
              Follow security instructions as calmly and confidently as possible. Wear easily removable footwear and lay off the accessories during security to prevent holding up the line and causing
              panic.
            </p>
            <p className="title_desc">Cooperate with the airport staff. Do whatever you can to make the process easier for yourself and others.</p>
          </div>

          <div className="requirement">
            <p className="title">Plan and arrive early</p>
            <p className="title_desc">
              Time is your ally when it comes to airport travel. Plan your journey meticulously, considering traffic, security lines, and potential delays. Arriving at the airport well before your
              flight lets you handle unforeseen situations easily.
            </p>
            <p className="title_desc">
              Metro Manila is famous for its seemingly impossible traffic jams, so you’re better safe than sorry! Some airlines, such as Philippine Airlines, offer early check-in. With PAL, flyers can
              check in six hours before departure at Terminal 1 and five hours before departure at Terminal 2.
            </p>
          </div>
        </div>

        <div className="img_container">
          <img
            src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/How+to+survive+NAIA+Immigration/04+Anete+Lusina.png"
            alt=""
            loading="lazy"
            onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
          />
          <p>Photo by Anete Lūsiņa via Unsplash</p>
        </div>

        <div className="extensive_story">
          <div className="info_grp">
            <p>
              If you clear check-in, security, and immigration well before your boarding time, enjoy what the airport offers. NAIA has several restaurants, duty-free stores, kiosks for purchasing
              pasalubong, and other places to hang out before your flight.
            </p>
            <p>
              For families traveling with young children, NAIA houses several nursing rooms with playpens, bottle warmers, diapers, and dedicated spaces for mothers to breastfeed. Terminals 1, 3, and
              4 also have Kiddie Traveler Lounges with play areas, toys, and games. In addition, all four NAIA terminals have chapels.
            </p>
            <p>Chat with your fellow travelers. Read a book or watch a movie on your tablet. Don’t forget to set an alarm if you decide to wait away from your gate!</p>
          </div>
        </div>

        <div className="footnote">
          <div className="title_container">
            <h2>The Bottom Line</h2>
          </div>

          <div className="info">
            <p>Despite its mixed reviews and questionable reputation, ample preparation can make the Ninoy Aquino International Airport is simple to navigate and clear without a problem.</p>
            <p>
              By researching your destination’s travel requirements, planning ahead, and familiarizing yourself with the security and immigration process at your designated terminal, you can avoid
              being offloaded and enjoy a hassle-free journey.
            </p>
            <p>If you’re ready to conquer the Ninoy Aquino International Airport but don’t yet have a destination, browse our offers at GalaGO!</p>
            <p>
              Why go through the trouble of jumping through hoops to book your dream vacation when you can get all you need with a GalaGO! travel package? Contact us to learn more about the available
              destinations and how you can save on your trip!
            </p>
          </div>
        </div>
      </div>

      <hr />

      <div className="featured_blogs">
        <h2>Featured Blogs</h2>
        <div className="card_container">
          <div className="blog_card col-md-6 col-12">
            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg"
                alt="Discover the Best Things to Do in Bohol All in One Tour!"
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />

              <div className="badge">
                <h2>Travel Tip</h2>
              </div>
            </div>

            <div className="info_grp">
              <div className="detail">
                <p className="date"> September 25, 2023</p>
                <p className="separator">·</p>
                <p className="duration">6 min read</p>
              </div>

              <h3>Discover the Best Things to Do in Bohol All in One Tour!</h3>

              <a href="/#/discover-the-best-things-to-do-in-bohol-all-in-one-tour" className="read_article_btn">
                <div className="read_btn">
                  <p>Read Article</p>
                  <ArrowRightSvg _color="#006FA9" _width={34} _height={14} />
                </div>
              </a>
            </div>
          </div>

          <div className="blog_card col-md-6 col-12">
            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg"
                alt="Guide to Travel Requirements in the Philippines"
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />

              <div className="badge">
                <h2>Travel Tip</h2>
              </div>
            </div>

            <div className="info_grp">
              <div className="detail">
                <p className="date"> September 25, 2023</p>
                <p className="separator">·</p>
                <p className="duration">6 min read</p>
              </div>

              <h3> Guide to Travel Requirements in the Philippines</h3>

              <a href="/#/guide-to-travel-requirements-in-the-philippines" className="read_article_btn">
                <div className="read_btn">
                  <p>Read Article</p>
                  <ArrowRightSvg _color="#006FA9" _width={34} _height={14} />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);
}
export default SurviveNAIAImmigration