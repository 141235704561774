import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { hotelSearch } from '../../../utils/apiRoutes';
import { AnimatedGalagoLoading } from '../../../components/AnimatedGalagoLoading';
import HotelCard from './HotelCard';
import HotelSearchResultsSkeletonCard from './HotelSearchResultsSkeletonCard';
import { GalagoButton } from '../../../components/Button';
import { SomethingWentWrong } from '../../error/SomethingWentWrong';
import { useNavigate } from 'react-router-dom';
import { TiLocationOutline } from 'react-icons/ti';
import { GallyLookLeft } from '../../../resources/svg/GallySvg';
import { ChevronDownV2 } from '../../../resources/svg/ChevronDownV2';
import { HotelFiltersStore } from '../../../store/HotelFiltersStore';
import { HotelSortingStore } from '../../../store/HotelSortingStore';
import { ErrorGalagoPage } from '../../../components/ErrorGalagoPage';

type HotelSearchResultType = {
  inputData: {
    keyword?: string;
    checkInDate?: string;
    checkOutDate?: string;
    adults?: number;
    rooms?: number;
    longitude: number;
    latitude: number;
  };
  enableQuery: boolean;
  setEnableQuery: any;
  searchParams: URLSearchParams;
};

const HotelSearchResults = ({ searchParams, enableQuery, setEnableQuery }: HotelSearchResultType) => {
  const navigate = useNavigate();
  const { setFilterKey, setPriceRange, setSelectedPriceRange, selectedPriceRange, setFacilitiesList, setBedPreferencesList } = HotelFiltersStore();
  const { hotelSort, setHotelSort, setSelectedFilter } = HotelSortingStore();
  const getCheckInDate = searchParams.get('checkInDate');
  const getCheckOutDate = searchParams.get('checkOutDate');
  const getAdults = searchParams.get('adults');
  const getRooms = searchParams.get('rooms');
  const getLatitude = searchParams.get('latitude');
  const getLongitude = searchParams.get('longitude');
  const getKeyword = searchParams.get('keyword');
  const getFilterKey = searchParams.get('filterKey');
  const getSelectedPriceRange = searchParams.get('priceRange');
  const getHotelStars = searchParams.get('hotelStars');
  const getHotelFacilities = searchParams.get('hotelFacilities');
  const getBedPreferences = searchParams.get('bedPreferences');
  const getMealInclusions = searchParams.get('mealInclusions');
  const getRoomFacilities = searchParams.get('roomFacilities');
  const getSortedBy = searchParams.get('sortedBy');

  const [inputData, setInputData] = React.useState({});
  const [showFilters, setShowFilters] = useState(false);
  // const []

  let urlGetParams = '';
  if (getLatitude && getLongitude) urlGetParams += `?latitude=${getLatitude}&longitude=${getLongitude}`;
  else if (getKeyword) urlGetParams += `?keyword=${getKeyword}`;
  urlGetParams += `&checkInDate=${getCheckInDate}`;
  urlGetParams += `&checkOutDate=${getCheckOutDate}`;
  urlGetParams += `&adults=${getAdults}`;
  urlGetParams += `&rooms=${getRooms}`;
  // urlGetParams += `&filterKey=${getFilterKey}`;

  const HotelSortingOptions = [
    { label: 'Price', fullLabel: 'Lowest Price First', value: 'LowestPrice' },
    { label: 'Price', fullLabel: 'Highest Price First', value: 'HighestPrice' },
    { label: 'Rating', fullLabel: 'Lowest Star-rating First', value: 'LowestRating' },
    { label: 'Rating', fullLabel: 'Highest Star-rating First', value: 'HighestRating' },
    { label: 'Location', fullLabel: 'Nearest Location First', value: 'NearestLocation' },
    { label: 'Location', fullLabel: 'Farthest Location First', value: 'FarthestLocation' },
  ];

  const [hotelSortOptions, setHotelSortOptions] = useState(HotelSortingOptions);

  useEffect(() => {
    if (getKeyword) {
      //! If the keyword is present, remove nearest and farthest location options
      setHotelSortOptions(HotelSortingOptions.filter((option) => option.value !== 'NearestLocation' && option.value !== 'FarthestLocation'));
    } else if (getLatitude && getLongitude) {
      //! If both latitude and longitude are present, show all options (including location options)
      setHotelSortOptions(HotelSortingOptions);
    }
    if (getSortedBy) {
      const selectedOption = HotelSortingOptions.find((option) => option.value === getSortedBy);
      if (selectedOption) {
        setHotelSort(selectedOption.fullLabel);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getKeyword, getLatitude, getLongitude, getSortedBy]);

  React.useEffect(() => {
    const searchData = {
      checkInDate: getCheckInDate || '',
      checkOutDate: getCheckOutDate || '',
      adults: getAdults || '',
      rooms: getRooms || '',
      latitude: getLatitude || '',
      longitude: getLongitude || '',
      keyword: getKeyword || '',
      filterKey: getFilterKey || '',
      priceRange: getSelectedPriceRange || '',
      hotelStars: getHotelStars || '',
      hotelFacilities: getHotelFacilities || '',
      bedPreferences: getBedPreferences || '',
      mealInclusions: getMealInclusions || '',
      roomFacilities: getRoomFacilities || '',
      sortedBy: getSortedBy || '',
    };

    setInputData(searchData);
  }, [
    getCheckInDate,
    getCheckOutDate,
    getAdults,
    getRooms,
    getLatitude,
    getLongitude,
    getKeyword,
    getFilterKey,
    getSelectedPriceRange,
    getHotelStars,
    getHotelFacilities,
    getBedPreferences,
    getMealInclusions,
    getRoomFacilities,
    getSortedBy,
  ]);

  const location = getLongitude && getLatitude ? 'Near me' : getKeyword || `${getLongitude} ${getLatitude}`;
  // console.log(hotelSort);

  let searchUrl: string = hotelSearch();
  searchUrl += `?checkInDate=${getCheckInDate}&checkOutDate=${getCheckOutDate}&adults=${getAdults}&rooms=${getRooms} `;

  if (getKeyword) {
    searchUrl += `&keyword=${getKeyword}`;
  } else if (getLongitude && getLatitude) {
    searchUrl += `&longitude=${getLongitude}&latitude=${getLatitude}`;
  }
  if (getFilterKey) {
    searchUrl += `&filterKey=${getFilterKey}`;
  }
  if (getSelectedPriceRange) {
    searchUrl += `&priceRange=${getSelectedPriceRange}`;
  }

  if (getHotelStars) {
    searchUrl += `&hotelStars=${getHotelStars}`;
  }

  if (getHotelFacilities) {
    searchUrl += `&hotelFacilities=${getHotelFacilities}`;
  }

  if (getBedPreferences) {
    searchUrl += `&bedPreferences=${getBedPreferences}`;
  }

  if (getMealInclusions) {
    searchUrl += `&mealInclusions=${getMealInclusions}`;
  }

  if (getRoomFacilities) {
    searchUrl += `&roomFacilities=${getRoomFacilities}`;
  }

  if (getSortedBy) {
    searchUrl += `&sortedBy=${getSortedBy}`;
  }

  const hotelSearchResults = useQuery({
    queryKey: ['hotelSearchResults', searchUrl],
    queryFn: async () => (await axios.get(searchUrl)).data,
    enabled: enableQuery,
    cacheTime: 0,
  });

  const { data, isFetching, isSuccess, isError, isFetched } = hotelSearchResults;
  const filterKey = data?.filterKey;

  const filterURL = (): string => {
    let filterUrlParams = '';
    if (getFilterKey) filterUrlParams += `&filterKey=${getFilterKey}`;
    else filterUrlParams += `&filterKey=${filterKey}`;
    if (searchParams.get('priceRange')) filterUrlParams += `&priceRange=${searchParams.get('priceRange')}`;
    if (searchParams.get('hotelStars')) filterUrlParams += `&hotelStars=${searchParams.get('hotelStars')}`;
    if (searchParams.get('hotelFacilities')) filterUrlParams += `&hotelFacilities=${searchParams.get('hotelFacilities')}`;
    if (searchParams.get('bedPreferences')) filterUrlParams += `&bedPreferences=${searchParams.get('bedPreferences')}`;
    return filterUrlParams;
  };

  //Show AnimatedGalagoLoading whenever there is a change in the searchURL
  useEffect(() => {
    setEnableQuery(true);
    setPriceRange({ start: 0, end: 0 });
    // console.log('changed');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchUrl]);

  if (isFetching)
    return (
      <div className="w-[1126px] relative top-[6rem]  flex flex-col items-center justify-start gap-[1.5rem] text-left text-[1.38rem] text-black font-ticket-text-sizes-ticket-text-medium-medium mb-40">
        <AnimatedGalagoLoading loadingText="Finding the best stays for you ..." show={true} />
        <HotelSearchResultsSkeletonCard frameDivBorderRadius="12px" frameDivBorder="1px solid #d9d9d9" frameDivBoxShadow="unset" cards={10} />
      </div>
    );

  if (isSuccess && isFetched) {
    if (enableQuery) {
      setEnableQuery(false);
      setFilterKey(data.filterKey);
      const priceRangeUrl = data.filters.priceRange;
      const priceRange: { min: number; max: number } = priceRangeUrl;
      setPriceRange({ start: priceRange.min, end: priceRange.max });
      setSelectedPriceRange(
        selectedPriceRange.start && selectedPriceRange.end !== 0 ? { start: selectedPriceRange.start, end: selectedPriceRange.end } : { start: priceRange.min, end: priceRange.max }
      );

      const hotelFacilitiesList = data.filters.hotelFacilities;
      setFacilitiesList(hotelFacilitiesList);

      const bedPreferencesList = data.filters.bedPreferences;
      setBedPreferencesList(bedPreferencesList);
    }
    const checkOut = moment(getCheckOutDate);
    const checkIn = moment(getCheckInDate);

    let dates = '';
    if (checkOut.format('MMMM') === checkIn.format('MMMM')) dates = `${checkIn.format('MMMM D')} - ${checkOut.format('D')}`;
    else dates = `${checkIn.format('MMMM D')} - ${checkOut.format('MMMM D')}`;

    let dates2 = '';
    if (checkOut.format('MMMM') === checkIn.format('MMMM')) dates2 = `${checkIn.format('MMMM D')} - ${checkOut.format('D, YYYY')}`;
    else dates2 = `${checkIn.format('MMMM D')} - ${checkOut.format('MMMM D')}, ${checkOut.format('YYYY')}`;

    const nights = checkOut.diff(checkIn, 'days');

    const hotelList = data.data?.tbo;

    const roomCount = getRooms;

    // eslint-disable-next-line array-callback-return
    const hotels: ReactElement[] = hotelList?.map((data: { [index: string]: any }, index: React.Key) => {
      if (data.Rooms.length >= (roomCount ?? 0)) {
        const rooms = [...new Set([...data.Rooms])];
        const cheapestRoom = _.min(rooms.map((room: { [index: string]: any }) => room));
        const cheapestRoomPrice = _.min<number>(rooms.map((room: any) => parseFloat(room.TotalFare))) ?? 0;
        const inclusions = rooms.find((room) => room.TotalFare === cheapestRoomPrice).Inclusion;

        return (
          <HotelCard
            key={index}
            bookingDetails={`${nights} ${nights === 1 ? 'night' : 'nights'}, ${dates}`}
            cityName={data.CityName}
            distance={getLongitude && getLatitude ? `| ${data.Distance} km away from you` : undefined}
            bookingDetails2={`${dates2}, ${data.CityName}`}
            propertyOrDescription={data.HotelName}
            minRoomPrice={cheapestRoomPrice}
            cheapestRoom={cheapestRoom || {}}
            inclusions={inclusions}
            hotelRating={data.HotelRating}
            nights={nights}
            hotel={data}
            frameDivBorderRadius="12px"
            frameDivBorder="1px solid #d9d9d9"
            frameDivBoxShadow="unset"
            searchCriteria={inputData}
            starRatingCount={data.StarRatingCount}
          />
        );
      }
    });

    // sort the hotels from cheapest room to highest
    // if (getKeyword) {
    //   hotels.sort((a: any, b: any) => {
    //     return a.props.minRoomPrice - b.props.minRoomPrice;
    //   });
    // }

    //! GALLY's Top PICK SPECIAL
    // if (hotels.length) {
    //   // const minRoomPrice = hotels[0].props.minRoomPrice;
    //   // console.log(hotels[0].props.minRoomPrice, hotels[0].props.hotel.Rooms[0].TotalFareWithMarkUp.toString());
    //   const cheapestRoom = hotels[0].props.hotel.Rooms[0];
    //   const minRoomPrice = hotels[0].props.hotel.Rooms[0].TotalFare;
    //   hotels[0] = (
    //     <HotelCard
    //       key={hotels[0].key}
    //       cheapestRoom={cheapestRoom}
    //       imageAltText={hotels[0].props.imageAltText}
    //       bookingDetails={hotels[0].props.bookingDetails} 
    //       // bookingDetails2={hotels[0].props.bookingDetails2}
    //       cityName={hotels[0].props.cityName}
    //       distance={hotels[0].props.distance}
    //       propertyOrDescription={hotels[0].props.propertyOrDescription}
    //       minRoomPrice={minRoomPrice}
    //       inclusions={hotels[0].props.inclusions}
    //       hotelRating={hotels[0].props.hotelRating}
    //       nights={hotels[0].props.nights}
    //       hotel={hotels[0].props.hotel}
    //       frameDivBorderRadius="0px 12px 12px 12px"
    //       frameDivBorder={'2px solid #006FA9'}
    //       frameDivBoxShadow={hotels[0].props.frameDivBoxShadow}
    //       searchCriteria={inputData}
    //       starRatingCount={hotels[0].props.hotel.starRatingCount}
    //     />
    //   );
    // }
    const propertyLength: number = data.data?.tbo.length;

    return (
      <div className="relative w-[80.625rem] top-[1rem] flex flex-col items-center justify-start bg gap-[1.5rem] text-left text-[1.38rem] text-black font-satoshi">
        <div className="self-stretch bg-[#f9f9f9] position-sticky top-0 pt-[3.5rem] mt-[-3rem] pb-4 flex flex-row items-center justify-between text-center text-[1rem] text-text-colors-text-primary font-footnote">
          <div className="flex flex-row items-center justify-center ">
            <div className="">
              <div className="text-base font-medium flex align-items-center gap-1" style={{ fontFamily: 'Satoshi' }}>
                <span style={{ fontSize: 22, fontWeight: 600, color: '#006FA9' }}>{propertyLength}</span>
                <p>properties found in</p>
                <p className="flex align-items-center" style={{ fontSize: 22, fontWeight: 600, color: '#006FA9' }}>
                  {' '}
                  <TiLocationOutline /> {location}
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center justify-center gap-[0.5rem]">
            <div className="font-medium relative">
              <p className="flex items-center gap-2 -z-5" style={{ fontFamily: 'General Sans' }} >
                Sort by:{' '}
                <span className="text-primary-primary-100 flex items-center gap-2 cursor-pointer relative" onClick={() => setShowFilters(!showFilters)}>
                  {!getSortedBy && getKeyword ? 'Lowest Price First' : !getSortedBy && getLatitude && getLongitude ? 'Nearest Location First' : hotelSort}
                  <ChevronDownV2 _color="#006FA9" style={{ transform: showFilters ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all 0.2s ease-in-out' }} />
                </span>
              </p>

              {showFilters && (
                <div
                  onMouseLeave={() => setShowFilters(false)}
                  id="filters-holder"
                  className="position-absolute bg-body d-flex flex-column align-items-start justify-content-center"
                  style={{
                    left: 0,
                    right: 0,
                    marginRight: 'auto',
                    marginTop: '10px',
                    width: '100%',
                    paddingTop: '0',
                    maxHeight: 'none',
                  }}
                >
                  {hotelSortOptions.map((option, index) => (
                    <div
                      key={index}
                      className="filters-opt"
                      onClick={() => {
                        setShowFilters(false);
                        const filters = filterURL();
                        setSelectedFilter(option.value);
                        navigate(urlGetParams + `&sortedBy=${option.value}${filters}`);
                      }}
                    >
                      <p className="text-sm text-left">{option.fullLabel}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col items-start justify-start text-[1.06rem] text-white-white-100 font-footnote w-100">
          {/* {propertyLength > 0 && (
            <div className="rounded-t-xl rounded-b-none bg-color-teal-teal-900 flex flex-row py-2.5 px-3.5 items-center align-middle justify-start gap-[0.63rem] ">
              <div className="border-2 rounded-full border-[#2DBFD6] bg-white w-[22px] h-[22px] flex items-center justify-center">
                <GallyLookLeft />
              </div>
              <div className="font-medium">Gally’s Top Pick</div>
            </div>
          )} */}

          {propertyLength === 0 ? (
            <ErrorGalagoPage
              title="Uh-oh! No Results found"
              content="Unfortunately, we couldnt find any hotels that fit your preferences. Try different dates, filters or destinations to access more exciting deals!"
              show
            />
          ) : (
            <div className="flex flex-col items-start justify-start gap-[1rem] text-[1.25rem] text-black w-100">{hotels}</div>
          )}
        </div>
      </div>
    );
  }

  if (isError) {
    if (enableQuery) setEnableQuery(false);
    return (
      <div className="w-full relative flex flex-col align-middle items-center justify-start gap-[1.5rem] text-left text-[1.38rem] text-black font-ticket-text-sizes-ticket-text-medium-medium">
        <div className="flex flex-col items-center justify-center gap-10">
          {/* KINDLY INCLUDE HERE A CONDITIONAL STATEMENT ABSED ON ERROR CODE */}
          <SomethingWentWrong />
          <div className="flex flex-row gap-3">
            <GalagoButton
              label={'Retry'}
              size="xl"
              btn="light"
              onClick={() => {
                window.location.reload();
              }}
              className="generalsans-regular text-lg text-nowrap w-[200px]"
            />
            <GalagoButton
              label={'Contact Us'}
              size="xl"
              btn="primary"
              onClick={() => {
                window.location.href = 'mailto:customersupport@GalaGo.com.ph';
              }}
              className="btn-primary generalsans-regular text-lg drop-shadow-md text-nowrap w-[200px]"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative top-[10rem] flex flex-col items-center justify-start gap-[1.5rem] text-left text-[1.38rem] text-black font-ticket-text-sizes-ticket-text-medium-medium mb-40">
      <div>LOADING...</div>
    </div>
  );
};

export default HotelSearchResults;
