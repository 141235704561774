import { SvgProps } from './SvgProps';

export const SwitchArrowSvg = ({ _width = 12, _height = 13, _color = '#4FA2B4' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="Vector" d="M9.3 1L13.5 5.2L9.3 9.4M12.8576 5.2H1.5M5.7 16.6L1.5 12.4L5.7 8.2M2.175 12.4H13.5" stroke={_color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const SwitchArrowHorizontal = ({ _width = 12, _height = 13, _color = '#4FA2B4' }: SvgProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.99 3.50001L20.01 8.51001" stroke="#006FA9" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.99 20.5L14.99 3.5" stroke="#006FA9" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9.01 20.5L3.99 15.49" stroke="#006FA9" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9.01 3.5L9.01 20.5" stroke="#006FA9" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
