import { InfantSvg } from '../resources/svg/InfantSvg';
import { ChildSvg } from '../resources/svg/ChildSvg';
import { UserSvg } from '../resources/svg/UserSvg';
import { COLOR } from '../custom-theme';
import { PassengersStore } from '../store/PassengersStore';
import { AdultSvg } from '../resources/svg/AdultSvg';
import { CircleGallySvg } from '../resources/svg/GallySvg';

interface PassengerDetailsProps extends React.HTMLAttributes<HTMLDivElement> {
  parentDivProps?: any;
}

export const Passengers = ({ ...parentDivProps }: PassengerDetailsProps) => {
  const { passengers, setPassengers } = PassengersStore();
  const maxPassengers = 9;
  const totalPassengers = passengers.adults + passengers.minors + passengers.children + passengers.infants;

  return (
    <div
      {...parentDivProps}
      id="passenger-details-holder"
      className="position-absolute bg-body z-10 d-flex flex-column align-items-start justify-content-center"
      style={{
        // left: 0,
        // right: 0,
        marginRight: 'auto',
        marginTop: '10px',
        minWidth: '330px',
        maxWidth: '400px',
        zIndex: 10,
        // width: '100%',
      }}
    >
      <div className="d-flex justify-content-between align-items-center align-self-stretch" style={{ padding: '8px 0px' }}>
        <div className="gap-2 d-flex justify-content-center align-items-center">
          <div>
            <AdultSvg />
          </div>
          <div className="passenger-count-label">
            <span className="w-100 d-inline-block">Adults</span>
            <span className="w-100 d-inline-block">Ages 18 years old and above</span>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '12px' }}>
          <button
            className="passenger-count-btn adult-control"
            disabled={passengers.adults === 1 ? true : false}
            onClick={() => {
              const count = passengers.adults - 1 < 1 ? 1 : passengers.adults - 1;
              setPassengers({ ...{ adults: count } });
            }}
            style={{
              borderColor: passengers.adults === 1 ? 'transparent' : COLOR.primary,
            }}
          >
            -
          </button>
          <span style={{ width: '10px', color: '#333', fontFamily: 'General-sans, sans-serif', fontSize: '16px', textAlign: 'center' }}>{passengers.adults}</span>
          <button
            className="passenger-count-btn adult-control"
            disabled={passengers.adults === maxPassengers || totalPassengers === maxPassengers ? true : false}
            onClick={() => {
              const count = passengers.adults + 1 > maxPassengers ? 9 : passengers.adults + 1;
              setPassengers({ ...{ adults: count } });
            }}
            style={{
              color: totalPassengers === maxPassengers ? '#333' : COLOR.primary,
              borderColor: totalPassengers === maxPassengers ? 'transparent' : COLOR.primary,
            }}
          >
            +
          </button>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center align-self-stretch" style={{ padding: '8px 0px' }}>
        <div className="gap-2 d-flex justify-content-center align-items-center">
          <div>
            <UserSvg />
          </div>
          <div className="passenger-count-label">
            <span className="w-100 d-inline-block">Minor</span>
            <span className="w-100 d-inline-block">Ages 12 to 17 years old</span>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '12px' }}>
          <button
            className="passenger-count-btn adult-control"
            disabled={passengers.minors === 0 ? true : false}
            onClick={() => {
              setPassengers({ ...{ minors: passengers.minors - 1 } });
            }}
            style={{
              borderColor: passengers.minors === 0 ? 'transparent' : COLOR.primary,
            }}
          >
            -
          </button>
          <span style={{ width: '10px', color: '#333', fontFamily: 'General-sans, sans-serif', fontSize: '16px', textAlign: 'center' }}>{passengers.minors}</span>
          <button
            className="passenger-count-btn adult-control"
            disabled={passengers.minors === 9 || totalPassengers === maxPassengers ? true : false}
            onClick={() => {
              const count = passengers.minors + 1 > maxPassengers ? 9 : passengers.minors + 1;
              setPassengers({ ...{ minors: count } });
            }}
            style={{
              color: totalPassengers === maxPassengers ? '#333' : COLOR.primary,
              borderColor: totalPassengers === maxPassengers ? 'transparent' : COLOR.primary,
            }}
          >
            +
          </button>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center align-self-stretch" style={{ padding: '8px 0px' }}>
        <div className="gap-2 d-flex justify-content-center align-items-center">
          <div>
            <ChildSvg />
          </div>
          <div className="passenger-count-label">
            <span className="w-100 d-inline-block">Children</span>
            <span className="w-100 d-inline-block">Ages 2 to 11 years old</span>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '12px' }}>
          <button
            className="passenger-count-btn"
            disabled={passengers.children === 0 ? true : false}
            onClick={() => {
              setPassengers({ ...{ children: passengers.children - 1 } });
            }}
            style={{
              borderColor: passengers.children === 0 ? 'transparent' : COLOR.primary,
            }}
          >
            -
          </button>
          <span style={{ width: '10px', color: '#333', fontFamily: 'General-sans, sans-serif', fontSize: '16px', textAlign: 'center' }}>{passengers.children}</span>
          <button
            className="passenger-count-btn"
            disabled={passengers.children === 9 || totalPassengers === maxPassengers ? true : false}
            onClick={() => {
              const count = passengers.children + 1 > maxPassengers ? 9 : passengers.children + 1;
              setPassengers({ ...{ children: count } });
            }}
            style={{
              color: totalPassengers === maxPassengers ? '#333' : COLOR.primary,
              borderColor: totalPassengers === maxPassengers ? 'transparent' : COLOR.primary,
            }}
          >
            +
          </button>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center align-self-stretch" style={{ padding: '8px 0px' }}>
        <div className="gap-2 d-flex justify-content-center align-items-center">
          <div>
            <InfantSvg />
          </div>
          <div className="passenger-count-label">
            <span className="w-100 d-inline-block">Infants</span>
            <span className="w-100 d-inline-block">Under 1 year old</span>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '12px' }}>
          <button
            className="passenger-count-btn"
            disabled={passengers.infants === 0 ? true : false}
            onClick={() => {
              setPassengers({ ...{ infants: passengers.infants - 1 } });
            }}
            style={{
              borderColor: passengers.infants === 0 ? 'transparent' : COLOR.primary,
            }}
          >
            -
          </button>
          <span style={{ width: '10px', color: '#333', fontFamily: 'General-sans, sans-serif', fontSize: '16px', textAlign: 'center' }}>{passengers.infants}</span>
          <button
            className="passenger-count-btn"
            disabled={passengers.infants === 9 || totalPassengers === maxPassengers ? true : false}
            onClick={() => {
              const count = passengers.infants + 1 > maxPassengers ? 9 : passengers.infants + 1;
              setPassengers({ ...{ infants: count } });
            }}
            style={{
              color: totalPassengers === maxPassengers ? '#333' : COLOR.primary,
              borderColor: totalPassengers === maxPassengers ? 'transparent' : COLOR.primary,
            }}
          >
            +
          </button>
        </div>
      </div>

      <div id="notice" className="w-100 py-[10px] px-[14px] flex gap-3 items-center rounded-lg" style={{ background: '#EDF6F8' }}>
        <CircleGallySvg _width={60} _height={60} />
        <div style={{ color: '#333' }}>Remember that a minor is always accompanied by an adult</div>
      </div>
    </div>
  );
};
