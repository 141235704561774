import NavBarTransparent from '../../../components/NavBarTransparent';
import { ChevronRightSvg } from '../../../resources/svg/ChevronRightSvg';
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import { LinkedInSvg } from '../../../resources/svg/LinkedinSvg';
import { TwitterSvg } from '../../../resources/svg/TwitterSvg';
import { LinkSvg } from '../../../resources/svg/LinkSvg';
import React from 'react';
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';

function IslandHoppingEssentials() {
  const [fbHover, setFbHover] = React.useState('#ADADAD');
  const [liHover, setLiHover] = React.useState('#ADADAD');
  const [twtHover, setTwtHover] = React.useState('#ADADAD');
  const [linkHover, setLinkHover] = React.useState('#ADADAD');

  return (
    <>
      <NavBarTransparent />

      <div className="hero_sec">
        <img
          src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Island+Hopping+Essentials/00+Cover.jpeg"
          alt="What's in Our Beach Bags: Island Hopping Essentials"
          loading="lazy"
          onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
        />
        <div className="gradient_overlay">
          <h1>Travel Tips</h1>

          <div className="bread_crumbs">
            <a href="/#/blogs">Blogs</a>
            <ChevronRightSvg _color="#ffffff" _width={20} _height={20} />
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
              What's in Our Beach Bags: Island Hopping Essentials
            </a>
          </div>
        </div>
      </div>

      <div className="articleBody">
        <div className="articleHeader">
          <h1>What's in Our Beach Bags: Island Hopping Essentials?</h1>

          <div className="info">
            <p className="authorSec">
              By <u>Anthea Reyes</u>
            </p>
            <p className="separator">•</p>
            <p className="articleDate">Sep 25, 2023</p>
            <p className="separator">•</p>
            <p className="article-read">4 min read</p>
          </div>

          <div className="shareBtnContainer">
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setFbHover('#006FA9')} onMouseLeave={() => setFbHover('#ADADAD')}>
              <FacebookSvg _color={fbHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLiHover('#006FA9')} onMouseLeave={() => setLiHover('#ADADAD')}>
              <LinkedInSvg _color={liHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setTwtHover('#006FA9')} onMouseLeave={() => setTwtHover('#ADADAD')}>
              <TwitterSvg _color={twtHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLinkHover('#006FA9')} onMouseLeave={() => setLinkHover('#ADADAD')}>
              <LinkSvg _color={linkHover} _width={24} _height={24} />
            </a>
          </div>
        </div>

        <div className="content">
          <div className="articleDescription">
            <p>
              The beauty of the Philippines is that it’s an archipelago. With over 7,000 islands, this Southeast Asian treasure has an endless offering of stunning landscapes, pristine waters, and
              vibrant cultures.
            </p>
            <p>
              From the world-renowned Palawan to more discreet jewels like Romblon, Camiguin, and more, a visit to this country promises unforgettable moments and breathtaking sights. That’s why, for
              any foreign or local traveler seeking a truly memorable adventure here, island-hopping is a must-do experience!
            </p>
            <p>
              Itching to go on a vacation to this tropical paradise already? Can you almost smell the salt in the air, feel the fine white sand between your toes, and hear the gentle crashing of the
              waves? Hold your horses!
            </p>
            <p>To make the most of your journey to this enchanting country, it's essential to pack wisely and bring along the right essentials.</p>
            <p>Let’s quickly run through the list of items every tourist needs to bring while exploring the different islands of the Philippines.</p>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Island+Hopping+Essentials/01+Timur+Weber+via+Pexels.png"
              alt="Travel Essentials"
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by Timur Weber via Pexels</p>
          </div>

          <div className="extensive_story">
            <h2>What to bring when island hopping around the Philippines</h2>

            <div className="tip_container">
              <div className="tip">
                <h3>Rash Guard</h3>
                <p>
                  We all want to look cute in a darling bikini or swimming trunks while on vacation. You know what isn’t cute, though? Sunburn. If you’re going island hopping, odds are you’ll spend
                  most of your time swimming, walking, or sailing under the sun.
                </p>
                <p>
                  Another consideration: Jellyfish. The Philippine islands are rich with marine life, one of them being a very lively collection of jellyfishes. From the deep blue sea to the shallow
                  waters at shore, they’re there and they sting.
                </p>
                <p>A rash guard will protect you from both of these (the sun and the jellyfish).</p>
              </div>

              <div className="tip">
                <h3>Water Shoes or Sandals</h3>
                <p>
                  Exploring the Philippine islands means, sometimes, walking along rocky shores. This is especially true for Palawan, where the white sand beaches are surrounded by beautiful but sharp
                  limestone karsts and old coral reefs.
                </p>
                <p>
                  Wearing a trusty pair of aqua shoes or water shoes will help protect your feet from these sharp surfaces, as well as from the occasional pinchy crab or needly sea urchin. They can
                  also give you the necessary grip while walking on slippery surfaces, like moss-covered boulders!
                </p>
              </div>

              <div className="tip">
                <h3>Sunscreen</h3>
                <p>
                  Again, island hopping means being under the mercy of the sun. While wearing a rash guard, a cute wide-brimmed beach hat, and shades can be of big help, they’re not enough on their
                  own to protect your skin from lasting damage.
                </p>
                <p>
                  Putting on sunscreen with a high SPF rating is your best protection against the harsh rays of the sun. It would also be nice to opt for eco-friendly products to minimize
                  environmental impact while exploring the country’s pristine islands.
                </p>
              </div>

              <div className="tip">
                <h3>Bug Repellent</h3>
                <p>A tropical paradise will have tropical insects (they’re part of the beauty of nature). Protect your skin from potential insect bites by bringing bug repellent.</p>
                <p>Again, be mindful and use eco-friendly products. We want to respect and preserve the natural wonders we’re visiting, even while we try to protect ourselves.</p>
              </div>

              <div className="tip">
                <h3>Bug Repellent</h3>
                <p>A tropical paradise will have tropical insects (they’re part of the beauty of nature). Protect your skin from potential insect bites by bringing bug repellent.</p>
                <p>Again, be mindful and use eco-friendly products. We want to respect and preserve the natural wonders we’re visiting, even while we try to protect ourselves.</p>
              </div>

              <div className="tip">
                <h3>Waterproof Backpack</h3>
                <p>
                  A waterproof backpack is a lifesaver as you hop from one destination to another. It will keep your belongings safe from water splashes, rain, or accidental drops in the sea. You can
                  also confidently carry your camera, wallet, and other essentials without worrying about water damage.
                </p>
              </div>

              <div className="tip">
                <h3>Waterproof Phone Pouch</h3>
                <p>
                  You will most likely want to keep your phone in your hand as you go on your adventures. A waterproof pouch is a great way to keep your smartphones protected, accessible, and
                  available to snap a photo of that one breathtaking view ASAP.
                </p>
              </div>

              <div className="tip">
                <h3>Snorkeling Gear</h3>
                <p>
                  The Philippines boasts an incredible marine ecosystem, so bring your own snorkeling gear if possible. While some tour operators provide gear for rent, having your own ensures a
                  better fit and a more hygienic experience.
                </p>
              </div>

              <div className="tip">
                <h3>Travel Towel</h3>
                <p>Pack a compact and quick-drying travel towel for beach visits and unexpected swims. It will come in handy when public restrooms lack hand dryers or towels.</p>
              </div>
            </div>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Island+Hopping+Essentials/02+Mat+Napo+via+Unsplash.png"
              alt="First Aid Kit"
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by Mat Napo via Unsplash</p>
          </div>

          <div className="extensive_story">
            <h2>Prescription Medications and First Aid Kit</h2>
            <p>
              If you have any prescription medications, make sure to bring an adequate supply along with a copy of your prescription. Additionally, pack a basic first aid kit with adhesive bandages,
              antiseptic wipes, pain relievers, and motion sickness medication. Most hotels have first aid kids on hand, but it never hurts to be extra prepared!
            </p>

            <div className="tip_container">
              <div className="tip">
                <h3>Cash</h3>
                <p>
                  While credit and debit cards are widely accepted in major cities, cash is essential for small businesses and remote islands. Bring enough local currency (Philippine Pesos) and keep
                  it in a secure and waterproof wallet.
                </p>
              </div>

              <div className="tip">
                <h3>Powerbank</h3>
                <p>Island-hopping can take hours. Bring a power bank to keep your gadgets charged.</p>
              </div>

              <div className="tip">
                <h3>Reusable Water Bottle</h3>
                <p>
                  With the heat you’ll experience, it’s important to stay hydrated. Reduce plastic waste by carrying a reusable water bottle. Many islands have refill stations where you can top up
                  your bottle with filtered water.
                </p>
              </div>

              <div className="tip">
                <h3>Trash Bag</h3>
                <p>Carry a small trash bag with you during island hopping trips, and properly dispose of your waste in designated areas. Leave the beaches as pristine as you found them.</p>
              </div>

              <div className="tip">
                <h3>Respectful Attitude and Cultural Awareness</h3>
                <p>As a visitor, it's crucial to respect the local culture and customs. Be mindful of the local traditions and practices in the destinations you’re visiting.</p>
              </div>
            </div>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Island+Hopping+Essentials/03+Adam+Navarro.png"
              alt="Adventure"
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by Adam Navarro via Unsplash</p>
          </div>

          <div className="footnote">
            <div className="title_container">
              <h2>Get ready for an island hopping adventure with GalaGO!</h2>
            </div>

            <div className="info">
              <p>Remember that the key to a successful island-hopping adventure in the Philippines is to travel light and stay prepared.</p>
              <p>Embrace the laid-back island vibe, be open to new experiences, and immerse yourself in the wonders of this breathtaking archipelago.</p>
              <p>
                All packed and ready to go? Book your flight and accommodations now with GalaGO! for a smooth and stress-free experience. We’ll also keep you stocked up on travel tips and sightseeing
                destinations for your next trip!
              </p>
            </div>
          </div>
        </div>

        <hr />

        <div className="featured_blogs">
          <h2>Featured Blogs</h2>
          <div className="card_container">
            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg"
                  alt="Discover the Best Things to Do in Bohol All in One Tour!"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Discover the Best Things to Do in Bohol All in One Tour!</h3>

                <a href="/#/discover-the-best-things-to-do-in-bohol-all-in-one-tour" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#006FA9" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>

            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg"
                  alt="Guide to Travel Requirements in the Philippines"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3> Guide to Travel Requirements in the Philippines</h3>

                <a href="/#/guide-to-travel-requirements-in-the-philippines" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#006FA9" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default IslandHoppingEssentials;
