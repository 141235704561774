import NavBarTransparent from '../../../components/NavBarTransparent';
import React from 'react';
import { ChevronRightSvg } from '../../../resources/svg/ChevronRightSvg';
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import { LinkedInSvg } from '../../../resources/svg/LinkedinSvg';
import { TwitterSvg } from '../../../resources/svg/TwitterSvg';
import { LinkSvg } from '../../../resources/svg/LinkSvg';
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';



const CebuHotels = () =>{
 const [fbHover, setFbHover] = React.useState('#ADADAD');
 const [liHover, setLiHover] = React.useState('#ADADAD');
 const [twtHover, setTwtHover] = React.useState('#ADADAD');
 const [linkHover, setLinkHover] = React.useState('#ADADAD');

    return (
      <>
        <NavBarTransparent />
        <div className="hero_sec">
          <img
            src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Cebu+Hotels/Blog_Header.jpg"
            alt=""
            loading="lazy"
            onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
          />
          <div className="gradient_overlay">
            <h1>Travel Destination</h1>

            <div className="bread_crumbs">
              <a href="/#/blogs">Blogs</a>
              <ChevronRightSvg _color="#ffffff" _width={20} _height={20} />
              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
                Top Hotels to Stay at in Cebu
              </a>
            </div>
          </div>
        </div>

        <div className="articleBody">
          <div className="articleHeader">
            <h1>Top Hotels to Stay at in Cebu</h1>

            <div className="info">
              <p className="authorSec">
                {' '}
                By <u>Anthea Reyes</u>
              </p>
              <p className="separator">•</p>
              <p className="articleDate">Nov 11, 2023</p>
              <p className="separator">•</p>
              <p className="article-read">6.5 min read</p>
            </div>

            <div className="shareBtnContainer">
              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setFbHover('#006FA9')} onMouseLeave={() => setFbHover('#ADADAD')}>
                <FacebookSvg _color={fbHover} _width={24} _height={24} />
              </a>

              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLiHover('#006FA9')} onMouseLeave={() => setLiHover('#ADADAD')}>
                <LinkedInSvg _color={liHover} _width={24} _height={24} />
              </a>

              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setTwtHover('#006FA9')} onMouseLeave={() => setTwtHover('#ADADAD')}>
                <TwitterSvg _color={twtHover} _width={24} _height={24} />
              </a>

              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLinkHover('#006FA9')} onMouseLeave={() => setLinkHover('#ADADAD')}>
                <LinkSvg _color={linkHover} _width={24} _height={24} />
              </a>
            </div>
          </div>

          <div className="content">
            <div className="articleDescription">
              <p>
                When it comes to exploring the vibrant and captivating city of Cebu, booking the perfect hotel is essential. Finding one that’s strategically placed and adequately equipped can be the
                key to a seamless and truly enjoyable stay. Thankfully, the hotel scene in this province offers a diverse range of accommodations. They have everything from modern luxury to beachside
                tranquility. Whether you're visiting to adventure or to unwind, you’re sure to find a stay that will fit your preference.
              </p>

              <p>Let's take a closer look at some of the top hotels in the area. Let us help you make the right choice when deciding where to stay in Cebu. </p>
            </div>

            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Cebu+Hotels/01+Burak+Ceviz.png"
                alt=""
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />
              <p>Photo by Burak Ceviz via Unsplash</p>
            </div>

            <div className="extensive_story">
              <h2>Best time to go to Cebu</h2>

              <div className="info_grp">
                <p>
                  Before we jump into bookings and reservations, we have one reminder for you: Timing is crucial when going on any sort of travel. As you search for the perfect stay on your
                  Cebu-cation, it’s important to know when to make your trip.
                </p>

                <p>
                  If you’re going to this province, the best time to visit is during the dry season. That’s any time between December and April. Scheduling your trip within these months increases the
                  likelihood of sunny days for your beach adventures, diving, and outdoor activities.
                </p>
                <p>
                  Going in January is also ideal if you’re looking to participate in the Sinulog Festival, which is a cultural gem of the province. There will be plenty of delicious food, lively dance
                  numbers, and more fun activities, but all that comes with higher tourist numbers. If you prefer a quieter experience, consider the shoulder months of November and May.{' '}
                </p>
                <p>These are just suggestions, however. Cebu's tropical climate makes it a fantastic destination for any season.</p>
              </div>
            </div>

            <div className="extensive_story">
              <h2>Best hotels in Cebu</h2>
              <div className="info_grp">
                <p>
                  Now that you know when to embark on your journey to Cebu, it’s time to dive into our curated list of this destination’s best hotels and resorts. Guarantee a remarkable experience
                  throughout your stay and appreciate the elegance of Cebu to the fullest with these recommended accommodations:
                </p>
              </div>
            </div>

            <div className="hotel_container">
              <div className="hotel_details">
                <div className="contacts">
                  <h2>Harolds Evotel</h2>
                  <p>Located at 146 Gorordo Avenue corner C. Rosal Street, Cebu City, 6000</p>
                  <p>Can be reached via mobile phone at +63 917 711 8828</p>
                </div>

                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Cebu+Hotels/02+Photo+by+Harolds+Evotel+Cebu+via+Facebook.png"
                    alt=""
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by Harolds Evotel Cebu via Facebook</p>
                </div>

                <div className="info_grp">
                  <p>
                    Nestled in the heart of Cebu City, Harolds Evotel is a true gem for those seeking simple comfort and convenience. This upscale hotel offers spacious and contemporary rooms with all
                    the modern amenities you could desire. They have a fitness center, a minibar in every room, and three dining options offering a range of international dishes.
                  </p>
                  <p>Their impeccable service and attention to detail also creates a welcoming environment, making them a popular choice for both business and leisure travelers. </p>
                  <p>
                    Harolds Evotel’s best feature, however, is their prime location. An easy 10-minute drive from Cebu Business Park and Ayala Centre Cebu, staying here gives you easy access to the
                    city's popular attractions and business districts. It’s just a 30-minute drive to Magellan’s cross and Sto. Niño de Cebu Basílica too.
                  </p>
                </div>
              </div>

              <div className="hotel_details">
                <div className="contacts">
                  <h2>Noble Cebu</h2>
                  <p>Located at 8WG6+M82 Grand Residences Cebu - North Tower A, President Roxas Street, Cebu City, 6000 </p>
                  <p>Can be reached via phone at +6332 520 4870</p>
                </div>

                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Cebu+Hotels/03+Photo+by+The+Noble+Cebu+Hotel+via+Facebook.png"
                    alt=""
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by The Noble Cebu Hotel via Facebook</p>
                </div>

                <div className="info_grp">
                  <p>If you’re looking for a luxurious and sophisticated experience, Noble Cebu stands out as a premier choice within the city.</p>
                  <p>
                    This newly-built upscale establishment boasts elegant serviced residences featuring plush furnishings and top-notch facilities. There’s also a conch-shaped outdoor pool where you
                    can go for a refreshing dip. The hotel's commitment to excellence is evident in every aspect of your stay, from the serene spa to the gourmet dining options. They have something to
                    offer for foodies of every kind, from the pizza-lovers to Chinese cuisine fans, sushi-cravers to meat aficionados, and more.
                  </p>

                  <div className="bullet_grp">
                    <div className="bullet_list">
                      <p>
                        Located at the center of the city, Noble is also strategically close to most of urban Cebu’s popular attractions. Choose to stay here and you’ll be just a few minutes’ drive
                        away from:
                      </p>
                      <ul>
                        <li>Cebu IT Park</li>
                        <li>Cebu Business Park</li>
                        <li>Waterfront Cebu City Casino</li>
                        <li>the Osmeña Fountain Circle </li>
                        <li>Colon Street</li>
                        <li>Magellan’s Cross</li>
                        <li>SM Seaside City Cebu </li>
                      </ul>
                      <p>Whether you're here for business, relaxation, or exploration, Noble Cebu promises a refined experience that will exceed your expectations.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hotel_details">
                <div className="contacts">
                  <h2>Waterfront Hotel and Casino</h2>
                  <p>Located at Salinas Drive, Lahug, Cebu City, 6000</p>
                  <p>Can be reached via phone +6332 232 6888 and via email at wcch@waterfronthotels.net</p>
                </div>

                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Cebu+Hotels/04+Photo+by+Waterfront+Cebu+City+Hotel+and+Casino+via+Facebook.png"
                    alt=""
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by Waterfront Cebu City Hotel and Casino via Facebook</p>
                </div>

                <div className="info_grp">
                  <p>
                    If excitement and entertainment is more of your vibe, Waterfront Hotels & Casinos is the place to go for you. With its lively atmosphere and world-class gaming facilities, this
                    hotel is a great choice for those who want to add a touch of thrill to their Cebu trip.
                  </p>
                  <p>
                    This six-story entertainment and casino block is the flagship of the Waterfront chain. Within its sprawling property, you can take your pick from 561 guestrooms and suites.
                    Regardless of which room you end up in, you will have access to stunning views of the city and the sea.{' '}
                  </p>
                  <p>
                    Waterfront Hotels & Casinos is more than just a place to stay – it's a destination in itself, offering endless entertainment options and an unforgettable time. Here, you can take
                    your palate on an adventure with nine wining and dining outlets. You can unwind in state-of-the-art fitness and relaxation amenities. Last but not the least, you can host the
                    grandest of parties or events with superior meeting and conference facilities (they even have two grand ballrooms!)
                  </p>
                  <p>
                    Half an hour’s drive from Mactan-Cebu International Airport and located at the heart of the city, this leisurely stay is also conveniently near Cebu City’s hottest tourist spots.
                    Booking a night or two (or more, why not) here is giving yourself the chance to reside in one of Cebu’s most stunning landmarks.{' '}
                  </p>
                </div>
              </div>

              <div className="hotel_details">
                <div className="contacts">
                  <h2>Citadines Cebu City</h2>
                  <p>Located at Base Line Center, Juana Osmeña Street, Cebu City, 6000</p>
                  <p>Can be reached via phone at +6332 260 9588</p>
                </div>

                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Cebu+Hotels/05+Photo+by+princess_gladys_llanos+via+Instagram.png"
                    alt=""
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by princess_gladys_llanos via Instagram</p>
                </div>

                <div className="info_grp">
                  <p>Modern, sleek, and stylish – that's what Citadines Cebu City is all about. </p>
                  <p>
                    This trendy hotel provides posh contemporary apartment-style accommodations, perfect for travelers who value independence and a homely atmosphere. That is, a home upgraded with
                    dazzling views of the Queen City of the South. The fully-equipped kitchenettes in each room allow you to prepare your meals if you wish. If you feel like dining outside, there’s
                    also an in-house restaurant that you can grab a quick bite in.
                  </p>
                  <p>
                    For exercise, you can drop by their state-of-the-art gym or enjoy a dip in their outdoor infinity pool. Located in the bustling Cebu Business Park, Citadines Cebu City ensures that
                    you're always close to the action while enjoying a cozy retreat at the end of the day.
                  </p>
                  <p>You can enjoy shopping, go restaurant-hopping, and explore famous tourist spots without worrying about a long drive back to your Cebu home. </p>
                </div>
              </div>

              <div className="hotel_details">
                <div className="contacts">
                  <h2>Be Resort Mactan</h2>
                  <p>Located at Punta Engaño Road, Lapu-Lapu City, 6015</p>
                  <p>
                    Can be reached via mobile phone at +63 995 510 0812, via phone at +6332 236 8888, and via email at
                    <a href="mailto:mactan@beresorts.com" target="_blank" rel="noreferrer">
                      {' '}
                      mactan@beresorts.com
                    </a>
                  </p>
                </div>

                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Cebu+Hotels/06+Photo+by+piaocheco_+via+Instagram.png"
                    alt=""
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by piaocheco_ via Instagram</p>
                </div>

                <div className="info_grp">
                  <p>If your dream getaway involves the sun, sea, and sand, Be Resort Mactan should be at the top of your list. </p>
                  <p>
                    Tucked away in secluded Punta Engaño, this beachfront paradise offers quirkily interesting rooms with stunning views of crystal-clear waters and a white sandy beach. The resort's
                    design blends modern trends with a tropical vibe, creating a relaxing ambiance that amuses while putting you in vacation mode. Outside, they have an outdoor pool with direct access
                    and view of their stunning beachfront. Here, you can enjoy a lazy day of lounging by the pool or get your adrenaline running by trying out various water sports activities on the
                    beach.
                  </p>
                  <p>A breezy 15-minute drive from Mactan-Cebu International Airport, this unique accommodation promises a memorable beach escape for beach lovers of every age. </p>
                </div>
              </div>

              <div className="hotel_details">
                <div className="contacts">
                  <h2>Bai Hotel</h2>
                  <p>Located at Ouano Avenue corner Seno Boulevard, City South Special Economic Administrative Zone, Mandaue City, 6014</p>
                  <p>
                    Can be reached via mobile phone at +63 920 975 4017, via phone at +6332 342 8888, and via email at{' '}
                    <a href="mailto:mactan@beresorts.com" target="_blank" rel="noreferrer">
                      info@baihotel.com.ph
                    </a>
                  </p>
                </div>

                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Cebu+Hotels/07+Photo+by+florengalery+via+Instagram.png"
                    alt=""
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by florengalery via Instagram</p>
                </div>

                <div className="info_grp">
                  <p>With its striking architecture and local flair, Bai Hotel is a standout choice for travelers seeking both luxury and comfort.</p>
                  <p>
                    Situated between the lively cities of Mandaue and Cebu, this stay offers spacious and elegantly designed rooms with spectacular harbor and city views. Its ultra-modern amenities,
                    including an infinity pool and fitness center, are designed to cater to your every need. This as well as the service and every other feature in the hotel are a testament to its
                    namesake — the Cebuano word for “friend”.
                  </p>
                  <p>With a dedication to becoming your own personal tranquil oasis, Bai Hotel proves itself to be a prime haven for you to rest and relax in after a hectic day of exploration. </p>
                </div>
              </div>

              <div className="hotel_details">
                <div className="contacts">
                  <h2>Seda Central Bloc Cebu</h2>
                  <p>Located at Cebu I.T. Park, Padriga Street, Apas, Cebu City, 6000</p>
                  <p>
                    Can be reached via phone at +6332 410 8899 and via email at{' '}
                    <a href="mailto:cbcebu@sedahotels.com" target="_blank" rel="noreferrer">
                      cbcebu@sedahotels.com
                    </a>
                  </p>
                </div>

                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Cebu+Hotels/08+Photo+by+Seda+Central+Bloc+Cebu+via+Facebook.png"
                    alt=""
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by Seda Central Bloc Cebu via Facebook</p>
                </div>

                <div className="info_grp">
                  <p>If you appreciate a modern and well-connected stay, Seda Central Bloc Cebu is the perfect choice. </p>
                  <p>
                    This 17-story contemporary hotel offers a selection of elegant hotel rooms for short stays and homey serviced residences for extended visits. Situated within Cebu I.T. Park, the
                    newest business district of the city, this hotel also boasts an ideal location for sightseeing. Book a room here, and you’ll be effortlessly close to must-visit tourist spots like
                    the Mountain View Nature’s Park and the Cebu Taoist Temple.
                  </p>

                  <p>
                    While the stylish amenities and friendly staff offer a rejuvenating environment, the hotel's in-house rooftop bar offers a dynamic dining experience. At Straight Up Rooftop Bar,
                    you can revel in the panoramic views of the Cebu City skyline while relishing a delicious meal. Combining convenience, comfort, and a touch of indulgence, Seda Central Bloc Cebu
                    promises you a seamless stay in the heart of Cebu.
                  </p>
                </div>
              </div>

              <div className="hotel_details">
                <div className="contacts">
                  <h2>Solea Mactan Resort</h2>
                  <p>Located at Victor Wahing Street, Alegria Cordova, Cebu</p>
                  <p>
                    Can be reached via phone at +6332 517 8889 and via email at{' '}
                    <a href="mailto:cbcebu@sedahotels.com" target="_blank" rel="noreferrer">
                      info@soleahotels.com
                    </a>
                  </p>
                </div>

                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Cebu+Hotels/09+Photo+by+Solea+Mactan+Cebu+resort+via+Facebook.png"
                    alt=""
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by Solea Mactan Cebu resort via Facebook</p>
                </div>

                <div className="info_grp">
                  <p>
                    For a tranquil beachfront escape with a touch of luxury, Solea Hotel Mactan is an excellent option. If you want to escape any and all reminders of your hectic work-life back home,
                    this grand resort offers a peaceful getaway in a serene location along the shores of Mactan Island.{' '}
                  </p>
                  <p>
                    The rooms here are spacious, each with its own private balcony and stunning views of the sea. For recreation, there’s a large salt-water outdoor infinity pool decked out with
                    kid-friendly water slides.{' '}
                  </p>
                  <p>
                    Aside from this, the resort also boasts a colorful game room, a fitness center, a spa, and more water sports facilities. You are sure to have your pick of marvelous experiences,
                    only to be topped off with access to a private beach area.{' '}
                  </p>
                </div>
              </div>

              <div className="extensive_story">
                <h2>Get a room…with GalaGO!</h2>
                <div className="info_grp">
                  <p>With so many top-notch options, it can be difficult and overwhelming to decide which hotel or resort is right for you. That’s why GalaGO! is here to make it easier. </p>
                  <p>
                    You can look through, inquire, and book any of the premier hotels listed above with just one click on our website. Make your reservations now and you’ll also get a half-day Cebu
                    City Tour to go with your booking.
                  </p>
                </div>
              </div>

              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Cebu+Hotels/10+Photo+via+sugbo.png"
                  alt=""
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />
                <p>Photo via sugbo.ph</p>
              </div>

              <div className="extensive_story">
                <div className="bullet_grp">
                  <div className="bullet_list">
                    <p>This package will already include: </p>
                    <ul>
                      <li>a three-days-two-nights air-conditioned room accommodation </li>
                      <li>daily breakfast at your hotel of choice</li>
                      <li>roundtrip airport to hotel transfer</li>
                      <li>air-conditioned vehicle for the duration of your tour</li>
                      <li>a GalaGO! vetted tour coordinator</li>
                      <li>all and any entrance fees needed for the rest of your trip</li>
                    </ul>
                  </div>
                </div>

                <div className="bullet_grp">
                  <div className="bullet_list">
                    <p>As for the complementary Cebu City Tour, the inclusions for this half-day exploration would be a stop by:</p>
                    <ul>
                      <li>Cebu Taoist Temple</li>
                      <li>Heritage of Cebu Monument</li>
                      <li>Yap-San Diego Ancestral House</li>
                      <li>Magellan’s Cross</li>
                      <li>Basilica Minore del Sto. Nino</li>
                      <li>Fuente Osmena Circle</li>
                      <li>Capitol Site</li>
                    </ul>
                  </div>
                </div>

                <div className="info_grp">
                  <p>This experience will also include participating in a running tour at Colon Street. </p>
                  <p>
                    This amazing GalaGO! offer won’t just make your dream Cebu vacation a reality. It will also give you your money’s worth and spare you from any inconvenience during your trip. Don’t
                    miss your chance and grab it now.{' '}
                  </p>
                </div>
              </div>

              <div className="footnote">
                <div className="title_container">
                  <h2>Enhance your Cebu experience </h2>
                </div>
                <div className="info">
                  <p>
                    There’s no denying that Cebu's hotel scene offers an impressively wide range of choices. Whatever it is you’re looking for — whether you're seeking luxury, convenience, beachfront
                    bliss, or a modern retreat — you will find something to meet your every preference.{' '}
                  </p>
                  <p>
                    The list we curated for you above, however, goes beyond meeting your needs. These premier hotels and resorts we’ve recommended to you won’t just give you a place to stay. They will
                    undoubtedly enhance your Cebu experience, too.{' '}
                  </p>
                  <p>Book your stay at one of these exceptional hotels today, and ready yourself for an incredible journey in the enchanting city of Cebu.</p>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="featured_blogs">
            <h2>Featured Blogs</h2>
            <div className="card_container">
              <div className="blog_card col-md-6 col-12">
                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg"
                    alt="Discover the Best Things to Do in Bohol All in One Tour!"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />

                  <div className="badge">
                    <h2>Travel Tip</h2>
                  </div>
                </div>

                <div className="info_grp">
                  <div className="detail">
                    <p className="date"> September 25, 2023</p>
                    <p className="separator">·</p>
                    <p className="duration">6 min read</p>
                  </div>

                  <h3>Discover the Best Things to Do in Bohol All in One Tour!</h3>

                  <a href="/#/discover-the-best-things-to-do-in-bohol-all-in-one-tour" className="read_article_btn">
                    <div className="read_btn">
                      <p>Read Article</p>
                      <ArrowRightSvg _color="#006FA9" _width={34} _height={14} />
                    </div>
                  </a>
                </div>
              </div>

              <div className="blog_card col-md-6 col-12">
                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg"
                    alt="Guide to Travel Requirements in the Philippines"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />

                  <div className="badge">
                    <h2>Travel Tip</h2>
                  </div>
                </div>

                <div className="info_grp">
                  <div className="detail">
                    <p className="date"> September 25, 2023</p>
                    <p className="separator">·</p>
                    <p className="duration">6 min read</p>
                  </div>

                  <h3>Guide to Travel Requirements in the Philippines</h3>

                  <a href="/#/guide-to-travel-requirements-in-the-philippines" className="read_article_btn">
                    <div className="read_btn">
                      <p>Read Article</p>
                      <ArrowRightSvg _color="#006FA9" _width={34} _height={14} />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
    };
export default CebuHotels;
