import React, { Key, useEffect, useState } from 'react';
import { FloatingLabelFormControl, SelectFloatingLabelFormControl } from '../../../components/FormControl';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../components/ui/tabs';
import { FlightPassengersStore } from '../../../store/FlightPassengersStore';
import moment from 'moment';
import { FlightPassengersHook } from '../../../hooks/FlightPassengersHook';
import { UserStore } from '../../../store/UserStore';
import { Calendar } from 'lucide-react';
import { Calendar as ReactCalendar } from 'react-calendar';
import { TitleDropdown } from '../../../components/TitleDropdown';
import { FlightResultsHook } from '../../../hooks/FlightResultsHook';
import { RestCountriesSelectInput } from '../../../components/RestCountriesSelectInput';
import { CircledInfoSvg } from '../../../resources/svg/CircledInfoSVG';
import { CaretUpSVG } from '../../../resources/svg/CaretupSVG';
import { AirlineFlyerSelectInput } from '../../../components/AirlineFlyerSelectInput';
import { truncate } from '../../../utils/string';
import { useSearchParams } from 'react-router-dom';
import { InfoCircle } from '../../../resources/svg/InfoSvg';
import '../../../resources/css/hotel-option-dropdown.scss';
// import { CaretDownSVG } from '../../../resources/svg/carret-down-svg';
import { ChevronDownSvg } from '../../../resources/svg/ChevronDownSvg';
import { CalendarSvg } from '../../../resources/svg/CalendarSvg';
import '../../../resources/css/form-control.scss';
import SelectOption from '../../../components/SelectOption';
import { CountriesHook } from '../../../hooks/CountriesHook';

type PassengerTabsProps = {
  PassengerCount: number;
  isDomesticFlight?: boolean;
  Flights: { [index: string]: any };
  Passengers: { [index: string]: any };
  FlightType: 'one-way' | 'round-trip';
  flyer?: string;
  titleErrors?: boolean[];
  lastNameErrors?: boolean[];
  firstNameErrors?: boolean[];
  birthDateErrors?: boolean[];
  passportNumberErrors?: boolean[];
  nationalityErrors?: boolean[];
  DepartureAirline: { [index: string]: any } | undefined;
  ReturnAirline: { [index: string]: any } | undefined;
};

const PassengerTabs = ({ PassengerCount, isDomesticFlight, Flights, Passengers, FlightType, flyer, DepartureAirline, ReturnAirline }: PassengerTabsProps) => {
  // if guest is logged in then the isLoggedin is false
  const { isLoggedIn } = UserStore();
  const [searchParams] = useSearchParams();

  let departureDate = searchParams.get('departureDate') ?? new Date();
  const { flightSegments } = FlightResultsHook();
  const { nationalities, countries } = CountriesHook();

  const [isSwitchOn, setIsSwitchOn] = useState<Boolean>(false);
  // const { setCenter, setDialogMessage, setShowDialog } = DialogBoxStore();

  // const handleToggleSwitch = (e: any) => {
  //   setIsSwitchOn(e.target.checked);
  // };

  let _marketingCode = '';
  let minimumPassportExpirationDate = new Date();
  if (FlightType === 'round-trip') {
    const { departureDateTime, MarketingCarriercode } = flightSegments(Flights.OriginDestinations);
    minimumPassportExpirationDate = new Date(moment(new Date(departureDateTime)).add(6, 'M').format('YYYY-MM-DD'));

    _marketingCode = MarketingCarriercode;
  } else {
    const { departureDateTime, MarketingCarriercode } = flightSegments(Flights.OriginDestinations);
    _marketingCode = MarketingCarriercode;

    minimumPassportExpirationDate = new Date(moment(new Date(departureDateTime)).add(6, 'M').format('YYYY-MM-DD'));
  }
  // eslint-disable-next-line

  const { appendSelectedPassenger, appendSelectedPassengerKeys, selectedPassengerKeys } = FlightPassengersStore();
  const { getAllSavedPassengers } = FlightPassengersHook();
  // const { refreshToken } = AuthenticationHooks();
  const [savedCompanions, setSavedCompanions] = useState<{ [index: string]: any }>();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  // const [selectedCompanions, setSelectedCompanions] = useState<any>();
  const [showBirthDateCalendar, setShowBirthDateCalendar] = useState(false);
  const [showPassportCalendar, setShowPassportCalendar] = useState(false);
  // const [adultCalendarBirthDate, setAdultCalendarBirthDate] = useState<any>();

  //Title Tooltip
  const [displayed, setDisplayed] = useState(false);
  const [tooltipHover, setTooltipHover] = React.useState('#4FA2B4');

  useEffect(() => {
    // showMinorPassengerWarningDialog();
    if (!isLoggedIn) return;
    (async () => {
      const data = await getAllSavedPassengers();
      // console.log(data.data.data);
      setSavedCompanions(data.data.data);
    })();
    // eslint-disable-next-line
  }, []);

  let adult = Passengers.adults;
  let minors = Passengers.minors;
  let children = Passengers.children;
  let infant = Passengers.infants;

  const [passengers, setPassengers] = useState<{ [index: string]: any }>();

  let passengerTypes: string[] = [];
  let tabs: { [index: string]: any } = []; // tab object

  for (let i = 0; i <= PassengerCount; i++) {
    if (adult !== 0) {
      passengerTypes[i] = 'Adult';
      --adult;
    }
    if (adult === 0 && minors !== 0) {
      passengerTypes[i + 1] = 'Minor';
      --minors;
    }
    if (adult === 0 && minors === 0 && children !== 0) {
      passengerTypes[i + 2] = 'Child';
      --children;
    }
    if (adult === 0 && minors === 0 && children === 0 && infant !== 0) {
      passengerTypes[i + 3] = 'Infant';
      --infant;
    }
  }

  const passengerTypesArray = passengerTypes.filter((element) => {
    return element !== null && element !== undefined;
  });

  // dynamic tab creation
  for (let i = 1; i <= PassengerCount; i++) {
    // const passengerName = passengers && passengers[i - 1];
    tabs[i] = {
      id: (i - 1).toString(),
      // tabName: PassengerCount >= 5 ? `${Array.from(passengerTypesArray[i - 1])[0]} - ${i}` : `${passengerTypesArray[i - 1]} ${i} ${passengerName?.firstName}`,
      tabName: `${passengerTypesArray[i - 1]}`,
      title: `${passengerTypesArray[i - 1]}`,
    }; // generate values inside the tab object
  }

  useEffect(() => {
    let names: { [index: string]: any } = [];
    for (let passenger in passengers) {
      names.push({
        firstName: passengers[passenger].firstName,
        lastName: passengers[passenger].lastName,
        middleName: passengers[passenger].middleName,
        title: passengers[passenger].title,
        nationality: passengers[passenger].nationality !== undefined ? passengers[passenger].nationality : 'PH',
        birthDate: passengers[passenger].birthDate ? moment(passengers[passenger].birthDate).format('YYYY-MM-DD') : undefined,
        passportNumber: passengers[passenger].passportNumber ? passengers[passenger].passportNumber : undefined,
        expirationDate: passengers[passenger].expirationDate ? moment(passengers[passenger].expirationDate).format('YYYY-MM-DD') : undefined,
        countryIssued: passengers[passenger].countryIssued ? passengers[passenger].countryIssued : !isDomesticFlight ? 'PH' : undefined,
        id: passengers[passenger].id,
        signAs: passengers[passenger].signAs,
        user: passengers[passenger].user,
      });
    }
    appendSelectedPassenger(names);
    // eslint-disable-next-line
  }, [passengers]);

  // for the original frequent passenger (select option) -------
  const selectedCompanion = (e: any) => {
    // convert object ot string
    const companions = savedCompanions?.map(function (item: any) {
      return item;
    });
    // console.log(companions);
    const selectedCompanion = companions[e.target.value];

    appendSelectedPassengerKeys([Number(e.target.value)]);

    // for the customized frequent passenger -------------------
    // const selectedCompanion = (key: Key | number) => {
    //   const companions = savedCompanions?.map(function (item: any) {
    //     return item;
    //   });
    //   const selectedCompanion = companions[key];

    //   appendSelectedPassengerKeys([Number(key)]);

    let newValue = {
      ...passengers?.[activeTabIndex],
      ...{
        firstName: selectedCompanion.firstName,
        middleName: selectedCompanion.middleName,
        lastName: selectedCompanion.lastName,
        title: selectedCompanion.title,
        birthDate: new Date(selectedCompanion.birthDate),
        nationality: selectedCompanion.nationality,
        passportNumber: selectedCompanion.passportNumber,
        expirationDate: selectedCompanion.expirationDate ? new Date(selectedCompanion.expirationDate) : undefined,
        countryIssued: selectedCompanion.countryIssued,
        id: selectedCompanion.id,
        signAs: selectedCompanion.signAs,
        user: selectedCompanion.user,
        // passengerType: selectedCompanion.passengerType,
      },
    };
    setPassengers({ ...passengers, ...{ [activeTabIndex]: newValue } });
  };
  // console.log(passengers);

  const ageLimiter = (passengerType: string) => {
    // console.log(departureDate);
    if (departureDate === null || departureDate === undefined) departureDate = new Date();

    if (passengerType === 'Adult') {
      return {
        min: new Date(moment(new Date(departureDate)).subtract(120, 'y').format('YYYY-MM-DD')),
        max: new Date(moment(new Date(departureDate)).subtract(18, 'y').format('YYYY-MM-DD')),
      };
    }
    if (passengerType === 'Minor') {
      return {
        min: new Date(moment(new Date(departureDate)).subtract(17, 'y').subtract(11, 'm').format('YYYY-MM-DD')),
        max: new Date(moment(new Date(departureDate)).subtract(12, 'y').format('YYYY-MM-DD')),
      };
    }
    if (passengerType === 'Child') {
      return {
        min: new Date(moment(new Date(departureDate)).subtract(11, 'y').subtract(11, 'm').format('YYYY-MM-DD')),
        max: new Date(moment(new Date(departureDate)).subtract(2, 'y').format('YYYY-MM-DD')),
      };
    }
    return {
      min: new Date(moment(new Date(departureDate)).subtract(1, 'y').subtract(11, 'm').format('YYYY-MM-DD')),
      max: new Date(moment(new Date(departureDate)).subtract(0, 'y').format('YYYY-MM-DD')),
    };
  };

  // const showMinorPassengerWarningDialog = () => {
  //   setShowDialog(true);
  //   setCenter(false);
  //   setDialogMessage(
  //     <div className="px-5 py-4 d-flex flex-column align-items-end">
  //       <p className="fs-5 fw-bolder mb-3">Minor passengers should be accompanied by an adult!</p>
  //       <p className="mb-3">
  //         One or more passengers on this booking appear to be under the age of 18. Unaccompanied minors are not permitted to travel. Please verify passenger information and ensure all minors are
  //         accompanied by an adult.
  //       </p>
  //       <GalagoButton
  //         label="Okay"
  //         className="!w-[150px]"
  //         onClick={() => {
  //           setShowDialog(false);
  //         }}
  //       />
  //     </div>
  //   );
  // };

  // const [nationalityError, setNationalityError] = useState(false);
  // const [passportNumberError, setPassportNumberError] = useState(false);
  // const [countryIssuedError, setCountryIssuedError] = useState(false);
  const [showTitleDropDown, setShowTitleDropDown] = useState(false);
  // const [showFrequentDropdown, setShowFrequentDropdown] = useState(false);

  // Form error-handling  [array]
  const [titleErrors, setTitleErrors] = useState<boolean[]>([]);
  const [firstNameErrors, setFirstNameErrors] = useState<boolean[]>([]);
  const [lastNameErrors, setLastNameErrors] = useState<boolean[]>([]);
  const [birthDateErrors, setBirthDateErrors] = useState<boolean[]>([]);
  const [nationalityErrors, setNationalityErrors] = useState<boolean[]>([]);
  const [passportNumberErrors, setPassportNumberErrors] = useState<boolean[]>([]);
  // const [frequentFlyerErrors, setFrequentFlyerErrors] = useState<boolean[]>([]);
  const [countryIssuedErrors, setCountryIssuedErrors] = useState<boolean[]>([]);

  // Form focus events
  const [inputFocus, setInputFocus] = useState('');

  // Frequent Passengers
  // const [selectedCompanionOption, setSelectedCompanionOption] = useState<Key | null>('');
  return (
    <>
      <Tabs
        defaultValue={tabs[1]?.id}
        className="flex flex-row items-start rounded-tl-xl rounded-b-xl rounded-tr-xl justify-start text-[1rem] text-color-shades-white-900 font-footnote bg-gray-100 border-1"
      >
        <TabsList className="flex flex-column items-start rounded-tl-lg rounded-bl-lg justify-start w-[100px] overflow-hidden bg-gray-100">
          {tabs.map((tab: { tabName: string; id: string; title: string }, index: number) => (
            <TabsTrigger
              key={index}
              value={tab.id}
              className="cursor-pointer rounded-tl-lg rounded-bl-lg self-stretch font-medium min-w-[3rem] border-b-1 max-w-[9.38rem] px-[20px] 
                        flex-col py-[10px] h-[64px] box-border items-center justify-center inline-flex whitespace-nowrap ring-offset-background transition-all 
                        focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none 
                        disabled:opacity-50 data-[state=active]:border-b-color-teal-teal-900 data-[state=active]:bg-white data-[state=active]:text-black 
                        data-[state=active]:shadow data-[state=inactive]:text-black font-footnote data-[state=active]:border-l-5 data-[state=active]:border-l-color-teal-teal-900"
              onClick={() => {
                setActiveTabIndex(index - 1);
              }}
              title={tab.title}
              style={{}}
            >
              {passengers?.[index - 1]?.firstName ? <span className="text-color-teal-teal-900">{tab.tabName}</span> : tab.tabName}
              {passengers?.[index - 1]?.firstName ? `${truncate(passengers?.[index - 1]?.firstName, 5)}` : ''}
            </TabsTrigger>
          ))}
        </TabsList>

        {tabs.map((tab: { tabName: string; id: string; title: string }, index: number) => (
          <TabsContent
            key={index}
            className="rounded-tl-none rounded-b-lg rounded-tr-lg box-border w-[37.56rem] flex flex-col p-4 items-start justify-start text-text-colors-text-secondary border-[1px] border-solid border-border-colors-border-primary bg-white"
            value={tab.id}
          >
            <div className="self-stretch flex flex-col items-start justify-start ">
              <div className="self-stretch flex flex-col items-start justify-start gap-[0.75rem]">
                <div className="self-stretch flex flex-row py-[12px] px-0 items-center justify-start text-[1.06rem] text-text-colors-text-primary">
                  <div className="relative font-medium font-footnote">Personal Details</div>
                </div>

                {isLoggedIn && (
                  <>
                    {savedCompanions && savedCompanions.length > 0 && (
                      <>
                        {/* <div className="form-floating w-100 transparent-line">
                          <SelectFloatingLabelFormControl
                            type="text"
                            placeholder="Frequent Passengers"
                            label="Frequent Passengers"
                            value={selectedCompanionOption ? 'Selected Frequent Passenger' : ''}
                            onClick={() => setShowFrequentDropdown(true)}
                            onFocus={() => setInputFocus('frequentPassenger')}
                            onBlur={() => setInputFocus('')}
                            success={selectedCompanionOption !== '' || inputFocus === 'frequentPassenger'}
                            receivedValue={selectedCompanionOption !== '' ? true : false}
                            autoComplete="false"
                            style={{ width: '100%', borderWidth: '2px', borderColor: selectedCompanionOption ? '#4FA2B4' : undefined }}
                          />
                          {showFrequentDropdown && (
                            <div className="dropdown-option w-100" onMouseLeave={() => setShowFrequentDropdown(false)}>
                              {savedCompanions?.map((value: { firstName: string; lastName: string }, key: Key | number) => {
                                !selectedPassengerKeys.includes(key) && (
                                  <div
                                    key={key}
                                    onClick={() => {
                                      selectedCompanion(key);
                                      setShowFrequentDropdown(false);
                                      setSelectedCompanionOption(value.firstName + ' ' + value.lastName);
                                    }}
                                  >
                                    {value.firstName} {value.lastName}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div> */}
                        <div className="form-floating w-100">
                          <select
                            className="form-select"
                            aria-label=""
                            onChange={selectedCompanion}
                            onClick={(e) => {
                              // console.log(e.target);
                            }}
                            style={{ borderWidth: '2px' }}
                          >
                            <option defaultValue="Select Frequent Passenger" selected disabled>
                              Select Frequent Passenger
                            </option>
                            {savedCompanions?.map((value: { firstName: string; lastName: string }, key: Key | number) => {
                              return (
                                <option key={key} value={key} disabled={selectedPassengerKeys.slice(0, tabs.length - 1).indexOf(key) !== -1 ? true : false}>
                                  {value.firstName} {value.lastName}
                                </option>
                              );
                            })}
                          </select>
                          <label>Frequent Passengers</label>
                        </div>
                        <div className="self-stretch flex flex-row py-2.5 px-4 items-center justify-between">
                          <img className="relative w-[11.25rem] h-[0.06rem]" alt="" src="/svg/vector-48.svg" />
                          <div className="relative leading-[1.31rem] font-medium">or fill-up the fields</div>
                          <img className="relative w-[11.25rem] h-[0.06rem]" alt="" src="/svg/vector-48.svg" />
                        </div>
                      </>
                    )}
                  </>
                )}

                {/* title dropdown */}
                <div className=" w-full flex flex-row gap-[10px] items-center position-relative ">
                  <div className="w-100 transparent-line">
                    <SelectFloatingLabelFormControl
                      placeholder="Title *"
                      label="Title *"
                      type="text"
                      value={passengers?.[index - 1]?.title ? passengers?.[index - 1]?.title?.charAt(0).toUpperCase() + passengers?.[index - 1]?.title?.slice(1) : ''}
                      onClick={() => setShowTitleDropDown(true)}
                      onFocus={() => setInputFocus('title')}
                      onBlur={() => setInputFocus('')}
                      success={passengers?.[index - 1]?.title || inputFocus === 'title'}
                      error={titleErrors[index - 1] && !passengers?.[index - 1]?.title}
                      receivedValue={passengers?.[index - 1]?.title ? true : false}
                      defaultValue={passengers?.[index - 1]?.title}
                      autoComplete="false"
                      style={{ width: '100%', borderWidth: '2px', borderColor: passengers?.[index - 1]?.title && '#4FA2B4' }}
                    />

                    {showTitleDropDown && (
                      <div
                        onMouseLeave={() => {
                          setShowTitleDropDown(false);
                          passengers?.[index - 1]?.title
                            ? setTitleErrors((prevErrors) => {
                                const newErrors = [...prevErrors] as boolean[];
                                newErrors[index - 1] = false;
                                return newErrors;
                              })
                            : // Else, set error to true
                              setTitleErrors((prevErrors) => {
                                const newErrors = [...prevErrors] as boolean[];
                                newErrors[index - 1] = true;
                                return newErrors;
                              });
                        }}
                      >
                        {/* TITLE DROP DOWN -----  */}
                        <TitleDropdown passengers={passengers} index={index} setPassengers={setPassengers} setShowTitleDropDown={setShowTitleDropDown} passengerType={tab.title} />
                      </div>
                    )}
                  </div>

                  {/* Tooltip */}

                  <div className=" cursor-pointer z-40" onMouseEnter={() => setDisplayed(true)} onMouseLeave={() => setDisplayed(false)}>
                    <span onMouseEnter={() => setTooltipHover('#006FA9')} onMouseLeave={() => setTooltipHover('#4FA2B4')}>
                      <CircledInfoSvg _color={tooltipHover} />
                    </span>
                    {displayed && (
                      <div className="tooltip-container position-absolute top-10 right-0 w-80">
                        <div className="d-flex flex-column gap-0">
                          <div className="ms-auto p-0">
                            <CaretUpSVG _width={26} _height={16} />
                          </div>
                          <div className="content-holder bg-white p-[16px] ring ring-[#4FA2B4] ring-offset-1 rounded-md  mt-0 flex flex-column gap-0 drop-shadow-xl">
                            <div className="mister d-flex flex-column gap-[4px] p-0 m-0">
                              <span className="text-primary font-medium font-footnote">Mr.</span>
                              <span className="textColor font-regular font-footnote">For men aged 18 and above.</span>
                            </div>
                            <hr className="border-t-2 border-neutral-300" />
                            <div className="ms d-flex flex-column gap-[4px] p-0 m-0">
                              <span className="text-primary font-medium font-footnote">Ms.</span>
                              <span className="textColor font-regular font-footnote">For women aged 18 and above.</span>
                            </div>
                            <hr className="border-t-2 border-neutral-300" />
                            <div className="master d-flex flex-column gap-[4px] p-0 m-0">
                              <span className="text-primary font-medium font-footnote">Master</span>
                              <span className="textColor font-regular font-footnote">For boys aged 17 and below.</span>
                            </div>
                            <hr className="border-t-2 border-neutral-300" />
                            <div className="miss d-flex flex-column gap-[4px] p-0 m-0">
                              <span className="text-primary font-medium font-footnote">Mistress</span>
                              <span className="textColor font-regular font-footnote">For girls aged 17 and below.</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {titleErrors[index - 1] && !passengers?.[index - 1]?.title && (
                  <span className="flex items-center" style={{ color: '#BD0A00' }}>
                    <InfoCircle _color="#BD0A00" />
                    &nbsp;Choose title.
                  </span>
                )}

                {/* name inputs */}
                {/* FIRST NAME ----------------------------------- */}
                <div className="w-full">
                  <FloatingLabelFormControl
                    type={'text'}
                    placeholder={'First Name'}
                    error={firstNameErrors[index - 1]}
                    success={passengers?.[index - 1]?.firstName || inputFocus === 'firstName'}
                    label={`First Name *`}
                    autoComplete="false"
                    onChange={(e) => {
                      let newValue = { ...passengers?.[index - 1], ...{ firstName: e.target.value } };
                      setPassengers({ ...passengers, ...{ [index - 1]: newValue } });
                      let firstName = e.target.value;
                      if (firstName.length === 0) {
                        setFirstNameErrors((prevErrors) => {
                          const newErrors = [...prevErrors] as boolean[];
                          newErrors[index - 1] = true;
                          return newErrors;
                        });
                        return;
                      }
                      setFirstNameErrors((prevErrors) => {
                        const newErrors = [...prevErrors] as boolean[];
                        newErrors[index - 1] = false;
                        return newErrors;
                      });
                    }}
                    onFocus={(e) => setInputFocus('firstName')}
                    onBlur={(e) => setInputFocus('')}
                    value={passengers?.[index - 1]?.firstName ?? ''}
                    style={{ width: '100%', borderColor: passengers?.[index - 1]?.firstName ? '#4FA2B4' : '' }}
                  />
                  {firstNameErrors[index - 1] && (
                    <span className="align-items-center mt-2 flex" style={{ color: '#BD0A00' }}>
                      <InfoCircle _color="#BD0A00" />
                      &nbsp;Please enter your first name
                    </span>
                  )}
                </div>

                {/* MIDDLE NAME --------------------------------------------- */}
                <div className="w-full">
                  <FloatingLabelFormControl
                    onChange={(e) => {
                      let newValue = { ...passengers?.[index - 1], ...{ middleName: e.target.value } };
                      setPassengers({ ...passengers, ...{ [index - 1]: newValue } });
                    }}
                    value={passengers?.[index - 1]?.middleName ?? ''}
                    type={'text'}
                    placeholder={'Middle Name (Optional)'}
                    label={'Middle Name (Optional)'}
                    style={{ width: '100%', borderColor: passengers?.[index - 1]?.middleName ? '#4FA2B4' : '' }}
                    autoComplete="false"
                    success={passengers?.[index - 1]?.middleName || inputFocus === 'middleName'}
                    onFocus={(e) => setInputFocus('middleName')}
                    onBlur={(e) => setInputFocus('')}
                  />
                </div>

                {/* LAST NAME ---------------------------------------------- */}
                <div className="w-full">
                  <FloatingLabelFormControl
                    value={passengers?.[index - 1]?.lastName}
                    type={'text'}
                    placeholder={'Last Name'}
                    label={'Last Name *'}
                    style={{ width: '100%', borderColor: passengers?.[index - 1]?.lastName ? '#4FA2B4' : '' }}
                    autoComplete="false"
                    onFocus={() => setInputFocus('lastName')}
                    onBlur={() => setInputFocus('')}
                    success={passengers?.[index - 1]?.lastName || inputFocus === 'lastName'}
                    error={lastNameErrors[index - 1] && !passengers?.[index - 1]?.lastName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      let newValue = { ...passengers?.[index - 1], ...{ lastName: e.target.value } };
                      setPassengers({ ...passengers, ...{ [index - 1]: newValue } });
                      let lastName = e.target.value;
                      if (lastName.length === 0) {
                        setLastNameErrors((prevErrors) => {
                          const newErrors = [...prevErrors] as boolean[];
                          newErrors[index - 1] = true;
                          return newErrors;
                        });
                        return;
                      }
                      setLastNameErrors((prevErrors) => {
                        const newErrors = [...prevErrors] as boolean[];
                        newErrors[index - 1] = false;
                        return newErrors;
                      });
                    }}
                  />
                </div>
                {lastNameErrors[index - 1] && (
                  <span className="align-items-center flex" style={{ color: '#BD0A00' }}>
                    <InfoCircle _color="#BD0A00" />
                    &nbsp;Please enter your last name
                  </span>
                )}

                <div className="self-stretch flex flex-row py-1.5 px-0 items-start justify-start">
                  <div className="flex-1 relative leading-[1.31rem] font-regular">Please make sure your name matches the one on your passport or valid government ID.</div>
                </div>

                {/* BIRTHDATE ------------------------------ */}
                <div className="self-stretch flex flex-row items-start justify-start gap-[0.88rem]">
                  <div className="position-relative w-full  calendar-date transparent-line">
                    <SelectFloatingLabelFormControl
                      onClick={() => {
                        setShowBirthDateCalendar(true);
                      }}
                      onFocus={() => setInputFocus('birthDate')}
                      onBlur={() => setInputFocus('')}
                      value={passengers?.[index - 1]?.birthDate ? moment(passengers?.[index - 1]?.birthDate).format('MMM DD, YYYY') : ''}
                      type={'text'}
                      success={passengers?.[index - 1]?.birthDate || inputFocus === 'birthDate'}
                      error={birthDateErrors[index - 1] && !passengers?.[index - 1]?.birthDate}
                      icon={
                        <CalendarSvg
                          className="w-4 h-4 mr-[8px] text-black-black-200"
                          _color={
                            birthDateErrors[index - 1] && !passengers?.[index - 1]?.birthDate ? '#BD0A00' : passengers?.[index - 1]?.birthDate || inputFocus === 'birthDate' ? '#4FA2B4' : '#ADADAD'
                          }
                        />
                      }
                      label="Date of Birth *"
                      placeholder="Date of Birth*"
                      autoComplete="false"
                      style={{ height: '58px', width: '100%', border: '2px solid', borderColor: passengers?.[index - 1]?.birthDate ? '#4FA2B4' : '' }}
                      receivedValue={passengers?.[index - 1]?.birthDate ? true : false}
                      useBirthdayStyle={true}
                    />

                    {showBirthDateCalendar && (
                      <div
                        className="position-absolute z-10 pt-[10px]"
                        onMouseLeave={() => {
                          setShowBirthDateCalendar(false);
                          passengers?.[index - 1]?.birthDate
                            ? // If birthdate is not empty on mouseleave, set error to false
                              setBirthDateErrors((prevErrors) => {
                                const newErrors = [...prevErrors] as boolean[];
                                newErrors[index - 1] = false;
                                return newErrors;
                              })
                            : // Else, set error to true
                              setBirthDateErrors((prevErrors) => {
                                const newErrors = [...prevErrors] as boolean[];
                                newErrors[index - 1] = true;
                                return newErrors;
                              });
                        }}
                      >
                        <ReactCalendar
                          onChange={(e: any) => {
                            const selectedDate = moment(e).format('YYYY-MM-DD');
                            let newValue = { ...passengers?.[index - 1], ...{ birthDate: selectedDate } };
                            setPassengers({ ...passengers, ...{ [index - 1]: newValue } });
                          }}
                          defaultActiveStartDate={ageLimiter(tab.title).max}
                          maxDate={ageLimiter(tab.title).max}
                          minDate={ageLimiter(tab.title).min}
                          onClickDay={() => setShowBirthDateCalendar(false)}
                        />
                      </div>
                    )}
                  </div>
                  <SelectOption
                    dataOptions={nationalities as { [key: string]: string }[]}
                    placeholder="Nationality"
                    onChange={(e) => {
                      // console.log(e);
                      let newValue = { ...passengers?.[index - 1], ...{ nationality: e.label } };
                      setPassengers({ ...passengers, ...{ [index - 1]: newValue } });
                    }}
                  />
                  {/* NATIONALITY ----------------------------- */}
                  {/* <RestCountriesSelectInput
                    output={'nationalities'}
                    label={'Nationality'}
                    important
                    autoComplete="false"
                    setPassengers={setPassengers}
                    index={index}
                    passengers={passengers}
                    nationalityErrors={nationalityErrors}
                    setNationalityErrors={setNationalityErrors}
                    setInputFocus={setInputFocus}
                    inputFocus={inputFocus}
                  /> */}
                </div>
                {/* Error texts for Birthdate and Nationality  */}
                <div
                  className={`mt-[-8px] grid grid-cols-2 ${
                    (birthDateErrors[index - 1] && !passengers?.[index - 1]?.birthDate) || (nationalityErrors[index - 1] && !passengers?.[index - 1]?.nationality) ? 'mb-4' : 'mb-0'
                  }`}
                >
                  <span className={`align-items-center flex ${birthDateErrors[index - 1] && !passengers?.[index - 1]?.birthDate ? 'visible' : 'invisible'}`} style={{ color: '#BD0A00' }}>
                    <InfoCircle _color="#BD0A00" />
                    &nbsp;Please enter your birth date
                  </span>
                  {nationalityErrors[index - 1] && !passengers?.[index - 1]?.nationality && (
                    <span className="align-items-center flex pl-7" style={{ color: '#BD0A00' }}>
                      <InfoCircle _color="#BD0A00" />
                      &nbsp;Please select your nationality
                    </span>
                  )}
                </div>
              </div>

              {!isDomesticFlight && (
                <div className="self-stretch flex flex-col items-start justify-start gap-[0.75rem] text-[1.06rem] text-text-colors-text-primary">
                  <div className="flex flex-row items-start self-stretch justify-start px-0 py-1">
                    <div className="relative leading-[1.38rem] font-medium font-footnote textColor">Passport or Travel Document</div>
                  </div>
                  {/* pw stuff here */}
                  {/* PASSPORT NUMBER ---------------------- */}
                  <>
                    <div className="w-full">
                      <FloatingLabelFormControl
                        type={'text'}
                        placeholder={'Passport or Document Number *'}
                        label={'Passport or Document Number *'}
                        success={passengers?.[index - 1]?.passportNumber || inputFocus === 'passportNum'}
                        error={passportNumberErrors[index - 1]}
                        onFocus={() => setInputFocus('passportNum')}
                        onBlur={() => setInputFocus('')}
                        style={{ width: '100%', border: '2px solid', borderColor: passengers?.[index - 1]?.passportNumber ? '#4FA2B4' : '' }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          let newValue = { ...passengers?.[index - 1], ...{ passportNumber: e.target.value } };
                          setPassengers({ ...passengers, ...{ [index - 1]: newValue } });
                          let passportNumber = e.target.value;
                          if (passportNumber.length === 0) {
                            setPassportNumberErrors((prevErrors) => {
                              const newErrors = [...prevErrors] as boolean[];
                              newErrors[index - 1] = true;
                              return newErrors;
                            });
                            return;
                          }
                          setPassportNumberErrors((prevErrors) => {
                            const newErrors = [...prevErrors] as boolean[];
                            newErrors[index - 1] = false;
                            return newErrors;
                          });
                        }}
                        value={passengers?.[index - 1]?.passportNumber}
                        autoComplete="false"
                      />
                    </div>
                    {passportNumberErrors[index - 1] && (
                      <span className="align-items-center flex" style={{ color: '#BD0A00' }}>
                        <InfoCircle _color="#BD0A00" />
                        &nbsp;Please enter your passport ID or travel document number
                      </span>
                    )}

                    {/* <PassportValidity
                      onSelectFn={(value) => {
                        let newValue = { ...passengers?.[index - 1], ...{ passportValidity: value } };
                        setPassengers({ ...passengers, ...{ [index - 1]: newValue } });
                      }}
                      dateSelected={passengers?.[index - 1]?.passportValidity}
                      optionalClassName="h-[3.5rem] font-medium font-footnote"
                    /> */}

                    {/* EXPIRATION DATE --------------------------------- */}
                    <div className="position-relative w-full calendar-date transparent-line m-0">
                      <SelectFloatingLabelFormControl
                        onClick={() => {
                          setShowPassportCalendar(true);
                        }}
                        success={passengers?.[index - 1]?.expirationDate || inputFocus === 'expirationDate'}
                        onFocus={() => setInputFocus('expirationDate')}
                        onBlur={() => setInputFocus('')}
                        value={passengers?.[index - 1]?.expirationDate ? moment(passengers?.[index - 1]?.expirationDate).format('MMM DD, YYYY') : ''}
                        type={'text'}
                        icon={<Calendar className="w-4 h-4 mr-2 text-black-black-200" color={passengers?.[index - 1]?.expirationDate || inputFocus === 'expirationDate' ? '#4FA2B4' : '#ADADAD'} />}
                        label="Expiration Date"
                        placeholder="Expiration Date*"
                        autoComplete="false"
                        style={{ width: '100%', border: '2px solid', borderColor: passengers?.[index - 1]?.expirationDate ? '#4FA2B4' : '' }}
                        receivedValue={passengers?.[index - 1]?.expirationDate ? true : false}
                      />

                      {showPassportCalendar && (
                        <div className="position-absolute z-10" onMouseLeave={() => setShowPassportCalendar(false)}>
                          <ReactCalendar
                            onChange={(e: any) => {
                              const selectedDate = moment(e).format('YYYY-MM-DD');
                              let newValue = { ...passengers?.[index - 1], ...{ expirationDate: selectedDate } };
                              setPassengers({ ...passengers, ...{ [index - 1]: newValue } });
                            }}
                            onClickDay={() => setShowPassportCalendar(false)}
                            defaultActiveStartDate={minimumPassportExpirationDate}
                            minDate={minimumPassportExpirationDate}
                          />
                        </div>
                      )}
                    </div>

                    {/* <CountryDropdown optionalClassName="w-full font-footnote" /> */}

                    <div className="w-full">
                      {/* <FloatingLabelFormControl
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          let newValue = { ...passengers?.[index - 1], ...{ countryIssued: e.target.value } };
                          setPassengers({ ...passengers, ...{ [index - 1]: newValue } });
                        }}
                        value={passengers?.[index - 1]?.countryIssued}
                        type={'text'}
                        placeholder={'Country Issued'}
                        label={'Country Issued'}
                        style={{ width: '100%' }}
                        autoComplete="false"
                      /> */}

                      {/* COUNTRY ISSUED  */}
                      {/* <RestCountriesSelectInput
                        output={'countries'}
                        label={'Country Issued'}
                        value={passengers?.[index - 1]?.countryIssued}
                        important
                        setPassengers={setPassengers}
                        passengers={passengers}
                        index={index}
                        countryIssuedErrors={countryIssuedErrors}
                        setCountryIssuedErrors={setCountryIssuedErrors}
                        nationalityErrors={''}
                        setNationalityErrors={() => {}}
                        setInputFocus={setInputFocus}
                        inputFocus={inputFocus}
                      /> */}

                      <SelectOption
                        dataOptions={countries as { [key: string]: string }[]}
                        placeholder="Country Issued"
                        onChange={(e) => {
                          // console.log(e);
                          let newValue = { ...passengers?.[index - 1], ...{ countryIssued: e.label } };
                          setPassengers({ ...passengers, ...{ [index - 1]: newValue } });
                        }}
                      />
                      {countryIssuedErrors[index - 1] && !passengers?.[index - 1]?.countryIssued && (
                        <span className="align-items-center flex" style={{ color: '#BD0A00' }}>
                          <InfoCircle _color="#BD0A00" />
                          &nbsp;Please select your ID’s issuance country
                        </span>
                      )}
                    </div>
                  </>
                  <div className="flex-1 relative text-[1rem] leading-[1.31rem] font-regular text-text-colors-text-secondary inline-block w-[35.06rem]">
                    Please make sure your passport or ID is valid for at least 6 months from your departure date.
                  </div>

                  {/* FREQUENT FLYER ----------------------  */}
                  <div className="flex flex-col gap-[12px] py-[10px] w-100 bg-[#FCFCFC] p-[10px] mt-3">
                    {/* <div className="form-check form-switch"> */}
                    {/* <input onClick={handleToggleSwitch} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" /> */}
                    {/* </div> */}
                    <div
                      className="flex justify-between items-center w-100   cursor-pointer"
                      onClick={() => {
                        setIsSwitchOn(!isSwitchOn);
                      }}
                    >
                      <p className="font-regular text-text-colors-text-primary generalsans-medium text-[17px]"> Frequent Flyer Number</p>
                      <ChevronDownSvg _color={isSwitchOn ? '#4FA2B4' : '#ADADAD'} style={{ transform: isSwitchOn ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                    </div>

                    {isSwitchOn && (
                      <>
                        <div className="transparent-line text-secondary">
                          If you have a frequent flyer number please enter here. We share program details with the airline, though we cannot guarantee point rewards
                        </div>
                        <AirlineFlyerSelectInput
                          label={'Airline'}
                          carrier={_marketingCode}
                          DepartureAirline={DepartureAirline}
                          ReturnAirline={ReturnAirline}
                          setInputFocus={setInputFocus}
                          inputFocus={inputFocus}
                          style={{ width: '100%', border: '2px solid', borderColor: passengers?.[index - 1]?.frequentFlyer ? '#4FA2B4' : '' }}
                        />
                        <FloatingLabelFormControl
                          type={'number'}
                          placeholder={'Frequent Reference Number'}
                          onChange={(e) => setPassengers({ ...passengers, ...{ [index - 1]: { ...passengers?.[index - 1], ...{ frequentFlyer: e.target.value } } } })}
                          label={'Frequent Reference Number'}
                          style={{ width: '100%', borderColor: passengers?.[index - 1]?.frequentFlyer ? '#4FA2B4' : '' }}
                          maxLength={15}
                          onFocus={() => setInputFocus('freqNum')}
                          onBlur={() => setInputFocus('')}
                          success={passengers?.[index - 1]?.frequentFlyer || inputFocus === 'freqNum'}
                        />
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </TabsContent>
        ))}
      </Tabs>
    </>
  );
};

export default PassengerTabs;
