import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { MouseEvent as ReactMouseEvent } from 'react';
import { SearchPageHook } from '../../hooks/SearchPageHook';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { SwitchArrowHorizontal, SwitchArrowSvg } from '../../resources/svg/SwitchArrowSvg';
import { FloatingIconFormControl } from '../../components/FormControl';
import { CalendarSvg } from '../../resources/svg/CalendarSvg';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../../resources/css/async-type-ahead.scss';
import '../../resources/css/flight-tab-index.scss';
import { GalagoButton } from '../../components/Button';
import { SearchSvg } from '../../resources/svg/SearchSvg';
import { LocationSvg } from '../../resources/svg/LocationSvg';
import { CloseCircleFilledSvg } from '../../resources/svg/CloseSvg';
import { FlightInfoStore } from '../../store/FlightInfoStore';
import { PassengerSvg } from '../../resources/svg/PassengerSvg';
import { CabinSvg } from '../../resources/svg/CabinSvg';
import { TiLocation } from 'react-icons/ti';
import { AirportSvg } from '../../resources/svg/AirportSvg';
import { ChevronDownV2 } from '../../resources/svg/ChevronDownV2';
import { Passengers } from '../../components/Passengers';
import { PassengersStore } from '../../store/PassengersStore';
import { AsyncTypestore } from '../../store/AsyncTypestore';
import { FlightDates } from '../../store/FlightDatesStore';
import { FlightCity } from '../../store/FlightCityStore';
import colors from '../../resources/css/_variables.module.scss';
import { AirplaneUpRightSvg } from '../../resources/svg/AirplanesSvg';
import { ArrowRightSvg } from '../../resources/svg/ArrowSvg';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { AdultV2Svg } from '../../resources/svg/AdultSvg';
import { UserSvg } from '../../resources/svg/UserSvg';
import { ChildSvg, ChildV2Svg } from '../../resources/svg/ChildSvg';
import { InfantV2Svg } from '../../resources/svg/InfantSvg';
const { primary, primaryLightActive, blackNormal } = colors;

export const FlightTabIndexWTP = () => {
  const { setFlightInfo, setShowFlightInfoModal, setShowReturnFlights, setShowReturnFlightsInfo } = FlightInfoStore();
  const PassengerStore = PassengersStore();
  const passengerTypes = PassengerStore.passengers;
  const { airportLocations } = SearchPageHook();
  const navigate = useNavigate();
  const fromRef = useRef<any>(null);
  const toRef = useRef<any>(null);
  //eslint-disable-next-line
  const [showFlightMenuOptions, setShowFlightMenuOptions] = useState(false);
  const [showCabinClassOptions, setShowCabinClassOptions] = useState(false);
  const [showPassengerOptions, setShowPassengerOptions] = useState(false);
  const [showDateRange, setShowDateRange] = useState(false);

  const [showDepartingCalendar, setShowDepartingCalendar] = useState(false);
  const [showReturnCalendar, setShowReturnCalendar] = useState(false);
  const [activeFlightSwitch, setActiveFlightSwitch] = useState('round-trip');
  const currentDate = moment(new Date()).format('MMM DD, YYYY');
  //eslint-disable-next-line
  const [defaultDepartureDate, setDefaultDepartureDate] = useState(currentDate);
  const [defaultReturnDate, setDefaultReturnDate] = useState('');

  const [isDepartureDateActive, setIsDepartureDateActive] = useState(false);
  const [isReturnDateActive, setIsReturnDateActive] = useState(false);
  const [isPassengerActive, setIsPassengerActive] = useState(false);
  //eslint-disable-next-line
  const [isPassengerChanged, setIsPassengerChanged] = useState(false);
  const [isCabinClassActive, setIsCabinClassActive] = useState(false);
  const [isCabinClassChanged, setIsCabinClassChanged] = useState(false);
  const [cabinLabel, setCabinLabel] = useState('Economy');

  // form defaultValues

  const { flyingFrom, setFlyingFrom, flyingTo, setFlyingTo, setFromDetails, setToDetails, fromDetails, toDetails, setIsFromActive, setIsToActive, isFromActive, isToActive } = AsyncTypestore();
  const { departingDate, setDepartingDate, returningDate, setReturningDate } = FlightDates();
  const { setSelectedToCityName, setSelectedFromCityName } = FlightCity();

  // const [departingDate, setDeparting] = useState('');
  // const [returnDate, setReturnDate] = useState('');
  //eslint-disable-next-line
  const [adults, setAdults] = useState(1);
  const [cabin, setCabin] = useState('economy');

  const handleClosePopups = (param: boolean) => {
    setShowDepartingCalendar(param);
    setShowReturnCalendar(param);
    setShowPassengerOptions(param);
    setShowCabinClassOptions(param);
  };

  // eslint-disable-next-line
  const [loadingAirportList, setLoadingAirportList] = useState(false);
  const [FromAirportList, setFromAirportList] = useState<{ [index: string]: any }>();
  const [FromMappedFlights, setFromMappedFlights] = useState<any>();

  useEffect(() => {
    if (FromAirportList && Array.isArray(FromAirportList.tag)) {
      const _country: string[] = [];
      const foundAirports = FromAirportList.tag;

      if (Array.isArray(foundAirports)) {
        //* Collect unique country locations
        foundAirports.forEach((airport: { location: string }) => {
          if (airport && airport.location && !_country.includes(airport.location)) {
            _country.push(airport.location);
          }
        });

        const _FromMappedAirports: { [index: string]: any } = [];
        let _index = 0;

        //* Map country locations to airport details
        _country.forEach((_airport) => {
          foundAirports.forEach((airport: { location: string; airportCode: string; airportName: string; cityName: string; countryCode: string; airports: any[] }) => {
            if (_airport === airport.location) {
              //* Include main airport details (airportCode and airportName)
              _FromMappedAirports[_index] = {
                index: _index,
                name: airport.location,
                code: airport.airportCode,
                isCountry: true,
                details: null,
              };
              _index++;

              airport.airports.forEach((detail: any) => {
                _FromMappedAirports[_index] = {
                  index: _index,
                  name: airport.cityName,
                  code: airport.countryCode,
                  isCountry: false,
                  details: detail,
                };
                _index++;
              });
            }
          });
        });

        //* Set the mapped flights to state
        setFromMappedFlights(_FromMappedAirports);
      } else {
        console.error('FromAirportList.tag is not an array:', foundAirports);
      }
    }
  }, [FromAirportList]);

  // eslint-disable-next-line
  const [ToAirportList, setToAirportList] = useState<{ [index: string]: any }>();
  const [ToMappedFlights, setToMappedFlights] = useState<any>();

  useEffect(() => {
    if (ToAirportList && Array.isArray(ToAirportList.tag)) {
      const _country: string[] = [];
      const foundAirports = ToAirportList.tag;

      if (Array.isArray(foundAirports)) {
        foundAirports.forEach((airport: { location: string }) => {
          if (airport && airport.location && !_country.includes(airport.location)) {
            _country.push(airport.location);
          }
        });

        const _ToMappedAirports: { [index: string]: any } = [];
        let _index = 0;

        _country.forEach((_airport) => {
          foundAirports.forEach((airport: { location: string; airportCode: string; airportName: string; cityName: string; countryCode: string; airports: any[] }) => {
            if (_airport === airport.location) {
              _ToMappedAirports[_index] = {
                index: _index,
                name: airport.location,
                code: airport.airportCode,
                isCountry: true,
                details: null,
              };
              _index++;

              airport.airports.forEach((detail: any) => {
                _ToMappedAirports[_index] = {
                  index: _index,
                  name: airport.cityName,
                  code: airport.countryCode,
                  isCountry: false,
                  details: detail,
                };
                _index++;
              });
            }
          });
        });

        setToMappedFlights(_ToMappedAirports);
      } else {
        console.error('ToAirportList.tag is not an array:', foundAirports);
      }
    }
  }, [ToAirportList]);

  const switchDestination = () => {
    let from = flyingFrom;
    let to = flyingTo;
    setFlyingFrom(to);
    setFlyingTo(from);

    let fromDets = fromDetails;
    let toDets = toDetails;
    setFromDetails(toDets);
    setToDetails(fromDets);

    let fromRefState = fromRef.current.state.selected;
    let toRefState = toRef.current.state.selected;
    fromRef.current.state.selected = toRefState;
    toRef.current.state.selected = fromRefState;
  };

  const [disabledButtonSearch, setDisabledButtonSearch] = useState(true);
  useEffect(() => {
    setDisabledButtonSearch(true);
    if (activeFlightSwitch === 'one-way') {
      if (flyingFrom && flyingTo && (departingDate || defaultDepartureDate) && adults && cabin) {
        setDisabledButtonSearch(false);
      }
    }
    if (activeFlightSwitch === 'round-trip') {
      if (flyingFrom && flyingTo && (departingDate || defaultDepartureDate) && (returningDate || defaultReturnDate) && adults && cabin) {
        setDisabledButtonSearch(false);
      }
    }

    // For onFocus  state
    fromRef.current.inputNode.style.borderColor = '';
    if (flyingFrom) {
      fromRef.current.inputNode.style.borderColor = primaryLightActive;
    }
    toRef.current.inputNode.style.borderColor = '';
    if (flyingTo) {
      toRef.current.inputNode.style.borderColor = primaryLightActive;
    }
    // eslint-disable-next-line
  }, [flyingFrom, flyingTo, departingDate, returningDate, cabin, activeFlightSwitch]);

  const handleLabelChange = (e: any) => {
    if (e.isCountry) {
      return `${e.name}`;
    } else {
      return `${e.details?.airportName}`;
    }
  };

  const handleClick = (e: any) => {
    if (disabledButtonSearch) {
      let alertMessage = '';
      if (!flyingFrom && !flyingTo) {
        alertMessage = 'Please select departure and arrival airport';
      } else if (!flyingFrom) {
        alertMessage = 'Please select departure airport';
      } else if (!flyingTo) {
        alertMessage = 'Please select arrival airport';
      }

      if (alertMessage) {
        alert(alertMessage);
        e.stopPropagation();
      }
    }
  };

  useEffect(() => {
    setDefaultReturnDate(moment(defaultDepartureDate).add(1, 'days').format('MMM DD, YYYY'));
    if (departingDate !== '') {
      setDefaultReturnDate(moment(departingDate).add(1, 'days').format('MMM DD, YYYY'));
    }

    if (returningDate !== '') {
      if (moment(departingDate).isSameOrAfter(moment(returningDate ? returningDate : defaultReturnDate))) {
        setReturningDate(moment(departingDate).add(1, 'days').format('MMM DD, YYYY'));
      }
    }
    // eslint-disable-next-line
  }, [departingDate]);

  // For showing and hiding options
  const handleCabinClassClickOutside = (event: Event) => {
    if (event instanceof MouseEvent && event.target instanceof Element && !event.target.closest('.cabin-class')) {
      setShowCabinClassOptions(false);
    }
  };

  const handleFlightMenuClickOutside = (event: Event) => {
    if (event instanceof MouseEvent && event.target instanceof Element && !event.target.closest('.flight-type')) {
      setShowFlightMenuOptions(false);
    }
  };

  const handlePassengersClickOutside = (event: Event) => {
    if (event instanceof MouseEvent && event.target instanceof Element && !event.target.closest('.passenger-type')) {
      setShowPassengerOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleCabinClassClickOutside as EventListener);
    document.addEventListener('click', handleFlightMenuClickOutside as EventListener);
    document.addEventListener('click', handlePassengersClickOutside as EventListener);
    return () => {
      document.removeEventListener('click', handleCabinClassClickOutside as EventListener);
      document.removeEventListener('click', handleFlightMenuClickOutside as EventListener);
      document.removeEventListener('click', handlePassengersClickOutside as EventListener);
    };
  }, []);

  const [state, setState] = useState([
    {
      startDate: moment().toDate(), // Today's date
      endDate: moment().add(1, 'days').toDate(), // Tomorrow's date
      key: 'selection',
    },
  ]);

  //for initial value only, temporarily
  const [dates, setDates] = useState({
    departure: moment().format('MMM D, YYYY'), // Today's date
    arrival: moment().add(1, 'days').format('MMM D, YYYY'), // Tomorrow's date
  });

  return (
    <>
      <div className="tab-pane fade show active" id="flight-tab-pane" role="tabpanel" aria-labelledby="flight-tab">
        <div className=" py-[16px] px-[34px]">
          <div className="mb-4 row">
            <div className="col-lg-auto">
              <div className="btn-group w-100" id="flights-switch">
                <a
                  href="/#"
                  onClick={(e: ReactMouseEvent<HTMLAnchorElement>) => {
                    e.preventDefault();
                    setActiveFlightSwitch('one-way');
                  }}
                  className={activeFlightSwitch === 'one-way' ? `btn btn-primary btn-sm text-white no-link-style` : `btn btn-outline-primary btn-sm`}
                  style={{ textDecoration: 'none', color: primary }}
                >
                  One Way
                </a>
                <a
                  href="/#"
                  onClick={(e: ReactMouseEvent<HTMLAnchorElement>) => {
                    e.preventDefault();
                    setActiveFlightSwitch('round-trip');
                  }}
                  className={activeFlightSwitch === 'round-trip' ? `btn btn-primary btn-sm text-white no-link-style` : `btn btn-outline-primary btn-sm`}
                  style={{ textDecoration: 'none', color: primary }}
                >
                  Round-Trip
                </a>
                {/* <a href="#" onClick={() => setActiveFlightSwitch('multi-city')} className={activeFlightSwitch === 'multi-city' ? `btn btn-primaryLiLightActive btn-sm` : `btn btn-outline-primaryLiLightActive btn-sm`}>
                  Multi-City
                </a> */}
              </div>
            </div>
          </div>
          <div className="top-menu">
            {/* Flight Type  */}
            <div className="menu flight-type position-relative " onClick={() => setShowFlightMenuOptions(!showFlightMenuOptions)}>
              <div className="flex gap-[8px] items-center content-center">
                <AirplaneUpRightSvg _color={primary} />
                <p>{activeFlightSwitch === 'one-way' ? 'One Way' : 'Round Trip'}</p>
                <div className={`caret ${showFlightMenuOptions ? 'rotate' : ''}`}>
                  <ChevronDownV2 _color="#ADADAD" />
                </div>
              </div>
              <div className={`menu-options ${!showFlightMenuOptions ? 'hide' : ''}`} onMouseLeave={() => setShowFlightMenuOptions(false)}>
                <ul>
                  <li onClick={() => setActiveFlightSwitch('one-way')}>One Way</li>
                  <li onClick={() => setActiveFlightSwitch('round-trip')}>Round Trip</li>
                </ul>
              </div>
            </div>

            {/* Cabin Class  */}
            <div className="menu cabin-class position-relative " onClick={() => setShowCabinClassOptions(!showCabinClassOptions)}>
              <div className="flex gap-[8px] items-center content-center">
                <CabinSvg _color={primary} />
                <p>{cabinLabel}</p>
                <div className={`caret ${showCabinClassOptions ? 'rotate' : ''}`}>
                  <ChevronDownV2 _color="#ADADAD" />
                </div>
              </div>
              <div className={`menu-options ${!showCabinClassOptions ? 'hide' : ''}`} onMouseLeave={() => setShowCabinClassOptions(false)}>
                <ul>
                  <li
                    onClick={() => {
                      setCabin('economy');
                      setCabinLabel('Economy');
                    }}
                  >
                    Economy
                  </li>
                  <li
                    onClick={() => {
                      setCabin('business');
                      setCabinLabel('Business');
                    }}
                  >
                    Business
                  </li>
                  <li
                    onClick={() => {
                      setCabin('premiumEconomy');
                      setCabinLabel('Premium Economy');
                    }}
                  >
                    Premium Economy
                  </li>

                  <li
                    onClick={() => {
                      setCabin('first');
                      setCabinLabel('First Class');
                    }}
                  >
                    First Class
                  </li>
                </ul>
              </div>
            </div>

            {/* Passenger Type  */}
            <div className="menu passenger-type position-relative " onClick={() => setShowPassengerOptions(true)}>
              <div className="flex gap-[8px] items-center content-center">
                <PassengerSvg _color={primary} />
                <p>{`${passengerTypes.adults} ${passengerTypes.adults > 1 ? 'Adults,' : 'Adult,'} ${passengerTypes.minors} ${passengerTypes.minors > 1 ? 'Minors,' : 'Minor,'} ${
                  passengerTypes.children
                } ${passengerTypes.children > 1 ? 'Children,' : 'Child,'} ${passengerTypes.infants} ${passengerTypes.infants > 1 ? 'Infants' : 'Infant'}`}</p>
                <div className={`caret ${showPassengerOptions ? 'rotate' : ''}`}>
                  <ChevronDownV2 _color="#ADADAD" />
                </div>
              </div>
              <div className={`menu-options-passenger ${!showPassengerOptions ? 'hide' : ''}  `}>
                <Passengers onMouseLeave={() => setShowPassengerOptions(false)} />
              </div>
            </div>
          </div>

          <div className="first-row mb-4  d-flex gap-[20px] items-center content-center w-full ">
            {/* Departure  */}
            <div className="max-w-[283px] w-full position-relative" id="airport-location-holder">
              <div>
                <label htmlFor="" className="form-label">
                  Departure
                </label>
                <span className="position-relative d-block">
                  <LocationSvg _color={flyingFrom || isFromActive ? primaryLightActive : '#A8A8A8'} className="location-svg position-absolute" style={{ zIndex: 1, left: '12px', top: '18px' }} />
                  {flyingFrom !== '' ? (
                    <CloseCircleFilledSvg
                      _color={primaryLightActive}
                      className="clear-text-svg"
                      onClick={() => {
                        setFlyingFrom('');
                        setFromDetails('');
                        // setAirportOptions([{}]);
                        fromRef.current.state.text = '';
                        fromRef.current.state.selected = [];
                        fromRef.current.inputNode.value = '';
                      }}
                    />
                  ) : null}
                  <AsyncTypeahead
                    ref={fromRef}
                    id="async-flying-from"
                    className="async-flight-locations position-relative"
                    isLoading={loadingAirportList}
                    delay={500}
                    onSearch={function (query: string): void {
                      airportLocations(query).then((response: { [index: string]: any }) => {
                        setFromAirportList(response);
                      });
                    }}
                    onChange={(e: { [index: string]: any }) => {
                      const airportCode = e[0]?.details?.airportCode ?? e[0]?.code;
                      const airportName = e[0]?.details?.airportName ?? e[0]?.name;
                      const cityName = e[0]?.details?.cityName ?? e[0]?.city;
                      setFlyingFrom(airportCode);
                      setFromDetails(airportName);
                      setSelectedFromCityName(cityName);
                      // setAirportOptions([{}]);
                    }}
                    onKeyDown={(e) => {
                      if (!/[a-zA-Z, ]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onFocus={() => {
                      handleClosePopups(false);
                      setIsFromActive(true);
                    }}
                    onBlur={() => setIsFromActive(false)}
                    labelKey={(e) => handleLabelChange(e)}
                    placeholder="Country, City or Airport"
                    options={FromMappedFlights}
                    minLength={1}
                    filterBy={() => true}
                    positionFixed={true}
                    renderMenuItemChildren={(e: any) => {
                      return (
                        <div
                          className={
                            !e?.isCountry
                              ? 'ps-4 d-flex flex-wrap align-items-start justify-between generalsans-medium w-100 max-w-100 '
                              : 'd-flex generalsans-medium w-full user-select-none pointer-events-none '
                          }
                          // style={{width: '100vw',minWidth: '500px', overflow: 'visible',  left: '0', right: '0'}}
                        >
                          <div className="pt-0.4 flex  gap-2 align-items-center justify-between">
                            {e?.isCountry ? <TiLocation /> : <AirportSvg />}
                            <span className="">{e.details?.airportName} </span>
                          </div>
                          {e?.isCountry && (
                            <div className="d-flex flex-column  justify-between w-full">
                              <div className="flex justify-between align-items-center">
                                <div className="flex">{e.name} </div>
                                <span className="">{e.isCountry && 'All Airports'}</span>
                              </div>
                            </div>
                          )}

                          <span>{e.details?.airportCode}</span>
                        </div>
                      );
                    }}
                  />
                </span>
              </div>

              <div className="svg-arrow-mobile">
                <div className="switch-mobile" onClick={() => switchDestination()}>
                  <SwitchArrowHorizontal />
                </div>
              </div>
            </div>
            {/* Switch Arrow  */}
            <div className=" svg-arrow">
              <div className="switch-arrow-svg mb-1 switch" onClick={() => switchDestination()}>
                <SwitchArrowSvg _color="#fff" />
              </div>
            </div>

            {/* Arrival  */}
            <div className="max-w-[283px] w-full" id="airport-location-holder">
              <div>
                <label htmlFor="" className="form-label">
                  Arrival
                </label>
                <span className="position-relative block">
                  <LocationSvg _color={flyingTo || isToActive ? primaryLightActive : '#A8A8A8'} className="location-svg position-absolute" style={{ zIndex: 1, left: '12px', top: '18px' }} />
                  {flyingTo !== '' ? (
                    <CloseCircleFilledSvg
                      _color={primaryLightActive}
                      className="clear-text-svg"
                      onClick={() => {
                        setFlyingTo('');
                        setToDetails('');
                        // setAirportOptions([{}]);
                        toRef.current.state.text = '';
                        toRef.current.state.selected = [];
                        toRef.current.inputNode.value = '';
                      }}
                    />
                  ) : null}
                  <AsyncTypeahead
                    ref={toRef}
                    id="async-flying-to"
                    className="async-flight-locations"
                    isLoading={loadingAirportList}
                    delay={500}
                    onSearch={function (query: string): void {
                      airportLocations(query).then((response: { [index: string]: any }) => {
                        setToAirportList(response);
                      });
                    }}
                    onChange={(e: { [index: string]: any }) => {
                      const airportCode = e[0]?.details?.airportCode ?? e[0]?.code;
                      const airportName = e[0]?.details?.airportName ?? e[0]?.name;
                      const cityName = e[0]?.details?.cityName ?? e[0]?.city;
                      setFlyingTo(airportCode);
                      setToDetails(airportName);
                      setSelectedToCityName(cityName);
                      // setAirportOptions([{}]);
                    }}
                    onKeyDown={(e) => {
                      if (!/[a-zA-Z, ]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onFocus={() => {
                      handleClosePopups(false);
                      setIsToActive(true);
                    }}
                    onBlur={() => setIsToActive(false)}
                    labelKey={(e) => handleLabelChange(e)}
                    placeholder="Country, City or Airport"
                    options={ToMappedFlights}
                    minLength={1}
                    filterBy={() => true}
                    renderMenuItemChildren={(e: any) => {
                      return (
                        <div
                          className={
                            !e?.isCountry
                              ? 'ps-4 d-flex flex-wrap align-items-start justify-between generalsans-medium w-100 max-w-100'
                              : 'd-flex generalsans-medium w-full user-select-none pointer-events-none '
                          }
                        >
                          <div className="pt-0.4 flex  gap-2 align-items-center justify-between">
                            {e?.isCountry ? <TiLocation /> : <AirportSvg />}
                            <span className="">{e.details?.airportName} </span>
                          </div>
                          {e?.isCountry && (
                            <div className="d-flex flex-column  justify-between w-full">
                              <div className="flex justify-between align-items-center">
                                <div className="flex">{e.name} </div>
                                <span className="">{e.isCountry && 'All Airports'}</span>
                              </div>
                            </div>
                          )}

                          <span>{e.details?.airportCode}</span>
                        </div>
                      );
                    }}
                  />
                </span>
              </div>
            </div>

            {/* Date  */}
            {activeFlightSwitch === 'one-way' && (
              <div className="max-w-[283px] w-full">
                <FloatingIconFormControl
                  onClick={(e: any) => {
                    setShowDepartingCalendar(true);
                    setShowReturnCalendar(false);
                    setShowPassengerOptions(false);
                    setShowCabinClassOptions(false);
                  }}
                  value={!departingDate ? defaultDepartureDate : departingDate}
                  onFocus={() => setIsDepartureDateActive(true)}
                  onBlur={() => setIsDepartureDateActive(false)}
                  readOnly={true}
                  onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    e.preventDefault();
                    return false;
                  }}
                  type={'text'}
                  label={'Date'}
                  icon={<CalendarSvg _color={(flyingFrom && flyingTo) || departingDate || isDepartureDateActive ? primaryLightActive : '#A8A8A8'} />}
                  placeholder={departingDate}
                  autoComplete="off"
                  style={{
                    border: (flyingFrom && flyingTo) || departingDate || isDepartureDateActive ? `2px solid ${primaryLightActive}` : '',
                    cursor: 'pointer',
                  }}
                />
                {showDepartingCalendar && (
                  <div className="flight-calendar-holder " onMouseLeave={() => setShowDepartingCalendar(false)}>
                    <Calendar
                      onChange={(e: any) => {
                        setShowDepartingCalendar(false);
                        setDepartingDate(moment(e).format('MMM DD, YYYY'));
                      }}
                      defaultValue={departingDate ? new Date(departingDate) : new Date(defaultDepartureDate)}
                      minDate={new Date()}
                      maxDate={new Date(moment().add(1, 'year').format('YYYY-MM-DD'))}
                    />
                  </div>
                )}
              </div>
            )}

            {/* Dates  */}
            {activeFlightSwitch === 'round-trip' && (
              <div className="max-w-[283px] w-full" onClick={() => setIsReturnDateActive(true)}>
                {/* <div className="flex flex-col"> */}
                <p className="date-range-label">Dates</p>
                <div
                  className={`date-input border-2 border-[${isReturnDateActive || (departingDate && returningDate) || (flyingFrom && flyingTo) ? primaryLightActive : '#DCE0E4'}]`}
                  onClick={() => {
                    setShowDateRange((prev) => !prev);
                  }}
                >
                  <span className="date-field">
                    <CalendarSvg _width={12} _height={12} _color={`${isReturnDateActive || (departingDate && returningDate) || (flyingFrom && flyingTo) ? primaryLightActive : '#D9D9D9'}`} />
                    <span className="date flex flex-col gap-[1px]">
                      <p className="date-label">Departure</p>
                      <p className="date-value">{departingDate ? moment(new Date(departingDate)).format('MMM DD, YYYY') : moment(new Date(defaultDepartureDate)).format('MMM DD, YYYY')}</p>
                    </span>
                  </span>

                  <ArrowRightSvg _width={16} _height={14} _color="#ADADAD" />

                  <span className="date-field">
                    <CalendarSvg _width={12} _height={12} _color={`${isReturnDateActive || (departingDate && returningDate) || (flyingFrom && flyingTo) ? primaryLightActive : '#D9D9D9'}`} />
                    <span className="date flex flex-col gap-[3px]">
                      <p className="date-label">Arrival</p>
                      <p className="date-value">{returningDate ? moment(new Date(returningDate)).format('MMM DD, YYYY') : moment(new Date(defaultReturnDate)).format('MMM DD, YYYY')}</p>
                    </span>
                  </span>
                </div>

                {showDateRange && (
                  <div
                    className="date-range-calendar"
                    onMouseLeave={() => {
                      setShowDateRange(false);
                      setIsReturnDateActive(false);
                    }}
                  >
                    <DateRange
                      editableDateInputs={true}
                      onChange={(item: any) => {
                        setState([item.selection]);

                        setDepartingDate(moment(item.selection.startDate).format('MMM DD, YYYY'));
                        setReturningDate(moment(item.selection.endDate).format('MMM DD, YYYY'));
                      }}
                      moveRangeOnFirstSelection={true}
                      ranges={state}
                      months={2}
                      direction="horizontal"
                      shownDate={new Date()}
                      rangeColors={[primary]}
                      color={primary}
                    />
                  </div>
                )}
                {/* </div> */}
              </div>
            )}

            {/* Mobile Responsive for Row 1 (Cabin Class, Flight Type and Passenger Details) */}
            <div className="mobile-last-row">
              {/* Cabin Class  */}
              <div className="cabin-class" onClick={() => setShowCabinClassOptions(!showCabinClassOptions)}>
                <div className="flex gap-[8px] items-center content-center">
                  <CabinSvg _color={"#C0C0C0"} />
                  <p>{cabinLabel}</p>
                  <div className={`caret ${showCabinClassOptions ? 'rotate' : ''}`}>
                    <ChevronDownV2 _color="#ADADAD" />
                  </div>
                </div>
                
              </div>
              <div className="mobile-passengers">
                <AdultV2Svg />
                {passengerTypes.adults}
                <ChildSvg _color="#C0C0C0" />
                {passengerTypes.minors}
                <ChildV2Svg />
                {passengerTypes.children}
                <InfantV2Svg />
                {passengerTypes.infants}
              </div>
            </div>
            {/* Search Button  */}
            <div onClick={handleClick} className="search-btn">
              <GalagoButton
                btn="primary"
                className={`search-flight-button ${disabledButtonSearch && 'button-disabled'}`}
                disabled={disabledButtonSearch}
                icon={
                  <span className="me-2 mb-1">
                    <SearchSvg _color="#fff" />
                  </span>
                }
                label={'SEARCH'}
                onClick={() => {
                  if (flyingFrom === flyingTo) {
                    alert('Please make sure your departure and arrival are different.');
                    setFlyingFrom('');
                    setFlyingTo('');
                  } else {
                    let finalReturnDate = '';
                    activeFlightSwitch === 'one-way'
                      ? (finalReturnDate = '')
                      : (finalReturnDate = `${returningDate ? moment(returningDate).format('YYYY/MM/DD') : moment(defaultReturnDate).format('YYYY/MM/DD')}`);

                    const urlGetParams = `?originCity=${fromDetails}&destinationCity=${toDetails}&originCode=${flyingFrom}&airportCode=${flyingFrom}&destinationCode=${flyingTo}&departureDate=${
                      departingDate ? moment(departingDate).format('YYYY/MM/DD') : moment(defaultDepartureDate).format('YYYY/MM/DD')
                    }&returnDate=${finalReturnDate}&class=${cabin}&adults=${passengerTypes.adults}&minors=${passengerTypes.minors}&children=${passengerTypes.children}&infants=${
                      passengerTypes.infants
                    }`;
                    let url = `/flights/one-way${urlGetParams}`;
                    if (activeFlightSwitch === 'round-trip') url = `/flights/round-trip${urlGetParams}`;
                    // return;
                    setFlightInfo({});
                    setShowFlightInfoModal(false);
                    setShowReturnFlights(false);
                    setShowReturnFlightsInfo([]);
                    navigate(url);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
