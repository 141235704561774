import NavBarTransparent from "../../../components/NavBarTransparent";
import { ChevronRightSvg } from "../../../resources/svg/ChevronRightSvg";
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import { LinkedInSvg } from '../../../resources/svg/LinkedinSvg';
import {TwitterSvg} from '../../../resources/svg/TwitterSvg';
import {LinkSvg} from '../../../resources/svg/LinkSvg';
import React from "react";
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';

const BoracayHotelGuide = () =>{
 const [fbHover, setFbHover] = React.useState('#ADADAD');
 const [liHover, setLiHover] = React.useState('#ADADAD');
 const [twtHover, setTwtHover] = React.useState('#ADADAD');
 const [linkHover, setLinkHover] = React.useState('#ADADAD');

 return (
   <>
     <NavBarTransparent />
     <div className="hero_sec">
       <img
         src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Boracay+Hotel+Guide+-+Where+to+Stay/Blog+Header.jpg"
         alt=""
         loading="lazy"
         onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
       />
       <div className="gradient_overlay">
         <h1>Top Destination</h1>

         <div className="bread_crumbs">
           <a href="/#/blogs">Blogs</a>
           <ChevronRightSvg _color="#ffffff" _width={20} _height={20} />
           <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
             Boracay Hotel Guide: Where to Stay
           </a>
         </div>
       </div>
     </div>

     <div className="articleBody">
       <div className="articleHeader">
         <h1>Boracay Hotel Guide: Where to Stay</h1>

         <div className="info">
           <p className="authorSec">
             {' '}
             By <u>Zoe Alcazaren</u>
           </p>
           <p className="separator">•</p>
           <p className="articleDate">Nov 11, 2023</p>
           <p className="separator">•</p>
           <p className="article-read">8 min read</p>
         </div>

         <div className="shareBtnContainer">
           <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setFbHover('#006FA9')} onMouseLeave={() => setFbHover('#ADADAD')}>
             <FacebookSvg _color={fbHover} _width={24} _height={24} />
           </a>

           <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLiHover('#006FA9')} onMouseLeave={() => setLiHover('#ADADAD')}>
             <LinkedInSvg _color={liHover} _width={24} _height={24} />
           </a>

           <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setTwtHover('#006FA9')} onMouseLeave={() => setTwtHover('#ADADAD')}>
             <TwitterSvg _color={twtHover} _width={24} _height={24} />
           </a>

           <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLinkHover('#006FA9')} onMouseLeave={() => setLinkHover('#ADADAD')}>
             <LinkSvg _color={linkHover} _width={24} _height={24} />
           </a>
         </div>
       </div>

       <div className="content">
         <div className="articleDescription">
           <p>
             Home to the Philippines’ most scenic beaches, Boracay is the go-to tropical paradise for locals and tourists alike. Its weather is forgiving year-round, and you’ll have no shortage of
             activities to enjoy alone, with your significant other, with your family, and with friends.{' '}
           </p>
           <p>
             However, choosing among the over 400 hotels Boracay offers can take time and effort. We’ve compiled a comprehensive list of the best resorts to book for the best possible Boracay
             experience.
           </p>
         </div>

         <div className="img_container">
           <img
             src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Boracay+Hotel+Guide+-+Where+to+Stay/01+Tides+Boracay.png"
             alt=""
             loading="lazy"
             onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
           />
           <p>Photo by The Tides Boracay</p>
         </div>

         <div className="extensive_story">
           <h2>When is the Best Time to Go to Boracay?</h2>
           <p>
             Typically, the best time to travel to Boracay is between March and May. According to the Philippine Atmospheric, Geophysical, and Astronomical Services Administration (PAGASA), the wet
             season occurs from June to November, during which you can expect less sun when you visit.
           </p>
           <p>
             Still, traveling during the rainy season has advantages—you’ll likely run into fewer crowds and enjoy cheaper fares for flights and hotels. Generally, the average high in Boracay is 30
             degrees, with a rare low of 25 degrees.{' '}
           </p>
         </div>

         <div className="storieswithTitleImageParagraphs">
           <div className="head">
             <h2>Top Best Hotels in Boracay</h2>
             <div className="head_desc">
               <p>With your flight to Boracay booked, it’s time to scour the island for its best hotels and resorts. Here are our top picks for the best hotels in Boracay.</p>
             </div>
           </div>

           <div className="story">
             <div className="head">
               <h2>The Tides</h2>
               <p>D’Mall Station 2</p>
             </div>

             <div className="info-grp">
               <p>
                 <a href="https://tidesboracay.com/" target="_blank" rel="noreferrer">
                   The Tides Hotel Boracay
                 </a>{' '}
                 is about as luxurious as you can get. It’s located in the heart of D’Mall, offering complete convenience and class. It’s just a two-minute walk from White Beach and has in-house
                 amenities like a rooftop pool and bar. When at The Tides, being on holiday doesn’t mean neglecting your fitness. The resort houses FIT Boracay, a world-class gym with advanced
                 machinery like ellipticals, smith machines, bikes, treadmills, and multi-press setups. You’ll forget you’re not in the city!
               </p>

               <p>
                 Also home to The Pelican Sky Bar and Restaurant, The Tides has its fair share of international and local delicacies so delicious you’ll find yourself booking a reservation every
                 night. After a long day of beach activities, the Surrender Day Spa will sound all too welcoming, offering Swedish and Shiatsu massages, luxurious foot masks, and manicure and pedicure
                 services.
               </p>
             </div>
           </div>

           <div className="story">
             <div className="head">
               <h2>Patio Pacific</h2>
               <p>Station 1 </p>
             </div>

             <div className="info-grp">
               <p>
                 A three-minute walk to the beach is{' '}
                 <a href="https://patiopacificboracay.com/" target="_blank" rel="noreferrer">
                   Patio Pacific
                 </a>
                 , an idyllic resort offering 64 rooms and suites. It holds Triple AAA resort accreditation from the Philippine Department of Tourism and holds a TripAdvisor Certificate of Excellence.
                 Patio’s in-house restaurant is the Garden Cafe, which serves Filipino classics like adobo and an upscale version of pinakbet.
               </p>

               <p>
                 Its rooms are elegantly decorated and contemporary, equipped with all the in-house services and equipment you need. While modest, its gym is perfect for keeping in shape while away
                 from home.
               </p>
             </div>
           </div>

           <div className="story">
             <div className="head">
               <h2>Astoria Current</h2>
               <p>Station 3 </p>

               <div className="img_container">
                 <img
                   src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Boracay+Hotel+Guide+-+Where+to+Stay/02+Photo+by+Astoria+Current.png"
                   alt=""
                   loading="lazy"
                   onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                 />
                 <p>Photo by Astoria Current</p>
               </div>
             </div>

             <div className="info-grp">
               <p>
                 A three-minute walk to the beach is{' '}
                 <a href="https://patiopacificboracay.com/" target="_blank" rel="noreferrer">
                   Patio Pacific
                 </a>
                 , an idyllic resort offering 64 rooms and suites. It holds Triple AAA resort accreditation from the Philippine Department of Tourism and holds a TripAdvisor Certificate of Excellence.
                 Patio’s in-house restaurant is the Garden Cafe, which serves Filipino classics like adobo and an upscale version of pinakbet.
               </p>

               <p>
                 Its rooms are elegantly decorated and contemporary, equipped with all the in-house services and equipment you need. While modest, its gym is perfect for keeping in shape while away
                 from home.
               </p>
             </div>
           </div>

           <div className="story">
             <div className="head">
               <h2>Mandarin Nest</h2>
               <p>D’Mall Station 2</p>
             </div>

             <div className="info-grp">
               <p>
                 Casual yet classy, the Mandarin Nest is a brand-new 4-star resort just an 11-minute walk away from Bulabog Beach and a 25-minute walk from Cagban Beach. Start your day at the Hakuna
                 Matata restaurant, which serves a buffet breakfast and a la carte meals. Unique to the Mandarin Nest is an expansive sun terrace for guests who want to get that perfect,
                 Instagrammable tan.
               </p>

               <p> Its modern rooms are equipped with free WiFi, 24-hour room service, and free breakfast in bed! </p>
             </div>
           </div>

           <div className="story">
             <div className="head">
               <h2>Coast Boracay</h2>
               <p>D’Mall Station 2</p>
             </div>

             <div className="info-grp">
               <p>
                 <a href="https://www.coastboracay.net/" target="_blank" rel="noreferrer">
                   Coast Boracay
                 </a>{' '}
                 is a 71-room beachfront resort styled with accent pieces by Mindanaoan weavers. The building is elegantly architectured, with vibrant and creative pieces plastering its walls.
                 Families traveling with younger children and game enthusiasts will love the resort’s addition of a game room, open daily from 9 am to 9 pm.
               </p>

               <p>
                 The hotel’s restaurant, Cha-Cha’s Beach Cafe, serves a breakfast buffet and transforms into a happy hour destination with an in-house DJ. After an afternoon of beach activities or a
                 night of drinking, rejuvenate yourself at the Anahata Wellness Spa, which offers paraffin treatments, aromatherapy massages, Filipino traditional hilot, and body scrubs.
               </p>

               <p> Top off your trip with one-of-a-kind pasalubong from The General Store, which stocks homegrown and artisan products.</p>
             </div>
           </div>

           <div className="story">
             <div className="head">
               <h2>Mövenpick Resort & Spa</h2>
               <p>Punta Bunga Road</p>

               <div className="img_container">
                 <img
                   src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Boracay+Hotel+Guide+-+Where+to+Stay/03+Photo+by+Movenpick+Boracay.png"
                   alt=""
                   loading="lazy"
                   onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                 />
                 <p>Photo by Movenpick Boracay</p>
               </div>
             </div>

             <div className="info-grp">
               <p>
                 Enveloped by palm trees and powdery white sand is the{' '}
                 <a href="https://movenpick.accor.com/" target="_blank" rel="noreferrer">
                   Movenpick Resort and Spa
                 </a>
                 , just 900 meters from Balinghai Beach and Diniwid Beach. This massive luxury resort has 312 modern guest rooms and a multi-level pool with spectacular sea views. The Breza restaurant
                 serves Boracay’s best brick-oven pizza, whereas the Sol Marina Beach Club provides a ritzy outdoor setting for lunch and dinner-goers who prefer to eat by the beach. Unique to
                 Movenpick is its designated Chocolate Hour, during which guests can enjoy a daily complimentary chocolate indulgence!
               </p>

               <p>The best way to wind down at the Movenpick is with a massage at the Sagay Spa. You’ll feel rejuvenated after a much-needed hilot massage and signature Sagay Regenerating Ritual. </p>
             </div>
           </div>

           <div className="story">
             <div className="head">
               <h2>Crimson Resort & Spa</h2>
               <p>Brgy Yapak (Station Zero)</p>
             </div>

             <div className="info-grp">
               <p>
                 Situated in total exclusivity, the{' '}
                 <a href="https://www.crimsonhotel.com/boracay" target="_blank" rel="noreferrer">
                   Crimson Resort and Spa
                 </a>{' '}
                 is in Boracay’s elusive Station Zero, a private beach with the whitest sands and bluest waters. Crimson is a celebrity magnet for many reasons—it boasts a fitness center, speedboat
                 island cruise, semi-submarine boat, paddle boarding, kayaking, and all the activities you can imagine would make for the perfect tropical getaway.
               </p>

               <p>
                 The Crimson’s Saffron Cafe serves international cuisine, whereas you can get delectable wine pairings at the Mosaic Latin American Grill. There’s live music and a delicious selection
                 of Asian fusion dishes at the Azure Beach Club, where you can also sip on the resort’s endless cocktail menu. Another must-try at the Crimson is the Aum Spa, which boasts an extensive
                 therapy menu, including relaxing bath rituals, pre and post-natal nurturing, and hot stones.
               </p>

               <p>If you’re traveling with little ones, no worries! The Crimson has Crimzone, a dedicated space for children to play in an adult-free zone.</p>
             </div>
           </div>

           <div className="story">
             <div className="head">
               <h2>The Lind</h2>
               <p>Station 1</p>
             </div>

             <div className="info-grp">
               <p>
                 <a href="https://www.thelindhotels.com/" target="_blank" rel="noreferrer">
                   The Lind
                 </a>{' '}
                 is a 5-star tropical home away from home equipped with 119 guest rooms overlooking the pool, garden, and beach. Identifying as a coastal hotel, The Lind has everything a guest could
                 dream of—multi-level pools, a fitness center, a kids’ club, and a beautiful beach setup. The Lind is home to three dining experiences. Tartine features continental classics and a mix
                 of Asian flair. Named after Boracay’s area code is the +36 restaurant, ideal for midday snacks or a cup of coffee to start the day. Finally, there’s Crust, which serves rustic
                 brick-oven pizzas.
               </p>

               <p>
                 Whether traveling with a significant other for an anniversary, engagement, or wedding, The Lind offers unforgettable romantic moments. The ballroom provides breathtaking sea views—a
                 perfect backdrop for sealing the deal with your partner.
               </p>
             </div>
           </div>
         </div>

         <div className="extensive_story">
           <h2>Honorable Mentions</h2>
           <p>Considering the abovementioned resorts' popularity, you’ll often risk not making the cut. Check out our honorable mentions—they’re worth every peso!</p>

           <div className="bullet_grp">
             <div className="bullet_list">
               <ul>
                 <li>
                   <span>Mandarin Bay Resort and Spa: </span>New to Boracay’s roster of luxury hotels, this beachfront resort is just a 9-minute walk from D’Mall and boasts unique, lagoon-type
                   architecture that’ll make you feel like you’re in the Bahamas.
                 </li>
                 <li>
                   <span>Lime Hotel Boracay: </span> Set in the center of Boracay’s party hub, Lime Hotel Boracay provides the perfect accommodation for water sports lovers and nightlife enthusiasts.
                   It hosts events and parties perfect for extroverted young travelers.
                 </li>
               </ul>
             </div>
           </div>
         </div>

         <div className="footnote">
           <div className="title_container">
             <h2>The Bottom Line</h2>
           </div>

           <div className="info">
             <p>
               When in Boracay, the selection of stunning hotels and resorts is virtually endless. You’ll get your fair share of luxury, quirky, and modern accommodations chock-full of incredible
               facilities.
             </p>
             <p>
               If you’re already planning your ideal Boracay escape, book our GalaGO! promo for a 3-day, 2-night stay with free daily breakfast at your favorite Boracay resort. Email{' '}
               <a href="mailto:customersupport@galago.com.ph" target="_blank" rel="noreferrer">
                 customersupport@galago.com.ph
               </a>{' '}
               for more information.
             </p>
           </div>
         </div>
       </div>

       <hr />

       <div className="featured_blogs">
         <h2>Featured Blogs</h2>
         <div className="card_container">
           <div className="blog_card col-md-6 col-12">
             <div className="img_container">
               <img
                 src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg"
                 alt="Discover the Best Things to Do in Bohol All in One Tour!"
                 loading="lazy"
                 onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
               />

               <div className="badge">
                 <h2>Travel Tip</h2>
               </div>
             </div>

             <div className="info_grp">
               <div className="detail">
                 <p className="date"> September 25, 2023</p>
                 <p className="separator">·</p>
                 <p className="duration">6 min read</p>
               </div>

               <h3>Discover the Best Things to Do in Bohol All in One Tour!</h3>

               <a href="/#/discover-the-best-things-to-do-in-bohol-all-in-one-tour" className="read_article_btn">
                 <div className="read_btn">
                   <p>Read Article</p>
                   <ArrowRightSvg _color="#006FA9" _width={34} _height={14} />
                 </div>
               </a>
             </div>
           </div>

           <div className="blog_card col-md-6 col-12">
             <div className="img_container">
               <img
                 src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg"
                 alt="Guide to Travel Requirements in the Philippines"
                 loading="lazy"
                 onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
               />

               <div className="badge">
                 <h2>Travel Tip</h2>
               </div>
             </div>

             <div className="info_grp">
               <div className="detail">
                 <p className="date"> September 25, 2023</p>
                 <p className="separator">·</p>
                 <p className="duration">6 min read</p>
               </div>

               <h3>Guide to Travel Requirements in the Philippines</h3>

               <a href="/#/guide-to-travel-requirements-in-the-philippines" className="read_article_btn">
                 <div className="read_btn">
                   <p>Read Article</p>
                   <ArrowRightSvg _color="#006FA9" _width={34} _height={14} />
                 </div>
               </a>
             </div>
           </div>
         </div>
       </div>
     </div>
   </>
 );
};
export default BoracayHotelGuide;