import React from 'react';

interface SvgProps extends React.SVGAttributes<HTMLOrSVGElement> {
  _width?: number;
  _height?: number;
  _color?: string;
  props?: any;
}

export const ConfirmEmailSvg = ({ _width = 160, _height = 154, _color = '#006FA9', ...props }: SvgProps) => {
  return (
    <svg width={_width} height={_height} {...props} viewBox="0 0 160 154" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="85.4257" cy="67.7968" rx="67.7968" ry="67.7968" fill={_color} fill-opacity="0.04" />
      <ellipse cx="92.2031" cy="85.4253" rx="67.7968" ry="67.7968" fill={_color} fill-opacity="0.04" />
      <ellipse cx="67.7968" cy="85.4253" rx="67.7968" ry="67.7968" fill={_color} fill-opacity="0.04" />
      <path
        d="M85.4907 104.311H63.2684C61.795 104.311 60.3819 103.691 59.3401 102.588C58.2982 101.485 57.7129 99.9887 57.7129 98.4286V69.0167C57.7129 67.4566 58.2982 65.9604 59.3401 64.8572C60.3819 63.754 61.795 63.1343 63.2684 63.1343H102.157C103.631 63.1343 105.044 63.754 106.086 64.8572C107.128 65.9604 107.713 67.4566 107.713 69.0167V86.6638"
        stroke={_color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.7129 69.0166L82.7129 86.6638L107.713 69.0166M93.824 113.135L107.713 98.4286M107.713 98.4286V111.664M107.713 98.4286H95.2129"
        stroke={_color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
