import React from 'react';
import { SvgProps } from './SvgProps';

export const AdultSvg = ({ _width = 15, _height = 15, _color = '#333333' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 16 18" fill={_color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.54305 4.54212C3.54305 5.72423 4.01264 6.85792 4.84852 7.6938C5.6844 8.52967 6.81809 8.99926 8.0002 8.99926C9.1823 8.99926 10.316 8.52967 11.1519 7.6938C11.9877 6.85792 12.4573 5.72423 12.4573 4.54212C12.4573 3.36001 11.9877 2.22632 11.1519 1.39044C10.316 0.554567 9.1823 0.0849762 8.0002 0.0849762C6.81809 0.0849762 5.6844 0.554567 4.84852 1.39044C4.01264 2.22632 3.54305 3.36001 3.54305 4.54212ZM6.83368 11.5134L7.48136 12.5928L6.3218 16.9072L5.06823 11.7919C4.99859 11.5099 4.72698 11.3253 4.44493 11.3985C2.00743 12.0078 0.200195 14.2155 0.200195 16.8445C0.200195 17.4365 0.680731 17.9135 1.26921 17.9135H14.7312C15.3231 17.9135 15.8002 17.433 15.8002 16.8445C15.8002 14.2155 13.993 12.0078 11.5555 11.3985C11.2734 11.3288 11.0018 11.5134 10.9322 11.7919L9.67859 16.9072L8.51903 12.5928L9.16671 11.5134C9.38957 11.1408 9.12145 10.6707 8.68966 10.6707H7.31421C6.88243 10.6707 6.6143 11.1443 6.83716 11.5134H6.83368Z"
        fill={_color}
      />
    </svg>
  );
};

export const AdultV2Svg = ({ _width = 15, _height = 15, _color = '#333333' }: SvgProps) => {
  return (
    <svg width="6" height="14" viewBox="0 0 6 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.00001 0.333313C3.35363 0.333313 3.69277 0.473789 3.94281 0.723837C4.19286 0.973886 4.33334 1.31302 4.33334 1.66665C4.33334 2.02027 4.19286 2.35941 3.94281 2.60946C3.69277 2.8595 3.35363 2.99998 3.00001 2.99998C2.64638 2.99998 2.30724 2.8595 2.0572 2.60946C1.80715 2.35941 1.66667 2.02027 1.66667 1.66665C1.66667 1.31302 1.80715 0.973886 2.0572 0.723837C2.30724 0.473789 2.64638 0.333313 3.00001 0.333313ZM2.00001 3.66665H4.00001C4.35363 3.66665 4.69277 3.80712 4.94281 4.05717C5.19286 4.30722 5.33334 4.64636 5.33334 4.99998V7.99998C5.33334 8.36817 5.03486 8.66665 4.66667 8.66665H4.33334V13C4.33334 13.3682 4.03486 13.6666 3.66667 13.6666H2.33334C1.96515 13.6666 1.66667 13.3682 1.66667 13V8.66665H1.33334C0.965149 8.66665 0.666672 8.36817 0.666672 7.99998V4.99998C0.666672 4.64636 0.807148 4.30722 1.0572 4.05717C1.30724 3.80712 1.64638 3.66665 2.00001 3.66665Z"
        fill="#C0C0C0"
      />
    </svg>
  );
};
