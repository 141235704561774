import { SvgProps } from './SvgProps';

export const BaggageSvg = ({ _width = 12, _height = 13, _color = '#006FA9' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1538 4.49469H3.84615C3.35652 4.49469 2.88695 4.7054 2.54073 5.08046C2.1945 5.45553 2 5.96423 2 6.49465V11.9945C2 12.525 2.1945 13.0337 2.54073 13.4087C2.88695 13.7838 3.35652 13.9945 3.84615 13.9945H12.1538C12.6435 13.9945 13.1131 13.7838 13.4593 13.4087C13.8055 13.0337 14 12.525 14 11.9945V6.49465C14 5.96423 13.8055 5.45553 13.4593 5.08046C13.1131 4.7054 12.6435 4.49469 12.1538 4.49469Z"
        stroke="#006FA9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.23096 13.9945V4.49469" stroke="#006FA9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.769 13.9945V4.49469" stroke="#006FA9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.69238 4.49433C5.69238 3.83131 5.93551 3.19543 6.36829 2.7266C6.80107 2.25777 7.38804 1.99438 8.00007 1.99438C8.61211 1.99438 9.19908 2.25777 9.63186 2.7266C10.0646 3.19543 10.3078 3.83131 10.3078 4.49433"
        stroke="#006FA9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
