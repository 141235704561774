import React from 'react';
import { CircledCheck } from '../../../resources/svg/CircledCheckSvg';
// import { GalagoButton } from '../../../components/Button';

type AllHotelPhotosModalRightSidebarProps = { facilities: string[] };

const AllHotelPhotosModalRightSidebar = ({ facilities }: AllHotelPhotosModalRightSidebarProps) => {
  const mappedFacilities = facilities.slice(0, 13).map((facility, index) => {
    return (
      <div className="flex flex-row items-center justify-start gap-[0.25rem]" key={index}>
        <div className="fs-6 flex align-items-start gap-3">
          <CircledCheck _width={18} _height={18} _color="#006FA9" /> <span className="d-block text-sm font-medium">{facility}</span>
        </div>
      </div>
    );
  });

  return (
    <section className="absolute top-[7.5rem] left-[59rem] pr-6 flex flex-col items-start justify-start gap-[1.5rem] text-left text-[1.5rem] text-black font-callout">
      <div className="flex flex-col items-start justify-start">
        <div className="relative leading-[130%] font-semibold textColor">Facilities</div>
      </div>
      <div className="flex flex-col items-start justify-start text-[0.81rem] mb-2">
        <div className="flex flex-col items-start justify-end gap-[0.75rem] textColor">{mappedFacilities}</div>
      </div>
    </section>
  );
};

export default AllHotelPhotosModalRightSidebar;
