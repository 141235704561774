import React from 'react';
import { Modal } from 'react-bootstrap';
import '../../resources/css/email-password-modal.scss';
import { FloatingLabelFormControl } from '../../components/FormControl';
import { LoadingBouncingDots } from '../../components/Loading';
import { GalagoButton } from '../../components/Button';
import { checkEmail } from '../../utils/data.utils';
import { AuthenticationHooks } from '../../hooks/AuthenticationHooks';
import { PasswordDialogStore } from '../../store/PasswordDialogStore';
import { EmailDialogStore } from '../../store/EmailDialogStore';
import { PendingVerificationStore } from '../../store/PendingVerificationStore';
import { DialogBoxStore } from '../../store/DialogBoxStore';
import { HurtGallySvg } from '../../resources/svg/GallySvg';

interface emailDialogInterface {
  show: boolean;
  onHide: () => void;
}

export const EmailDialog = ({ show, onHide }: emailDialogInterface) => {
  // hooks
  const { verifyEmailHook } = AuthenticationHooks();
  const { setShowPasswordDialog } = PasswordDialogStore();
  const { setShowEmailDialog, setUserEmail, setShowEmailConfirmationDialog } = EmailDialogStore();
  const { setShowPendingDialog } = PendingVerificationStore();
  const { setShowDialog, setDialogMessage, setDialogSize } = DialogBoxStore();
  // states
  const [email, setEmail] = React.useState('');
  const [isValidEmail, setIsValidEmail] = React.useState(true);
  const [isEmptyEmail, setIsEmptyEmail] = React.useState<boolean | undefined>();
  const [disableEmailButton, setDisableEmailButton] = React.useState(false);

  const onClickVerifyEmail = async () => {
    setIsEmptyEmail(email.length === 0 ? true : false);
    if (email.length === 0) return;

    const _isValidEmail = checkEmail(email); // email validation returns true if the email is valid
    setIsValidEmail(_isValidEmail);
    if (!_isValidEmail) return;

    setIsValidEmail(true);
    setDisableEmailButton(true);

    // verify email API
    const response = await verifyEmailHook({ email: email, platform: 'web' });

    // if response is not successful
    if (response.status >= 500) {
      setDisableEmailButton(false);
      setShowDialog(true);
      setDialogSize('lg');
      setDialogMessage(
        <div className="d-flex flex-column align-items-center justify-center py-10">
          <HurtGallySvg _width={250} _height={250} />
          <p className="fw-medium fs-5 mb-1">Our bad! Something went wrong!</p>
          <p>Sit tight, we're working on it! Try refreshing this page</p>
          <div className="mt-3">
            <GalagoButton
              label={
                <>
                  <svg width="25" height="25" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.29603 4.61862C6.65853 4.51029 7.05853 4.43945 7.5002 4.43945C9.49603 4.43945 11.1127 6.05612 11.1127 8.05195C11.1127 10.0478 9.49603 11.6645 7.5002 11.6645C5.50436 11.6645 3.8877 10.0478 3.8877 8.05195C3.8877 7.31029 4.1127 6.61862 4.49603 6.04362"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path d="M5.7793 4.71829L6.98346 3.33496" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5.7793 4.71875L7.18346 5.74375" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  Refresh Page
                </>
              }
              onClick={() => window.location.reload()}
            />
          </div>
        </div>
      );
      return;
    }

    const { isEmailExists, details } = response.data.data;

    const isDetailsCompleted = validateUserDetails(details);
    setUserEmail(email);

    // if the user is new
    if (isEmailExists === false && details === null) {
      setShowEmailConfirmationDialog(true);
    }

    // if the email exists and the details is not yet filled then show password dialog
    if (isEmailExists === true && isDetailsCompleted === false && details?.isVerified === false) {
      // setShowEmailConfirmationDialog(true);
      // console.log(details);
      setShowPendingDialog(true);
    }

    // if the email exists and the details is filled
    if (isEmailExists === true && isDetailsCompleted === true && details?.isVerified === true) {
      setShowPasswordDialog(true);
    }

    // close email dialog
    setShowEmailDialog(false);
    setDisableEmailButton(false);
  };

  /**
   * validate the user details if the user completed his registration
   */
  const validateUserDetails = (details: null | { [index: string]: any }): boolean => {
    if (details === null) return false;
    if (details?.firstName !== null && details?.lastName !== null && details?.birthDate !== null) return true;
    return false;
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered animation={false}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="p-0">
        <div id="email-modal-header">
          <h4 style={{ fontSize: 22, fontWeight: '700' }}>What’s your email?</h4>
          <span style={{ fontSize: 14, fontWeight: '500' }}>Enter a valid email. No spam, just exciting travel experiences!</span>
        </div>
        <div id="email-modal-body">
          <FloatingLabelFormControl
            error={isValidEmail === false || isEmptyEmail === true ? true : false}
            onChange={(e) => {
              setEmail(e.target.value);
              setIsValidEmail(true); // reset the validation state
              setIsEmptyEmail(undefined); // reset the validation state
            }}
            type={'email'}
            placeholder={'Email Address'}
            label={'Email Address'}
            autoComplete="off"
            success={email.length > 0 ? true : false}
          />
          {!isValidEmail ? (
            <div id="email-error-message">
              <img src="/icons/error-mark-circle.svg" alt="" />
              <span className="text-[#BD0A00] text-sm">Oops! Please give us a valid email address.</span>
            </div>
          ) : null}
          {isEmptyEmail ? (
            <div id="email-error-message">
              <img src="/icons/error-mark-circle.svg" alt="" loading="lazy" /> Please enter an email address.
            </div>
          ) : null}
          <GalagoButton label={disableEmailButton ? <LoadingBouncingDots /> : 'Continue'} onClick={onClickVerifyEmail} btn="primary" />
          {/* <button onClick={verifyEmail} disabled={disableEmailButton} type="button" className="btn btn-primary mt-3">
            {disableEmailButton ? <LoadingBouncingDots /> : 'Continue'}
          </button> */}
        </div>
      </Modal.Body>
    </Modal>
  );
};
