import React from 'react';
import { FlightTabIndex } from './FlightTabIndex';
import { HotelTabIndex } from './HotelTabIndex';
import { HotelSvg } from '../../resources/svg/HotelSvg';
import { AirplaneUpRightSvg } from '../../resources/svg/AirplanesSvg';
import colors from '../../resources/css/_variables.module.scss';
import { FlightTabIndexWTP } from './FlightTabIndexWTP';
import { HotelTabIndexWTP } from './HotelTabIndexWTP';
const { primary, blackNormal } = colors;

export const SearchFormWTP = () => {
  const [activeTab, setActiveTab] = React.useState('flights');

  return (
    <>
      <div id="hero-section">
        <div className={`hero-background ${activeTab === 'flights' ? 'bg-flights' : 'bg-hotels'}`}>
          <div className="overlay" />

          <div className="content">
            <div className="hero-text">
              <p className="hero-tagline"> {activeTab === 'flights' ? 'Book Your Next Flight' : 'Book Your Perfect Story'} </p>
              <p className="hero-subtag p-0 m-0"> {activeTab === 'flights' ? "Search and discover 700+ flights *" : "Search and discover 1 million rooms *"}</p>
            </div>

            <div className="search-form-container">
              <div id="search-form-main">
                <ul className="nav nav-tabs active" id="search-tabs" role="tablist">
                  <li className="nav-item">
                    <button
                      className="nav-link active satoshi-regular d-flex gap-2 align-items-center p-3"
                      id="flight-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#flight-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="flight-tab-pane"
                      aria-selected="true"
                      onClick={() => {
                        setActiveTab('flights');
                      }}
                    >
                      <AirplaneUpRightSvg _color={activeTab === 'flights' ? primary : blackNormal} />
                      <p style={{ color: activeTab === 'flights' ? primary : blackNormal, fontWeight: 'bold' }}>Flights</p>
                    </button>
                  </li>

                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link satoshi-regular d-flex gap-2 align-items-center p-3"
                      id="hotel-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#hotel-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="hotel-tab-pane"
                      aria-selected="false"
                      onClick={() => setActiveTab('hotels')}
                    >
                      <HotelSvg _color={activeTab === 'hotels' ? primary : blackNormal} />
                      <p style={{ color: activeTab === 'hotels' ? primary : blackNormal, fontWeight: 'bold' }}>Hotels</p>
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <FlightTabIndexWTP />
                  <HotelTabIndexWTP reset={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
