import React from 'react';
import Select from 'react-select';

type SelectOptionProps = {
  dataOptions: { [key: string]: string }[];
  onChange?: (newValue: any) => void;
  placeholder?: string;
};

const SelectOption = ({ dataOptions, placeholder, onChange }: SelectOptionProps) => {
  const options = dataOptions;
  const [value, setValue] = React.useState<{ [key: string]: string } | null>(null);
  return (
    <Select
      styles={{
        control: (base) => ({
          ...base,
          borderRadius: 12,
          borderWidth: 2,
          borderColor: value ? 'rgb(79, 162, 180)' : '',
          boxShadow: 'none',
          ':active': { borderColor: 'rgb(79, 162, 180)' },
          ':focus': { borderColor: 'rgb(79, 162, 180)' },
          ':hover': { borderColor: 'rgb(79, 162, 180)' },
        }),
        input: (base) => ({ ...base, padding: '12px' }),
        singleValue: (base) => ({ ...base, padding: '12px' }),
        menuList: (base) => ({ ...base, color: 'black' }),
        placeholder: (base) => ({ ...base, padding: '12px' }),
      }}
      options={options}
      className="w-100 position-relative mb-0"
      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
      placeholder={placeholder}
      onChange={(newValue) => {
        onChange && onChange(newValue);
        setValue(newValue as { [key: string]: string } | null);
      }}
    />
  );
};

export default SelectOption;
