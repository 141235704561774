//eslint-disable-next-line
import React, { MouseEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LocationSvg } from '../../../resources/svg/LocationSvg';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { SearchSvg } from '../../../resources/svg/SearchSvg';
import { FloatingIconFormControl } from '../../../components/FormControl';
import Calendar from 'react-calendar';
import { CalendarSvg } from '../../../resources/svg/CalendarSvg';
import { SearchPageHook } from '../../../hooks/SearchPageHook';
import { GalagoButton } from '../../../components/Button';
import moment from 'moment';
import { Passengers } from '../../../components/Passengers';
import { PassengersStore } from '../../../store/PassengersStore';
import FlightResultsSearchFormMobile from './FlightResultsSearchFormMobile';
import { TiLocation } from 'react-icons/ti';
import { AirportSvg } from '../../../resources/svg/AirportSvg';
import { PassengerSvg } from '../../../resources/svg/PassengerSvg';
import { ChevronDownV2 } from '../../../resources/svg/ChevronDownV2';
import { FlightInfoStore } from '../../../store/FlightInfoStore';
//eslint-disable-next-line
import { CloseCircleFilledSvg } from '../../../resources/svg/CloseSvg';
import { CabinSvg } from '../../../resources/svg/CabinSvg';
import { AsyncTypestore } from '../../../store/AsyncTypestore';
//eslint-disable-next-line
import { SwitchArrowSvg } from '../../../resources/svg/SwitchArrowSvg';

import { FlightFiltersStoreOneWay } from '../../../store/FlightFiltersStoreOneWay';
import colors from '../../../resources/css/_variables.module.scss';
const { primary, charcoal60 } = colors;

type FlightResultsSearchFormType = {
  searchType: 'one-way' | 'round-trip' | 'multi-city';
  searchCriteria: { [index: string]: any };
  // setSelectedFlight: (e: any) => void;
  // setResetSelectedFlight?: (e: any) => void;
  // setDepartureTrip: (e: any) => void;
  // setReturnTrip: (e: any) => void;
  setFlightInfo: (e: any) => void;
  setShowReturnFlights: (e: any) => void;
};

const FlightResultsSearchForm = ({
  searchType,
  searchCriteria,
  // setSelectedFlight,
  // setResetSelectedFlight,
  // setDepartureTrip,
  // setReturnTrip,
  setFlightInfo,
  setShowReturnFlights,
}: FlightResultsSearchFormType) => {
  const navigate = useNavigate();
  const { setPriceRange, setSelectedPriceRangeLabel } = FlightFiltersStoreOneWay();
  const { airportLocations } = SearchPageHook();
  const passengerStore = PassengersStore();
  const { passengers, fromCityName, fromAirportCode, toCityName, toAirportCode, returnDate, departingDate, cabinClass } = searchCriteria;
  const { flyingFrom, setFlyingFrom, flyingTo, setFlyingTo, setFromDetails, setToDetails, fromDetails, toDetails, setIsFromActive, setIsToActive, isFromActive, isToActive } = AsyncTypestore();

  //* Function to store values in localStorage
  const saveToLocalStorage = () => {
    const expiryInMinutes = 2;
    const now = new Date();
    const expiryTimestamp = now.getTime() + expiryInMinutes * 60 * 1000; //? Convert minutes to milliseconds

    if (flyingFrom) {
      localStorage.setItem('flyingFrom', JSON.stringify({ value: flyingFrom, expiry: expiryTimestamp }));
    }
    if (flyingTo) {
      localStorage.setItem('flyingTo', JSON.stringify({ value: flyingTo, expiry: expiryTimestamp }));
    }
  };

  // Retrieve values from localStorage
  const getFromLocalStorage = () => {
    const now = new Date().getTime();

    const storedFlyingFromStr = localStorage.getItem('flyingFrom');
    if (storedFlyingFromStr) {
      try {
        const storedFlyingFrom = JSON.parse(storedFlyingFromStr);
        if (now < storedFlyingFrom.expiry) {
          setFlyingFrom(storedFlyingFrom.value);
        } else {
          localStorage.removeItem('flyingFrom'); //? Remove expired item
        }
      } catch (e) {
        console.error('Failed to parse flyingFrom from localStorage:', e);
        localStorage.removeItem('flyingFrom'); //? Remove corrupted item
      }
    }

    const storedFlyingToStr = localStorage.getItem('flyingTo');
    if (storedFlyingToStr) {
      try {
        const storedFlyingTo = JSON.parse(storedFlyingToStr);
        if (now < storedFlyingTo.expiry) {
          setFlyingTo(storedFlyingTo.value);
        } else {
          localStorage.removeItem('flyingTo'); //? Remove expired item
        }
      } catch (e) {
        console.error('Failed to parse flyingTo from localStorage:', e);
        localStorage.removeItem('flyingTo'); //? Remove corrupted item
      }
    }
  };

  // const clearStorage = () => {
  //   localStorage.removeItem('flyingFrom');
  //   localStorage.removeItem('flyingTo');
  //   // Optionally reset the state values
  //   setFlyingFrom('');
  //   setFlyingTo('');
  // };

  useEffect(() => {
    // Retrieve from localStorage on component mount
    getFromLocalStorage();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Save to localStorage whenever values change
    saveToLocalStorage();
    // eslint-disable-next-line
  }, [flyingFrom, flyingTo, fromDetails, toDetails, isFromActive, isToActive]);

  const { setShowFlightInfoModal, setShowReturnFlightsInfo } = FlightInfoStore();
  React.useEffect(() => {
    //* set default passenger state if the page is refreshed
    passengerStore.setPassengers({
      ...{
        adults: passengers.adults,
        minors: passengers.minors,
        children: passengers.children,
        infants: passengers.infants,
      },
    });
    // eslint-disable-next-line
  }, []);
  const PassengerStore = PassengersStore();
  const passengerTypes = PassengerStore.passengers;
  //eslint-disable-next-line

  //* form defaultValues
  // eslint-disable-next-line
  const [flyingFromInput, setFlyingFromInput] = useState('');
  // eslint-disable-next-line
  const [flyingToInput, setFlyingToInput] = useState('');
  //eslint-disable-next-line
  const [departingInput, setDepartingInput] = useState('');
  //eslint-disable-next-line
  const [returnDateInput, setReturnDateInput] = useState('');
  //eslint-disable-next-line
  const [adultsInput, setAdultsInput] = useState(1);
  //eslint-disable-next-line
  const [childrenInput, setChildrenInput] = useState(0);
  //eslint-disable-next-line
  const [infantsInput, setInfantsInput] = useState(0);
  //eslint-disable-next-line
  const [showSearchOptions, setShowSearchOptions] = useState(false);

  //! Refs
  const fromRef = useRef<any>(null);
  const toRef = useRef<any>(null);

  useEffect(() => {
    // Check if the refs are assigned and apply border color
    if (fromRef.current) {
      fromRef.current.inputNode.style.borderColor = primary;
    }
    if (toRef.current) {
      toRef.current.inputNode.style.borderColor = primary;
    }
    // eslint-disable-next-line
  }, []);

  //* States for Calendar
  const [showPassengerForm, setShowPassengerForm] = useState(false);
  const [showDepartingCalendar, setShowDepartingCalendar] = useState(false);
  const [showReturnCalendar, setShowReturnCalendar] = useState(false);
  //eslint-disable-next-line
  const [activeFlightSwitch, setActiveFlightSwitch] = useState('one-way');
  const [showCabinClassForm, setShowCabinClassForm] = useState(false);
  const [isPassengerActive, setIsPassengerActive] = useState(false);
  //eslint-disable-next-line
  const [isPassengerChanged, setIsPassengerChanged] = useState(false);
  const [isCabinClassActive, setIsCabinClassActive] = useState(false);
  const [isCabinClassChanged, setIsCabinClassChanged] = useState(false);
  const [defaultReturnDate, setDefaultReturnDate] = useState('');
  const [isDepartureDateActive, setIsDepartureDateActive] = useState(false);
  const [isReturnDateActive, setIsReturnDateActive] = useState(false);

  //* Default values
  const [departing, setDeparting] = useState(moment(departingDate).format('MMM DD, YYYY'));
  const [returning, setReturning] = useState(moment(returnDate).format('MMM DD, YYYY'));
  //eslint-disable-next-line
  const [adults, setAdults] = useState(1);

  const [cabin, setCabin] = useState(cabinClass || 'economy');
  const [cabinLabel, setCabinLabel] = useState(() => {
    switch (cabinClass) {
      case 'business':
        return 'Business';
      case 'first':
        return 'First Class';
      case 'premiumEconomy':
        return 'Premium Economy';
      default:
        return 'Economy';
    }
  });

  //* Dates
  const currentDate = moment(new Date()).format('MMM DD, YYYY');
  //eslint-disable-next-line
  const [defaultDepartureDate, setDefaultDepartureDate] = useState(currentDate);

  //* Actions
  const [disabledButtonSearch, setDisabledButtonSearch] = useState(true);
  //eslint-disable-next-line
  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState(true);

  //* Airport Searching
  //eslint-disable-next-line
  const [loadingAirportList, setLoadingAirportList] = useState(false);
  const [FromAirportList, setFromAirportList] = useState<{ [index: string]: any }>();
  const [FromMappedFlights, setFromMappedFlights] = useState<any>();
  const [ToAirportList, setToAirportList] = useState<{ [index: string]: any }>();
  const [ToMappedFlights, setToMappedFlights] = useState<any>();

  //* Style
  const [isFlightTypeClicked, setIsFlightTypeClicked] = useState(false);
  const [isPassengerClicked, setIsPassengerClicked] = useState(false);
  const [isCabinClicked, setIsCabinClicked] = useState(false);

  //* Flight Type
  const [showFlightTypeForm, setShowFlightTypeForm] = useState(false);
  const [isFlightTypeActive, setIsFlightTypeActive] = useState(false);
  const [isFlightTypeChanged, setIsFlightTypeChanged] = useState(false);
  //eslint-disable-next-line
  const [flightType, setFlightType] = useState('one-way');
  const [flightTypeLabel, setFlightTypeLabel] = useState('one-way');
  //eslint-disable-next-line
  const [defaultFlightType, setDefaultFlightType] = searchType === 'one-way' ? 'one-way' : 'round-trip';
  //eslint-disable-next-line
  const [flightTypeValue, setFlightTypeValue] = useState(defaultFlightType);

  //! Button Disabled
  useEffect(() => {
    setDisabledButtonSearch(true);
    if (activeFlightSwitch === 'one-way') {
      if (flyingFrom && flyingTo && (departing || defaultDepartureDate) && adults && cabin) {
        setDisabledButtonSearch(false);
      }
    }
    if (activeFlightSwitch === 'round-trip') {
      if (flyingFrom && flyingTo && (departing || defaultDepartureDate) && (returnDate || defaultReturnDate) && adults && cabin) {
        setDisabledButtonSearch(false);
      }
    }
    // eslint-disable-next-line
  }, [flyingFrom, flyingTo, departing, returnDate, cabin, activeFlightSwitch]);

  const handleClosePopups = (param: boolean) => {
    setShowDepartingCalendar(param);
    setShowReturnCalendar(param);
    setShowPassengerForm(param);
    setShowCabinClassForm(param);
  };

  React.useEffect(() => {
    setIsSearchButtonDisabled(true);
    if (flightTypeValue === 'one-way') {
      if (flyingFromInput !== '' && flyingToInput !== '' && departingInput !== '') {
        setIsSearchButtonDisabled(false);
      }
    }
    if (flightTypeValue === 'round-trip') {
      if (flyingFromInput !== '' && flyingToInput !== '' && departingInput !== '' && returnDateInput !== '') {
        setIsSearchButtonDisabled(false);
      }
    }
  }, [flyingFromInput, flyingToInput, departingInput, returnDateInput, flightTypeValue]);

  React.useEffect(() => {
    setAdultsInput(passengerStore.passengers.adults + passengerStore.passengers.minors);
    setChildrenInput(passengerStore.passengers.children);
    setInfantsInput(passengerStore.passengers.infants);
  }, [passengerStore.passengers.adults, passengerStore.passengers.minors, passengerStore.passengers.children, passengerStore.passengers.infants]);

  //! Mapping for Departure airports
  useEffect(() => {
    if (FromAirportList && Array.isArray(FromAirportList.tag)) {
      const _country: string[] = [];
      const foundAirports = FromAirportList.tag;

      if (Array.isArray(foundAirports)) {
        // Collect unique country locations
        foundAirports.forEach((airport: { location: string }) => {
          if (airport && airport.location && !_country.includes(airport.location)) {
            _country.push(airport.location);
          }
        });

        const _FromMappedAirports: { [index: string]: any } = [];
        let _index = 0;

        // Map country locations to airport details
        _country.forEach((_airport) => {
          foundAirports.forEach((airport: { location: string; airportCode: string; airportName: string; cityName: string; countryCode: string; airports: any[] }) => {
            if (_airport === airport.location) {
              // Include main airport details (airportCode and airportName)
              _FromMappedAirports[_index] = {
                index: _index,
                name: airport.location,
                code: airport.airportCode,
                isCountry: true,
                details: null,
              };
              _index++;

              airport.airports.forEach((detail: any) => {
                _FromMappedAirports[_index] = {
                  index: _index,
                  name: airport.cityName,
                  code: airport.countryCode,
                  isCountry: false,
                  details: detail,
                };
                _index++;
              });
            }
          });
        });

        // Set the mapped flights to state
        setFromMappedFlights(_FromMappedAirports);
      } else {
        console.error('FromAirportList.tag is not an array:', foundAirports);
      }
    }
  }, [FromAirportList]);

  //! Mapping for Arrival airports
  useEffect(() => {
    if (ToAirportList && Array.isArray(ToAirportList.tag)) {
      const _country: string[] = [];
      const foundAirports = ToAirportList.tag;

      if (Array.isArray(foundAirports)) {
        foundAirports.forEach((airport: { location: string }) => {
          if (airport && airport.location && !_country.includes(airport.location)) {
            _country.push(airport.location);
          }
        });

        const _ToMappedAirports: { [index: string]: any } = [];
        let _index = 0;

        _country.forEach((_airport) => {
          foundAirports.forEach((airport: { location: string; airportCode: string; airportName: string; cityName: string; countryCode: string; airports: any[] }) => {
            if (_airport === airport.location) {
              // Include main airport details (airportCode and airportName)
              _ToMappedAirports[_index] = {
                index: _index,
                name: airport.location,
                code: airport.airportCode,
                isCountry: true,
                details: null,
              };
              _index++;

              airport.airports.forEach((detail: any) => {
                _ToMappedAirports[_index] = {
                  index: _index,
                  name: airport.cityName,
                  code: airport.countryCode,
                  isCountry: false,
                  details: detail,
                };
                _index++;
              });
            }
          });
        });

        setToMappedFlights(_ToMappedAirports);
      } else {
        console.error('ToAirportList.tag is not an array:', foundAirports);
      }
    }
  }, [ToAirportList]);

  //! Handle label change
  const handleLabelChange = (e: any) => {
    if (e.isCountry) {
      return `${e.name}`;
    } else {
      return `${e.details?.airportName}`;
    }
  };

  //! Set default return date
  useEffect(() => {
    setDefaultReturnDate(moment(defaultDepartureDate).add(1, 'days').format('MMM DD, YYYY'));
    if (departing !== '') {
      setDefaultReturnDate(moment(departing).add(1, 'days').format('MMM DD, YYYY'));
    }

    if (returning !== '') {
      if (moment(departing).isSameOrAfter(moment(returning ? returning : defaultReturnDate))) {
        setReturning(moment(departing).add(1, 'days').format('MMM DD, YYYY'));
      }
    }
    // eslint-disable-next-line
  }, [departing]);

  //* Request Query Trigger for Autosearch Edit Function
  const fromTriggerSearch = (query: any) => {
    if (query) {
      airportLocations(query).then((response) => {
        setFromAirportList(response);
      });
    }
  };

  const fromHandleFocus = () => {
    handleClosePopups(false);
    setIsFromActive(true);
    fromTriggerSearch(fromRef.current.inputNode.value);
    // if (fromRef.current) {
    //   fromRef.current.inputNode.style.borderColor = primary;
    // }
  };

  const toTriggerSearch = (query: any) => {
    if (query) {
      airportLocations(query).then((response) => {
        setToAirportList(response);
      });
    }
  };
  const toHandleFocus = () => {
    handleClosePopups(false);
    setIsToActive(true);
    toTriggerSearch(toRef.current.inputNode.value);
    // if (toRef.current) {
    //   toRef.current.inputNode.style.borderColor = primary;
    // }
  };

  useEffect(() => {
    setFlightType(searchType === 'one-way' ? 'one-way' : 'round-trip');
    setFlightTypeLabel(searchType === 'one-way' ? 'one-way' : 'round-trip');
  }, [searchType]);

  // const switchDestination = () => {
  //   let from = flyingFrom;
  //   let to = flyingTo;
  //   setFlyingFrom(to);
  //   setFlyingTo(from);

  //   let fromDets = fromDetails;
  //   let toDets = toDetails;
  //   setFromDetails(toDets);
  //   setToDetails(fromDets);

  //   let fromRefState = fromRef.current.state.selected;
  //   let toRefState = toRef.current.state.selected;
  //   fromRef.current.state.selected = toRefState;
  //   toRef.current.state.selected = fromRefState;
  // };

  return (
    <>
      {/* edit wrapper start */}
      <div className="p-5 bg-primary z-10 mb-[13rem]" style={{ height: '150px' }}>
        <div className="edit_wrapper container" id="absolute-flight-info">
          <div className="edit-card ">
            <div className="flex-wrap">
              <div className="flex gap-3 w-50">
                {/* FLight Type */}
                <div className="w-[140px] relative">
                  <FloatingIconFormControl
                    icon={<AirportSvg _color={(flyingFrom && flyingTo) || isFlightTypeActive || isFlightTypeChanged ? primary : '#a8a8a8'} />}
                    caret={
                      <ChevronDownV2
                        style={{
                          transform: showFlightTypeForm ? 'rotate(180deg)' : 'rotate(0deg)',
                          transition: 'transform 0.3s ease, fill 0.3s ease',
                        }}
                        _color={(flyingFrom && flyingTo) || isFlightTypeActive || isFlightTypeChanged ? primary : '#adadad'}
                      />
                    }
                    id="flight-type"
                    name="flight-type"
                    type="select"
                    value={flightTypeLabel === 'one-way' ? 'One-way' : 'Round-trip'}
                    defaultValue={searchType === 'one-way' ? 'one-way' : 'round-trip'}
                    onClick={() => {
                      // Toggle showFlightTypeForm state
                      setShowFlightTypeForm((prevState) => {
                        // Toggle isFlightTypeClicked based on the new state of showFlightTypeForm
                        setIsFlightTypeClicked((prevState) => !prevState);
                        return !prevState;
                      });

                      // Reset other forms and calendars
                      setShowDepartingCalendar(false);
                      setShowReturnCalendar(false);
                      setShowPassengerForm(false);
                      setShowCabinClassForm(false);
                    }}
                    autoComplete="false"
                    readOnly={true}
                    onBlur={() => (!isFlightTypeChanged ? setIsFlightTypeActive(false) : '')}
                    onFocus={() => setIsFlightTypeActive(true)}
                    style={{
                      border: 'none',
                      cursor: 'pointer',
                      color: isFlightTypeClicked ? primary : '#333',
                    }}
                  />

                  {showFlightTypeForm && (
                    <div
                      onMouseLeave={() => {
                        setShowFlightTypeForm(false);
                        setIsFlightTypeClicked(false);
                      }}
                      id="cabin-details-holder"
                      className="position-absolute bg-body d-flex flex-column align-items-start justify-content-center"
                      style={{
                        left: 0,
                        right: 0,
                        marginRight: 'auto',
                        marginTop: '10px',
                        width: '100%',
                        zIndex: 10,
                      }}
                    >
                      <div
                        className="cabin-opt"
                        onClick={() => {
                          setFlightType('one-way');
                          setFlightTypeLabel('one-way');
                          setShowFlightTypeForm(false);
                          setIsFlightTypeChanged(true);
                        }}
                      >
                        One-Way
                      </div>
                      <div
                        className="cabin-opt"
                        onClick={() => {
                          setReturning(moment(departing).add(1, 'days').format('MMM DD, YYYY'));
                          setFlightType('round-trip');
                          setFlightTypeLabel('round-trip');
                          setShowFlightTypeForm(false);
                          setIsFlightTypeChanged(true);
                        }}
                      >
                        Round-trip
                      </div>
                    </div>
                  )}
                </div>
                {/* FLight Type  */}

                {/* Passenger */}
                <div className="w-[310px]">
                  <FloatingIconFormControl
                    onClick={() => {
                      // Toggle showPassengerForm state
                      setShowPassengerForm((prevState) => {
                        // Toggle isPassengerClicked based on the new state of showPassengerForm
                        setIsPassengerClicked((prevState) => !prevState);
                        return !prevState;
                      });

                      // Reset other forms and calendars
                      setShowDepartingCalendar(false);
                      setShowReturnCalendar(false);
                      setShowFlightTypeForm(false);
                      setShowCabinClassForm(false);
                    }}
                    onFocus={() => setIsPassengerActive(true)}
                    onBlur={() => setIsPassengerActive(false)}
                    value={`${passengerTypes.adults} ${passengerTypes.adults > 1 ? 'Adults,' : 'Adult,'} ${passengerTypes.minors} ${passengerTypes.minors > 1 ? 'Minors,' : 'Minor,'} ${
                      passengerTypes.children
                    } ${passengerTypes.children > 1 ? 'Children,' : 'Child,'} ${passengerTypes.infants} ${passengerTypes.infants > 1 ? 'Infants' : 'Infant'}`}
                    type={'text'}
                    icon={<PassengerSvg _color={(flyingFrom && flyingTo) || isPassengerActive || isPassengerChanged ? primary : '#A8A8A8'} />}
                    caret={
                      <ChevronDownV2
                        style={{
                          transform: showPassengerForm ? 'rotate(180deg)' : 'rotate(0deg)',
                          transition: 'transform 0.3s ease, fill 0.3s ease',
                        }}
                        _color={(flyingFrom && flyingTo) || isPassengerActive || isPassengerChanged ? primary : '#adadad'}
                      />
                    }
                    readOnly={true}
                    autoComplete="false"
                    style={{
                      border: 'none',
                      cursor: 'pointer',
                      color: isPassengerClicked ? primary : '#333',
                    }}
                  />

                  {showPassengerForm && (
                    <Passengers
                      onMouseLeave={() => {
                        setShowPassengerForm(false);
                        setIsPassengerClicked(false);
                      }}
                    />
                  )}
                </div>
                {/* Passengers */}

                {/* Cabin Class */}
                <div className="w-[150px] relative">
                  <FloatingIconFormControl
                    icon={<CabinSvg _color={(flyingFrom && flyingTo) || isCabinClassActive || isCabinClassChanged ? primary : '#a8a8a8'} />}
                    caret={
                      <ChevronDownV2
                        style={{
                          transform: showCabinClassForm ? 'rotate(180deg)' : 'rotate(0deg)',
                          transition: 'transform 0.3s ease, fill 0.3s ease',
                        }}
                        _color={(flyingFrom && flyingTo) || isCabinClassActive || isCabinClassChanged ? primary : '#adadad'}
                      />
                    }
                    id="cabin-class"
                    name="cabin-class"
                    type="select"
                    value={cabinLabel}
                    defaultValue={'Economy'}
                    onClick={() => {
                      // Toggle showCabinClassForm state
                      setShowCabinClassForm((prevState) => {
                        // Toggle isCabinClicked based on the new state of showCabinClassForm
                        setIsCabinClicked((prevState) => !prevState);
                        return !prevState;
                      });

                      // Reset other forms and calendars
                      setShowDepartingCalendar(false);
                      setShowReturnCalendar(false);
                      setShowPassengerForm(false);
                      setShowFlightTypeForm(false);
                    }}
                    autoComplete="false"
                    readOnly={true}
                    onBlur={() => (!isCabinClassChanged ? setIsCabinClassActive(false) : '')}
                    onFocus={() => setIsCabinClassActive(true)}
                    style={{
                      border: 'none',
                      cursor: 'pointer',
                      color: isCabinClicked ? primary : '#333',
                    }}
                  />

                  {showCabinClassForm && (
                    <div
                      onMouseLeave={() => {
                        setShowCabinClassForm(false);
                        setIsCabinClicked(false);
                      }}
                      id="cabin-details-holder"
                      className="position-absolute bg-body d-flex flex-column align-items-start justify-content-center"
                      style={{
                        left: 0,
                        right: 0,
                        marginRight: 'auto',
                        marginTop: '10px',
                        width: '100%',
                        zIndex: 10,
                      }}
                    >
                      <div
                        className="cabin-opt"
                        onClick={() => {
                          setCabin('economy');
                          setCabinLabel('Economy');
                          setShowCabinClassForm(false);
                          setIsCabinClassChanged(true);
                        }}
                      >
                        Economy
                      </div>
                      <div
                        className="cabin-opt"
                        onClick={() => {
                          setCabin('business');
                          setCabinLabel('Business');
                          setShowCabinClassForm(false);
                          setIsCabinClassChanged(true);
                        }}
                      >
                        Business
                      </div>
                      <div
                        className="cabin-opt"
                        onClick={() => {
                          setCabin('first');
                          setCabinLabel('First Class');
                          setShowCabinClassForm(false);
                          setIsCabinClassChanged(true);
                        }}
                      >
                        First Class
                      </div>
                      <div
                        className="cabin-opt"
                        onClick={() => {
                          setCabin('premiumEconomy');
                          setCabinLabel('Premium Ec..');
                          setShowCabinClassForm(false);
                          setIsCabinClassChanged(true);
                        }}
                      >
                        Premium Economy
                      </div>
                    </div>
                  )}
                </div>
                {/* Cabin Class */}
              </div>

              <div className="py-3">
                <hr />
              </div>

              <div className=" flex gap-2">
                {/* Departure */}
                <div className="flex-fill">
                  <label htmlFor="" className="form-label">
                    Departure
                  </label>
                  <span className="position-relative d-block ">
                    <LocationSvg _color={flyingFrom || isFromActive ? primary : '#A8A8A8'} className="location-svg position-absolute" style={{ zIndex: 1, left: '12px', top: '18px' }} />
                    {flyingFrom !== '' ? (
                      <CloseCircleFilledSvg
                        _color={primary}
                        className="clear-text-svg absolute"
                        style={{ zIndex: 2, right: '12px', top: '18px' }}
                        onClick={() => {
                          setFlyingFrom('');
                          setFromDetails('');
                          // setAirportOptions([{}]);
                          fromRef.current.state.text = '';
                          fromRef.current.state.selected = [];
                          fromRef.current.inputNode.value = '';
                        }}
                      />
                    ) : null}
                    <AsyncTypeahead
                      ref={fromRef}
                      id="async-flying-from"
                      className="async-flight-locations border-color-error-red-700"
                      isLoading={loadingAirportList}
                      onSearch={(query) => fromTriggerSearch(query)}
                      onChange={(e: { [index: string]: any }) => {
                        const airportCode = e[0]?.details?.airportCode ?? e[0]?.code;
                        const airportName = e[0]?.details?.airportName ?? e[0]?.name;
                        setFlyingFrom(airportCode);
                        setFromDetails(airportName);
                      }}
                      onKeyDown={(e) => {
                        if (!/[a-zA-Z, ]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onFocus={fromHandleFocus}
                      onBlur={() => {
                        setIsFromActive(false);
                      }}
                      labelKey={(e) => handleLabelChange(e)}
                      defaultInputValue={fromCityName}
                      placeholder={fromCityName}
                      options={FromMappedFlights}
                      minLength={0}
                      filterBy={() => true}
                      renderMenuItemChildren={(e: any) => {
                        return (
                          <div className={!e?.isCountry ? 'ps-4 d-flex flex-wrap align-items-start justify-between generalsans-medium' : 'd-flex generalsans-medium w-full '}>
                            <div className="pt-0.4 flex  gap-2 align-items-center justify-between">
                              {e?.isCountry ? <TiLocation /> : <AirportSvg />}
                              <span className="">{e.details?.airportName} </span>
                            </div>
                            {e?.isCountry && (
                              <div className="d-flex flex-column  justify-between w-full">
                                <div className="flex justify-between align-items-center">
                                  <div className="flex">{e.name} </div>
                                  <span className="">{e.isCountry && 'All Airports'}</span>
                                </div>
                              </div>
                            )}

                            <span>{e.details?.airportCode}</span>
                          </div>
                        );
                      }}
                    />
                  </span>
                </div>
                {/* Departure */}

                {/* <div className=" svg-arrow mb-[10px]">
                          <div className="switch-arrow-svg mb-1 switch" onClick={() => switchDestination()}>
                            <SwitchArrowSvg _color="#fff" />
                          </div>
                        </div> */}

                {/* Arrival */}
                <div className="flex-fill">
                  <label htmlFor="" className="form-label">
                    Arrival
                  </label>
                  <span className="position-relative d-block">
                    <LocationSvg _color={flyingTo || isToActive ? primary : '#A8A8A8'} className="location-svg position-absolute" style={{ zIndex: 1, left: '12px', top: '18px' }} />
                    {flyingTo !== '' ? (
                      <CloseCircleFilledSvg
                        _color={primary}
                        className="clear-text-svg absolute"
                        style={{ zIndex: 2, right: '12px', top: '18px' }}
                        onClick={() => {
                          setFlyingTo('');
                          setToDetails('');
                          // setAirportOptions([{}]);
                          toRef.current.state.text = '';
                          toRef.current.state.selected = [];
                          toRef.current.inputNode.value = '';
                        }}
                      />
                    ) : null}
                    <AsyncTypeahead
                      ref={toRef}
                      id="async-flying-to"
                      className="async-flight-locations"
                      isLoading={loadingAirportList}
                      onSearch={(query) => toTriggerSearch(query)}
                      onChange={(e: { [index: string]: any }) => {
                        const airportCode = e[0]?.details?.airportCode ?? e[0]?.code;
                        const airportName = e[0]?.details?.airportName ?? e[0]?.name;

                        setFlyingTo(airportCode);
                        setToDetails(airportName);
                      }}
                      onKeyDown={(e) => {
                        if (!/[a-zA-Z ]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onFocus={toHandleFocus}
                      onBlur={() => {
                        setIsToActive(false);
                      }}
                      labelKey={(e) => handleLabelChange(e)}
                      defaultInputValue={toCityName}
                      placeholder={toCityName}
                      options={ToMappedFlights}
                      minLength={1}
                      filterBy={() => true}
                      renderMenuItemChildren={(e: any) => (
                        <div
                          className={
                            !e?.isCountry
                              ? 'ps-4 d-flex flex-wrap align-items-start justify-between generalsans-medium w-100 max-w-100'
                              : 'd-flex generalsans-medium w-full border-color-shades-gray-900'
                          }
                        >
                          <div className="pt-0.4 flex  gap-2 align-items-center justify-between">
                            {e?.isCountry ? <TiLocation /> : <AirportSvg />}
                            <span className="">{e.details?.airportName} </span>
                          </div>
                          {e?.isCountry && (
                            <div className="d-flex flex-column  justify-between w-full">
                              <div className="flex justify-between align-items-center">
                                <div className="flex">{e.name} </div>
                                <span className="">{e.isCountry && 'All Airports'}</span>
                              </div>
                            </div>
                          )}

                          <span>{e.details?.airportCode}</span>
                        </div>
                      )}
                    />
                  </span>
                </div>
                {/* Arrival */}

                {/* Departure Calendar */}
                <div className="flex-fill w-[5%]">
                  <FloatingIconFormControl
                    onClick={(e: any) => {
                      setShowDepartingCalendar(true);
                      setShowReturnCalendar(false);
                      setShowPassengerForm(false);
                      setShowCabinClassForm(false);
                    }}
                    value={!departing ? defaultDepartureDate : departing}
                    onFocus={() => setIsDepartureDateActive(true)}
                    onBlur={() => setIsDepartureDateActive(false)}
                    readOnly={true}
                    onKeyDown={(e: any) => {
                      e.preventDefault();
                      return false;
                    }}
                    type={'text'}
                    label={'Departure Date'}
                    icon={<CalendarSvg _color={(flyingFrom && flyingTo) || departing || isDepartureDateActive ? primary : '#A8A8A8'} />}
                    // placeholder="Date of Departure"
                    placeholder={departingDate}
                    defaultValue={departingDate}
                    autoComplete="off"
                    style={{
                      border: (flyingFrom && flyingTo) || departing || isDepartureDateActive ? `2px solid ${primary}` : '',
                      marginBottom: '10px',
                      cursor: 'pointer',
                    }}
                  />
                  {showDepartingCalendar && (
                    <div className="flight-calendar-holder " onMouseLeave={() => setShowDepartingCalendar(false)}>
                      <Calendar
                        onChange={(e: any) => {
                          setShowDepartingCalendar(false);
                          setDeparting(moment(e).format('MMM DD, YYYY'));
                          setShowReturnCalendar(true);
                          setIsReturnDateActive(true);
                          // setMaxDate(moment(e).add(1, 'years').format('YYYY-MM-DD'));
                        }}
                        defaultValue={departing ? new Date(departing) : new Date(defaultDepartureDate)}
                        minDate={new Date()}
                        maxDate={new Date(moment().add(1, 'year').format('YYYY-MM-DD'))}
                      />
                    </div>
                  )}
                </div>
                {/* Departure Calendar */}

                {/* Return Date */}
                {flightTypeLabel === 'round-trip' && (
                  <div className="flex-fill w-[5%]">
                    <FloatingIconFormControl
                      value={!returning ? defaultReturnDate : returning}
                      onClick={() => {
                        setShowReturnCalendar(true);
                        setShowDepartingCalendar(false);
                        setShowPassengerForm(false);
                        setShowCabinClassForm(false);
                      }}
                      onFocus={() => setIsReturnDateActive(true)}
                      onBlur={() => setIsReturnDateActive(false)}
                      readOnly={true}
                      type={'text'}
                      label={'Return Date'}
                      icon={<CalendarSvg _color={(flyingFrom && flyingTo) || returnDate || isReturnDateActive ? primary : '#A8A8A8'} />}
                      placeholder={returning === '' ? defaultReturnDate : returning}
                      autoComplete="off"
                      id="return-date-frm"
                      style={{
                        border: (flyingFrom && flyingTo) || returnDate || isReturnDateActive ? `2px solid ${primary}` : '',
                        cursor: departing ? 'pointer' : 'not-allowed',
                      }}
                    />
                    {showReturnCalendar && departing !== '' && (
                      <div className="flight-calendar-holder" onMouseLeave={() => setShowReturnCalendar(false)}>
                        <Calendar
                          onChange={(e: any) => {
                            setReturning(moment(e).format('MMM DD, YYYY'));
                            setShowReturnCalendar(false);
                          }}
                          value={returnDate ? new Date(returnDate) : new Date(defaultReturnDate)}
                          minDate={departing ? new Date(departing) : new Date()}
                          maxDate={new Date(moment(departing).add(1, 'years').format('YYYY-MM-DD'))}
                        />
                      </div>
                    )}
                  </div>
                )}
                {/* Return Date */}

                {/* Search Button */}
                <div className="grid justify-end mt-[30px] mb-[25px]">
                  <GalagoButton
                    btn="primary"
                    size="sm"
                    className={`search-flight-button ${disabledButtonSearch && 'button-disabled'}`}
                    disabled={disabledButtonSearch}
                    style={{ width: 150, height: 50 }}
                    icon={
                      <span className="me-2 mb-1">
                        <SearchSvg _color="#fff" />
                      </span>
                    }
                    label={'SEARCH'}
                    onClick={() => {
                      // Clear Storage here if needed
                      if (flyingFrom === flyingTo) {
                        alert('Please make sure your departure and arrival are different.');
                        setFlyingFrom('');
                        setFlyingTo('');
                      } else {
                        setPriceRange({ start: 0, end: 0 });
                        setSelectedPriceRangeLabel({ start: 0, end: 0 });
                        let finalReturnDate = '';
                        flightTypeLabel === 'one-way'
                          ? (finalReturnDate = '')
                          : (finalReturnDate = `${returning ? moment(returning).format('YYYY/MM/DD') : moment(defaultReturnDate).format('YYYY/MM/DD')}`);

                        const urlGetParams = `?originCity=${fromDetails}&destinationCity=${toDetails}&originCode=${flyingFrom}&airportCode=${flyingFrom}&destinationCode=${flyingTo}&departureDate=${
                          departing ? moment(departing).format('YYYY/MM/DD') : moment(defaultDepartureDate).format('YYYY/MM/DD')
                        }&returnDate=${finalReturnDate}&class=${cabin}&adults=${passengerTypes.adults}&minors=${passengerTypes.minors}&children=${passengerTypes.children}&infants=${
                          passengerTypes.infants
                        }`;

                        // Use flightTypeLabel to set the correct flight type in the URL
                        let url = `/flights/${flightTypeLabel === 'one-way' ? 'one-way' : 'round-trip'}${urlGetParams}`;

                        setFlightInfo({});
                        setShowFlightInfoModal(false);
                        setShowReturnFlights(false);
                        setShowReturnFlightsInfo([]);
                        navigate(url);
                        setShowSearchOptions(false);
                      }
                    }}
                  />
                </div>
                {/* Search Button */}
              </div>
            </div>
          </div>
        </div>

        {/* edit wrapper end */}
        <FlightResultsSearchFormMobile
          // setSelectedFlight={setSelectedFlight}
          // setResetSelectedFlight={setResetSelectedFlight}
          // setDepartureTrip={setDepartureTrip}
          // setReturnTrip={setReturnTrip}
          setFlightInfo={setFlightInfo}
          searchCriteria={{ passengers, fromCityName, fromAirportCode, toCityName, toAirportCode, departingDate, returnDate, cabinClass }}
          searchType={searchType}
          setShowReturnFlights={setShowReturnFlights}
        />
      </div>
    </>
  );
};

export default FlightResultsSearchForm;
