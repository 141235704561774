import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLongRight } from '../../resources/svg/ArrowLongRightSvg';

const blogs = [
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/A+guide+to+Ethical.jpg',
    title: 'A Guide to Ethical and Sustainable Tourism in the Philippines',
    date: 'December 6, 2023',
    readTime: '7',
    desc: 'Home to over 7,000 islands, the Philippines is a tourism magnet, attracting visitors worldwide who long to grace its white-sand beaches..',
    link: '/a-guide-to-ethical-and-sustainable-tourism-in-the-philippines',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Mindful+Traveler%E2%80%99s+Guide+to+Filipino+Customs+and+Traditions.jpg',
    title: 'Travelers Crash Course on Filipino Customs, Traditions, and Cultural Etiquette',
    date: 'December 6, 2023',
    readTime: '3',
    desc: 'Be a mindful traveler. Take note of these words and gestures to keep in mind and to practice while exploring the Philippines. ',
    link: '/travelers-crash-course-on-filipino-customs-traditions,-and-cultural-etiquette',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Gadgets+and+Smartphone+Apps+Every+Traveler+Should+Have.jpg',
    title: 'Gadgets and Travel Apps Every Traveler Should Have',
    date: 'November 16, 2023',
    readTime: '6',
    desc: 'What travel apps and accessories belong in your packing list? Find out the best gadgets and apps for traveling!',
    link: '/gadgets-and-travel-apps-every-traveler-should-have',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Philippines+road+trip+guide_+Destinations+you+can+reach+by+car.jpg',
    title: 'Philippines Road Trip Guide: Destinations You Can Reach by Car',
    date: 'November 14, 2023',
    readTime: '7',
    desc: 'Need to get away from the city? Check out these road trip destinations near Manila for your next holiday!',
    link: '/philippines-road-trip-guide-destinations-you-can-reach-by-car',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Beat+the+heat_+Cool+climate+destinations+in+the+Philippines.jpg',
    title: 'Beat the Heat! Explore the Coldest Places in the Philippines',
    date: 'November 14, 2023',
    readTime: '7',
    desc: 'Want to escape the Metro Manila heat? Discover the coldest places in the Philippines for your next holiday.',
    link: '/beat-the-heat-explore-the-coldest-places-in-the-philippines',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/How+to+travel+when+youre+allergic.jpg',
    title: 'The Ultimate Guide to Traveling With Food Allergies',
    date: 'November 7, 2023',
    readTime: '6',
    desc: 'Are you traveling with food allergies? Learn the best ways to stay safe when enjoying international cuisine abroad!',
    link: '/the-ultimate-guide-to-traveling-with-food-allergies',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/El+Nido_Hotels.jpg',
    title: 'Need an Island Escape? Check Into These Hotels in El Nido',
    date: 'November 7, 2023',
    readTime: '8',
    desc: 'Planning a tropical escape to El Nido, Palawan, and wondering where to stay? Look no further! Here are five of our hand-picked best stays!',
    link: '/need-an-island-escape-check-into-these-hotels-in-el-nido',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/blogHeaderV2_Ghost+Tours+B.jpg',
    title: 'Tips for a Spooky But Safe Ghost-Hunting Adventure',
    date: 'October 26, 2023',
    readTime: '2',
    desc: 'Prepare for spine-chilling ghost-hunting adventures with these tips: gear, respect, groups, overnight stays, and a touch of superstition!',
    link: '/tips-for-a-spooky-but-safe-ghost-hunting-adventure',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/blogHeaderV2_Ghostly+Adventure.jpg',
    title: 'Add These 11 Haunted Places in the Philippines to Your Ghost Tour Bucket List',
    date: 'October 24, 2023',
    readTime: '5',
    desc: 'Ready to get spooked? Hop on a spine chilling ghost tour experience at the most haunted places in the Philippines!',
    link: '/add-these-11-haunted-places-in-the-philippines-to-your-ghost-tour-bucket-list',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/blogHeaderV2_Which+Type+of+Traveler+are+You.jpg',
    title: 'There are 3 Types of Travelers: Which One Are You?',
    date: 'October 20, 2023',
    readTime: '3',
    desc: 'Discover which of the three types of traveler you are—and maybe get into less fights with your travel group by doing so!',
    link: '/there-are-3-types-of-travelers-which-one-are-you?',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/blogHeaderV2_Cebu_What+to+do.jpg',
    title: 'What to Do in Cebu: Top 10 Best Cebu Tours',
    date: 'October 18, 2023',
    readTime: '8',
    desc: 'Discover the captivating beauty of Cebu! From historical sites to stunning landscapes, this guide will show you what to do in Cebu!',
    link: '/what-to-do-in-cebu-top-10-best-cebu-tours',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/El+Nido_Things+to+do.jpg',
    title: 'Things to Do in El Nido for an Adventure-Filled Trip',
    date: 'October 18, 2023',
    readTime: '7',
    desc: 'Discover the best things to do in El Nido for an unforgettable trip! From hidden lagoons to stunning caves, this island paradise has it all!',
    link: '/things-to-do-in-el-nido-for-an-adventure-filled-trip',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_Countryside.jpg',
    title: 'GalaGO! Guide: Things to Do in Bohol',
    date: 'October 9, 2023',
    readTime: '8',
    desc: 'Discover the enchanting wonders of Bohol with our guide to the best things to do on the island. Your Bohol adventure starts here!',
    link: '/galago-guide-things-to-do-in-bohol',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/El+Nido_GalaGO!+Guide.jpg',
    title: 'Your Ultimate Guide to El Nido, Palawan',
    date: 'September 29, 2023',
    readTime: '7',
    desc: 'Discover the breathtaking beauty of El Nido Palawan with our comprehensive guide.',
    link: '/your-ultimate-guide-to-el-nido-palawan',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Helpful+Tips+for+First-Time+Flyers.jpg',
    title: 'Flying for the First Time? Here are Some Helpful Tips for You!',
    date: 'September 29, 2023',
    readTime: '5',
    desc: 'Flying for the first time? Here are some best tips and must-haves for first time flyers.',
    link: '/flying-for-the-first-time-here-are-some-helpful-tips-for-you',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol+Hotels.jpg',
    title: 'Where to Stay in Bohol: Best Hotels and Resorts',
    date: 'September 29, 2023',
    readTime: '7',
    desc: 'Wondering where to stay in Bohol? Look no further! Here’s a curated list of GalaGO’s top picks for the best hotels in Bohol!',
    link: '/where-to-stay-in-bohol-best-hotels-and-resorts',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg',
    title: 'Discover the Best Things to Do in Bohol All in One Tour!',
    date: 'November 7, 2023',
    readTime: '6',
    desc: 'Experience the best things to do in Bohol all in one tour! The Bohol countryside tour is perfect for first-time travelers to the island!',
    link: '/discover-the-best-things-to-do-in-bohol-all-in-one-tour',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/island+hopping+essentials.jpg',
    title: "What's in Our Beach Bags: Island Hopping Essentials",
    date: 'September 25, 2023',
    readTime: '4',
    desc: 'Get ready for the ultimate island-hopping experience in the Philippines with this checklist for essentials to pack in your beach bag!',
    link: '/whats-in-our-beach-bags-island-hopping-essentials',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/How+to+Survive+NAIA+Immigration.jpg',
    title: 'How to Survive NAIA Immigration',
    date: 'November 14, 2023',
    readTime: '9',
    desc: 'Scared of getting offloaded or denied through NAIA immigration? Discover theseexpert tips to fly out of the Philippines with ease!',
    link: '/how-to-survive-naia-immigration',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg',
    title: 'Guide to Travel Requirements in the Philippines',
    date: 'September 25, 2023',
    readTime: '4',
    desc: 'Stay informed and travel smart! Here’s a comprehensive, updated guide to the latest Philippines travel requirements!',
    link: '/guide-to-travel-requirements-in-the-philippines',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Boracay+Hotel+Guide+Where+to+Stay%C2%A0.jpg',
    title: 'Boracay Hotel Guide: Where to Stay',
    date: 'November 11, 2023',
    readTime: '8',
    desc: 'Are you booking a much-needed Boracay getaway? Check out our list of the best hotels in Boracay!',
    link: '/boracay-hotel-guide-where-to-stay',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Cebu+Hotels.jpg',
    title: 'GalaGO! Guide: Top Hotels to Stay at in Cebu',
    date: 'November 11, 2023',
    readTime: '6.5',
    desc: 'Top Picks from Cebu’s Diverse Hotel Scene',
    link: '/top-hotels-to-stay-at-in-Cebu',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Mobile+Travel+Photography+Tips%C2%A0.jpg',
    title: 'Mobile Travel Photography Tips',
    date: 'November 11, 2023',
    readTime: '6',
    desc: 'Don’t have an SLR? Learn how to take Instagrammable photos with your mobile phone!',
    link: '/mobile-travel-photography-tips',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Navigating+Rainy+Season+Tips+for+Traveling+in+the+Philippines%C2%A0.jpg',
    title: 'Navigating the Rainy Season: Tips for Traveling in the Philippines',
    date: 'November 11, 2023',
    readTime: '4.5',
    desc: 'Rainy season is still travel season in the Philippines. Get the tips and tricks to keep your PH adventure going despite the incoming monsoon.',
    link: '/navigating-the-rainy-season-tips-for-traveling-in-the-philippines',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Mystical+places+to+visit+in+the+Philippines.jpg',
    title: 'Beyond the Veil: 5 Mystical Places to Visit in the Philippines',
    date: 'November 11, 2023',
    readTime: '13.5',
    desc: 'Here are 10 Philippine destinations to visit if you’re looking for enchantment or supernatural thrills.',
    link: '/beyond-the-veil-5-mystical-places-to-visit-in-the-philippines',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Avoiding+Crowds_+Off-Peak+Travel+Destinations+in+the+Philippines.jpg',
    title: 'Off-Peak October Adventures: Philippine Destinations You Can Enjoy While Avoiding Crowds',
    date: 'November 11, 2023',
    readTime: '7',
    desc: 'Discover serene and uncrowded October getaways in the Philippines. Start your off-peak adventure with these destinations perfect for introverts!',
    link: '/off-peak-october-adventures-philippine-destinations-you-can-enjoy-while-avoiding-crowds',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Digital+detox_+Remote+islands+to+escape+to+in+the+Philippines.jpg',
    title: 'Unplug and Unwind: 5 Remote Islands in the Philippines for a Digital Detox',
    date: 'November 11, 2023',
    readTime: '8',
    desc: 'Escape the notifs and the screens. Recharge on breathtaking remote islands. Check out these digital detox destinations.',
    link: '/unplug-and-unwind-5-remote-islands-in-the-philippines-for-a-digital-detox',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Unique+Places+to+visit+in+Baguio.jpg',
    title: 'Baguio Staycation Guide: The Best Hotels in Baguio',
    date: 'November 11, 2023',
    readTime: '7',
    desc: 'Looking to get away from the city for some fresh air? Find out our top picks for the best hotels in Baguio!',
    link: '/baguio-staycation-guide-the-best-hotels-in-baguio',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Things+to+do+on+a+Tagaytay+Day+trip.jpg',
    title: 'Things to Do on a Tagaytay Day Trip',
    date: 'November 11, 2023',
    readTime: '9.5',
    desc: 'Are you looking for something to do away from the city? Put these Tagaytay-based activities on your weekend itinerary!',
    link: '/things-to-do-on-a-tagaytay-day-trip',
  },
  {
    image: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Unique+Places+to+visit+in+Baguio.jpg',
    title: 'Baguio Off the Beaten Path: Unique Places to Visit in Baguio',
    date: 'November 11, 2023',
    readTime: '2.5',
    desc: "Explore Baguio's hidden gems in this GalaGO! guide to unique places. From cultural havens to emerging hotspots, discover the City of Pines like never before!",
    link: '/baguio-off-the-beaten-path-unique-places-to-visit-in-baguio',
  },
];
type blogListType = {
  BlogView: 'list' | 'grid';
};
const BlogList = ({ BlogView }: blogListType) => {
  return (
    <div className={BlogView === 'list' ? 'list-wrapper' : 'grid-wrapper'}>
      {blogs.map((data: any, i) => {
        return (
          <Link to={data.link} style={{ textDecoration: 'none', color: '#333333' }} key={i}>
            <div className="blog-card-list">
              <div className="image-list-cont">
                <img src={data.image} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} loading="lazy" />
                {/* <span className="image-tag">Travel Tip</span> */}
              </div>
              <div className="blog-info-list">
                <p className="blog-title">{data.title}</p>
                <p className="text-secondary blog-date">
                  {data.date} . {data.readTime} min read
                </p>
                <p className="desc">{data.desc}</p>
                <p className="readArticle">
                  <span>Read article</span>
                  <ArrowLongRight _color="#006FA9" />
                </p>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default BlogList;
