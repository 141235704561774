import NavBarTransparent from "../../../components/NavBarTransparent";
import { ChevronRightSvg } from "../../../resources/svg/ChevronRightSvg";
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import { LinkedInSvg } from '../../../resources/svg/LinkedinSvg';
import {TwitterSvg} from '../../../resources/svg/TwitterSvg';
import {LinkSvg} from '../../../resources/svg/LinkSvg';
import React from "react";
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';

function FirstTimeFly(){
    const [fbHover, setFbHover] = React.useState('#ADADAD');
    const [liHover, setLiHover] = React.useState('#ADADAD');
    const [twtHover, setTwtHover] = React.useState('#ADADAD');
    const [linkHover, setLinkHover] = React.useState('#ADADAD');

    return (
      <>
        <NavBarTransparent />

        <div className="hero_sec">
          <img
            src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/First+time+flyer+guide/00+Cover+-+Erik+Odiin+via+Unsplash.jpeg"
            alt="Flying for the First Time? Here are Some Helpful Tips for You!"
            loading="lazy"
            onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
          />
          <div className="gradient_overlay">
            <h1>Travel Tips</h1>

            <div className="bread_crumbs">
              <a href="/#/blogs">Blogs</a>
              <ChevronRightSvg _color="#ffffff" _width={20} _height={20} />
              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
                Flying for the First Time? Here are Some Helpful Tips for You!
              </a>
            </div>
          </div>
        </div>

        <div className="articleBody">
          <div className="articleHeader">
            <h1>Flying for the First Time? Here are Some Helpful Tips for You!</h1>

            <div className="info">
              <p className="authorSec">
                By <u>Anthea Reyes</u>
              </p>
              <p className="separator">•</p>
              <p className="articleDate">Sep 29, 2023</p>
              <p className="separator">•</p>
              <p className="article-read">5 min read</p>
            </div>

            <div className="shareBtnContainer">
              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setFbHover('#006FA9')} onMouseLeave={() => setFbHover('#ADADAD')}>
                <FacebookSvg _color={fbHover} _width={24} _height={24} />
              </a>

              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLiHover('#006FA9')} onMouseLeave={() => setLiHover('#ADADAD')}>
                <LinkedInSvg _color={liHover} _width={24} _height={24} />
              </a>

              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setTwtHover('#006FA9')} onMouseLeave={() => setTwtHover('#ADADAD')}>
                <TwitterSvg _color={twtHover} _width={24} _height={24} />
              </a>

              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLinkHover('#006FA9')} onMouseLeave={() => setLinkHover('#ADADAD')}>
                <LinkSvg _color={linkHover} _width={24} _height={24} />
              </a>
            </div>
          </div>

          <div className="content">
            <div className="articleDescription">
              <p>
                Doing anything for the first time can feel like a weird mix of nerve-racking and exhilarating. This can be especially true when that first-time thing involves traveling by air and
                going to a new place.
              </p>
              <p>In cases like this, words of wisdom from people who’ve been there (and done that) can be a big help in easing your worries.</p>
              <p>Let’s get you mentally and emotionally prepared for your first foray into air travel. Here’s a quick rundown of tips for those of you who are flying for the first time ever!</p>
            </div>

            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/First+time+flyer+guide/01+Phl+Mosley+via+Unsplash.png"
                alt="First Time Fly Tips"
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />
              <p>Photo by Phil Mosley via Unsplash</p>
            </div>

            <div className="extensive_story">
              <h2>What to expect on your flight</h2>

              <div className="info_grp">
                <p>As a flyer, your experience will center around two things: the plane and the airport.</p>
                <p>
                  The airplane is a pretty straightforward concept. It’s a tube of metal with wings. To get into it, you’ll either just walk outside of your boarding gate or ride a bus. Then, you’ll
                  walk through a loading bridge and onto the actual aircraft. By the power of engineering and physics, it will fly through the air and get you to your destination.
                </p>
              </div>

              <div className="bullet_grp">
                <div className="bullet_list">
                  <p>Once inside, here’s what you can expect:</p>
                  <ul>
                    <li>Long aisles and rows of cramped seats (unless you’re sitting in business or first class).</li>
                    <li>Space underneath your chair for your carry-on.</li>
                    <li>Storage above your chair for your carry-on.</li>
                    <li>A set of safety instructions in the back pocket of the chair in front of you.</li>
                    <li>Children crying from a distance.</li>
                    <li>Children crying and/or being a menace near you.</li>
                    <li>Adults acting like children and being a menace somewhere in your immediate area.</li>
                  </ul>
                </div>
              </div>

              <div className="info_grp">
                <p>Now, onto the airport. For those flying into the Philippines, the major airport coming into the country is the Ninoy Aquino International Airport (NAIA).</p>
                <p>
                  Whether you’re an experienced traveler or a newbie, you’re sure to have heard some of the horror stories from and about NAIA. The character-building experiences in this airport are
                  as iconic as the rest of the Philippines’ natural wonders.
                </p>
              </div>

              <div className="bullet_grp">
                <div className="bullet_list">
                  <p>How do you separate fact from fiction from a worst-case scenario that could realistically happen to you? Well, here’s what you can expect:</p>
                  <ul>
                    <li>A lot of people. Plenty.</li>
                    <li>Long lines</li>
                    <li>Lounge areas that are too warm</li>
                    <li>Lounge areas that are too cold</li>
                    <li>Expensive souvenir shops</li>
                    <li>Expensive food places</li>
                    <li>Outdated but clean bathrooms</li>
                    <li>Cold, metal multi-seater airport benches</li>
                    <li>More people</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/First+time+flyer+guide/02+Danila+Hamsterman+via+Unsplash.png"
                alt="First Time Fly Tips"
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />
              <p>Photo by Danila Hamsterman via Unsplash</p>
            </div>

            <div className="extensive_story">
              <h2>Know before you go: Pre-flight things to note</h2>
              <p>Knowledge is power. Here are a few that can help make your journey smoother as you go on your first flight.</p>

              <div className="sub_content">
                <div className="head">
                  <h3>Three key parts of the airport</h3>
                  <p>As a passenger, you need to know these three key parts of the airport:</p>
                </div>

                <div className="bullet_grp">
                  <i className="title">The Terminal</i>
                  <div className="bullet_list">
                    <p>
                      Airports are usually compound-sized buildings divided into big sections. These sections are called terminals, which are assigned different numbers. It’s important to know the
                      terminal number of your flight because that’s where your boarding gate will be.
                    </p>
                    <ul>
                      <li>
                        <span>Departure vs. Arrival:</span> The departure area is usually separated into a different area, side, or level of the airport terminal.
                      </li>
                      <li>
                        <span>Boarding Gate:</span> The most important part of the airport for the passenger. As the name implies, this is where the flight attendants will call you so you can board
                        the right plane.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="bullet_grp">
                  <i className="title">Queues to line up for</i>
                  <div className="bullet_list">
                    <p>You will go through four main lines on your way to your flight.</p>
                    <ol>
                      <li>
                        <span>Security:</span> At the airport terminal’s main entrance, you and all your luggage need to pass through a security checkpoint.{' '}
                      </li>
                      <li>
                        <span>Check-in:</span> Look for your airline's counter at the terminal. You'll present your ticket and drop off your luggage for check-in. If you didn't check in online, here's
                        where get your boarding pass.
                      </li>
                      <li>
                        <span>Departures:</span> You'll need to go through security (again) before heading into the boarding gate area.
                      </li>
                      <li>
                        <span>Immigration and Customs (for international flyers):</span> More on how to survive these queues in our guide to NAIA immigration!
                      </li>
                      <li>
                        <span>Boarding Gate:</span> This is the final stage! Once it's time to board your flight, you'll need to present your boarding pass to the airline attendant at your gate. Then
                        it's straight to the boarding bridge and into your plane.
                      </li>
                    </ol>
                  </div>
                </div>

                <div className="bullet_grp">
                  <i className="title">Important paperwork to bring</i>
                  <p>To ensure that you get to your flight, there are a few documents that you absolutely cannot forget to bring.</p>
                  <div className="bullet_list">
                    <p>Remember to bring and always keep the following paperwork with you:</p>
                    <ol>
                      <li>ticket or boarding pass,</li>
                      <li>government ID, and/or</li>
                      <li>passport (for international flights)</li>
                    </ol>
                  </div>
                  <p>
                    If you’re checking in at the airport instead of online, then you need to bring your ticket (sometimes also called your itinerary receipt). If you do check in online, then you can
                    leave your ticket and just bring a print copy of your boarding pass.
                  </p>
                  <p>
                    If you’re flying domestic, you can opt to leave your passport at home and just bring any government ID. However, to be safe, just bring your passport anyway. Some airport staff can
                    get quirky and pedantic.
                  </p>
                </div>

                <div className="bullet_grp">
                  <i className="title">Luggage guidelines to remember</i>
                  <p>For people traveling by air, there are two kinds of luggage — the carry-on and the checked-in luggage.</p>
                  <p>
                    Your carry-on is the luggage that you bring onto the plane. This can be a backpack, gym bag, small suitcase, and so on. You’ll be allowed to store this above or below your seat.
                  </p>
                  <p>Checked-in luggage is usually reserved for the big suitcases weighing over 7 kilograms or so.</p>
                  <p>
                    Every airline has its own set of guidelines regarding the weight limit and list of prohibited objects for in-flight luggage. You can check these guidelines on your airline’s
                    website. Usually, they’re also sent to you via email after you book your ticket.
                  </p>
                  <p>As you pack, it’s important to keep these guidelines in mind to avoid getting additional charges and having to unpack your luggage on the spot.</p>
                </div>
              </div>
            </div>

            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/First+time+flyer+guide/03+kirklai+via+Unsplash.png"
                alt=""
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />
              <p>Photo by kirklai via Unsplash</p>
            </div>

            <div className="extensive_story">
              <h2>Tips for First-Time Flyers</h2>
              <p>Aside from basic knowledge about the what and where of flying, here are some additional tips and hacks to make your journey easier.</p>

              <div className="tip_container">
                <div className="tip">
                  <h3>Always print your paperwork</h3>
                  <p>
                    Digital flight tickets and board passes are now a thing — a convenient thing. But, the best practice would still be to print them. Just in case the airport WiFi doesn’t work on
                    your phone or if your phone runs out of battery.
                  </p>
                </div>

                <div className="tip">
                  <h3>Keep your passport and ID secure but within reach</h3>
                  <p>
                    Always place these two things in your carry-on. But also make sure they’re somewhere secure (attenzione pickpocket!) because they’re going to be your most important belongings when
                    traveling elsewhere, especially abroad.
                  </p>
                </div>

                <div className="tip">
                  <h3>Do the online check-in</h3>
                  <p>Nobody likes waiting or wasting time — and what more in an airport! So online check-in is your best friend. Doing this will help you skip some of the (very) long lines.</p>
                </div>

                <div className="tip">
                  <h3>Dress comfy, dress smart</h3>
                  <p>Your first time flying is a big deal, and it can be tempting to go all out on your airport fit. This is your friendly reminder to embrace the cute but prioritize comfort.</p>
                  <p>
                    One smart way to achieve this is by layering up. This lets you acclimate easily to changes in temperature. You can also use your jacket or coat as an improvised blanket if you’re
                    sleeping mid-flight.
                  </p>
                  <p>
                    Wear footwear that’s easy to slip on and off as well, and keep accessories to a minimum. This will help during security check-ins, where they will ask you to remove your metal
                    jewelry and footwear.
                  </p>
                </div>

                <div className="tip">
                  <h3>Arrive at the airport two to three hours before your flight</h3>
                  <p>
                    Boarding time is usually 45 minutes to an hour before the actual flight. Get to the airport two to three hours before your flight so you have enough time to check-in, go through
                    security, use the restroom, and so on.
                  </p>
                  <p>While we’re at it, always, always factor in the traffic going to the airport when estimating how much time you need to make it to your flight.</p>
                </div>

                <div className="galaGuide">
                  <h3>GalaGUIDE!</h3>
                  <p>
                    For domestic travelers, be at the airport 2 hours before boarding. For international travelers, be there 3 hours ahead. Make that 4 hours if you're flying for the first time, just
                    in case you get caught up at the immigration line!
                  </p>
                </div>

                <div className="tip">
                  <h3>Keep your ears open</h3>
                  <p>
                    Flight changes and delays happen. Keep your ears peeled for announcements made in the boarding gate area. You’ll know it has to do with you if they mention your name or your flight
                    number.
                  </p>
                </div>
              </div>
            </div>

            <div className="footnote">
              <div className="title_container">
                <h2>Take flight with confidence!</h2>
              </div>

              <div className="info">
                <p>Feel better prepared for your first flight? You should! Embrace the thrill of adventure, and focus on savoring the beauty of new experiences.</p>
                <p>
                  Haven’t booked your flight yet? Do it with GalaGO! We can help you find more affordable rates and better deals. We can also recommend premium places to visit and stay in. You can fix
                  your entire travel itinerary in one go!
                </p>
              </div>
            </div>
          </div>

          <hr />

          <div className="featured_blogs">
            <h2>Featured Blogs</h2>

            <div className="card_container">
              <div className="blog_card col-md-6 col-12">
                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg"
                    alt="Discover the Best Things to Do in Bohol All in One Tour!"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />

                  <div className="badge">
                    <h2>Travel Tip</h2>
                  </div>
                </div>

                <div className="info_grp">
                  <div className="detail">
                    <p className="date"> September 25, 2023</p>
                    <p className="separator">·</p>
                    <p className="duration">6 min read</p>
                  </div>

                  <h3>Discover the Best Things to Do in Bohol All in One Tour!</h3>

                  <a href="/#/discover-the-best-things-to-do-in-bohol-all-in-one-tour" className="read_article_btn">
                    <div className="read_btn">
                      <p>Read Article</p>
                      <ArrowRightSvg _color="#006FA9" _width={34} _height={14} />
                    </div>
                  </a>
                </div>
              </div>

              <div className="blog_card col-md-6 col-12">
                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg"
                    alt="Guide to Travel Requirements in the Philippines"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />

                  <div className="badge">
                    <h2>Travel Tip</h2>
                  </div>
                </div>

                <div className="info_grp">
                  <div className="detail">
                    <p className="date"> September 25, 2023</p>
                    <p className="separator">·</p>
                    <p className="duration">6 min read</p>
                  </div>

                  <h3>Guide to Travel Requirements in the Philippines</h3>

                  <a href="/#/guide-to-travel-requirements-in-the-philippines" className="read_article_btn">
                    <div className="read_btn">
                      <p>Read Article</p>
                      <ArrowRightSvg _color="#006FA9" _width={34} _height={14} />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
export default FirstTimeFly