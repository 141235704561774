import { Link } from 'react-router-dom';
import '../../resources/css/privacypolicy.scss';
import { useRef } from 'react';
import { useState } from 'react';
import Footer from '../../components/Footer';
import { NavBar } from '../../components/NavBar';

const PrivacyAndPolicy = () => {
  const privacy1 = useRef(null);
  const privacy2 = useRef(null);
  const privacy3 = useRef(null);
  const privacy4 = useRef(null);
  const privacy5 = useRef(null);
  const privacy6 = useRef(null);
  const privacy7 = useRef(null);
  const privacy8 = useRef(null);
  const privacy9 = useRef(null);
  const privacy10 = useRef(null);
  const privacy11 = useRef(null);
  
  const [activeButton, setActiveButton] = useState(0);

  const scrollToSection = (ref: any, buttonNumber: any) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
    if (activeButton !== buttonNumber) {
      setActiveButton(buttonNumber);
    }
  };
  return (
    <>
      <NavBar />

      <div className="privacy-header d-flex justify-center align-items-center">
        <div className="d-flex justify-content-between align-items-center w-100" style={{ maxWidth: 1370 }}>
          <p className="m-0" style={{fontSize: 28, fontWeight: 700, fontFamily: 'Satoshi'}}>Privacy Policy</p>
          <div className="d-flex p-4">
            <Link to="/help-center" style={{ textDecoration: 'none' }}>
              <p>Help Center</p>
            </Link>
          </div>
        </div>
      </div>
      <div className="" style={{ maxWidth: 1570, margin: 'auto' }}>
        <div className="privacy-info d-flex flex-column gap-4">
          <div>
            <p className="generalsans-regular text-secondary">Last Updated April 22, 2024</p>
          </div>
          <div className="privacy-content">
            <div className="privacy-group">
              <div className="privacy-intro textColor">
                <p className="intro">
                <b>WalkThePlanet Inc.</b> ("we," "us," "our") is committed to protecting the privacy of its users ("user," "you," "your"). This Privacy Policy outlines our practices concerning the collection, use, and sharing of your information through the use of our Galago mobile application ("App") and its related services (collectively, the "Services"). 
                </p>
                <p className="intro">
                By accessing or using our Services, you agree to the collection, storage, use, and disclosure of your information as described in this Privacy Policy. We are at the forefront of not only implementing but also complying with the Data Privacy Act of 2012 (DPA) and its Implementing Rules and Regulations (IRR).  
                </p>
                
               
              </div>
              <div className="privacy-text d-flex flex-column">
                <div ref={privacy1}>
                  <h1>1. WHAT WE COLLECT</h1>
                  <p>
                    As a user, we may collect and process personal information about you, including but not limited to:
                  </p>
                  <div>
                    <p>
                     a. Personal Identification Information: 
                    </p>
                    <div style={{paddingLeft: 15, margin: 0}}>
                        <p>i. Full Name </p>
                        <p>ii. Date of Birth </p>
                        <p>iii. Gender </p>
                        <p>iv. Gender </p>
                    </div>
                  </div>
                  <div>
                    <p>
                     b. Contact Information: 
                    </p>
                    <div style={{paddingLeft: 15, margin: 0}}>
                        <p>i. Email Address </p>
                        <p>ii. Phone Number </p>
                        <p>iii. Postal Address </p>
                    </div>
                  </div>
                  <div>
                    <p>
                     c. Travel Details: 
                    </p>
                    <div style={{paddingLeft: 15, margin: 0}}>
                        <p>i. Passport Information  </p>
                        <p>ii. Travel Itinerary (flights, hotels, car rentals, etc.) </p>
                        <p>iii. Frequent Flyer or Travel Reward Program Numbers </p>
                    </div>
                  </div>
                  <div>
                    <p>
                     d. Payment Information: 
                    </p>
                    <div style={{paddingLeft: 15, margin: 0}}>
                        <p>i. Credit/Debit Card Details </p>
                        <p>ii. Billing Address </p>
                        <p>iii. Payment History </p>
                    </div>
                  </div>
                  <div>
                    <p>
                     e. User Account Data: 
                    </p>
                    <div style={{paddingLeft: 15, margin: 0}}>
                        <p>i. Username </p>
                        <p>ii. Password (should be stored securely) </p>
                        <p>iii. Preferences and Interests related to travel </p>
                    </div>
                  </div>
                  <div>
                    <p>
                     f. Location Data:  
                    </p>
                    <div style={{paddingLeft: 15, margin: 0}}>
                        <p>i. Current Location (if location services are used) </p>
                        <p>ii. Travel Destinations history</p>
                    </div>
                  </div>
                  <div>
                    <p>
                     g. Device Information: 
                    </p>
                    <div style={{paddingLeft: 15, margin: 0}}>
                        <p>i. Device Type (e.g., smartphone, tablet) </p>
                        <p>ii. Operating System </p>
                        <p>iii. IP Address </p>
                        <p>iv. Browser Type </p>
                    </div>
                  </div>
                  <div>
                    <p>
                     h. Usage Data:  
                    </p>
                    <div style={{paddingLeft: 15, margin: 0}}>
                        <p>i. App Activity (pages visited, search queries, time spent, etc.) </p>
                        <p>ii. Website Cookies</p>
                        <p>iii. Interaction with advertisements </p>
                    </div>
                  </div>
                  <div>
                    <p>
                     i. Communication Data:  
                    </p>
                    <div style={{paddingLeft: 15, margin: 0}}>
                        <p>i. Customer Service Interactions </p>
                        <p>ii. Feedback and Reviews </p>
                        <p>iii. Email Correspondences </p>
                    </div>
                  </div>
                  <div>
                    <p>
                     j. Social Media Information (if applicable):  
                    </p>
                    <div style={{paddingLeft: 15, margin: 0}}>
                        <p>i. Social Media Account Details</p>
                        <p>ii. Social Media Interactions with the brand </p>
                    </div>
                  </div>
                </div>
                <div ref={privacy2}>
                  <h1>2. HOW WE COLLECT</h1>
                    <p>We employ various methods and channels to gather information from users to enhance our services and personalize the user experience. The data collection is conducted through the following means: </p>
                  <p>
                    a. Digital Forms and User Interface Interaction. We collect information when you fill out forms during registration, booking transactions, or requesting support. This includes the data input into our user interfaces on various platforms. 
                  </p>
                  <p>
                    b. Application Usage. As you navigate through our app, we collect information on your usage patterns, preferences, and any feedback you provide. This data helps us improve app functionality and user experience. 
                  </p>
                  <p>
                    c. AI-Powered Assistance. Our AI-driven tools may collect data during interactions, such as queries you input, to provide intelligent and personalized assistance. 
                  </p>
                  <p>d. Customer Service Communications. When you engage with our customer service team through calls, emails, or chat support, we may collect information provided during these interactions to assist you better and improve our service quality. </p>
                  <p>e. Social Media Interactions. Information may also be gathered through your interactions with our official social media accounts, such as messages, comments, or posts that you direct to our brand. </p>
                  <p>f. In-Person Data Collection. We may collect personal data directly from you when we have face-to-face interactions, such as during travel fairs or promotional events. </p>
                </div>
                <div ref={privacy3}>
                  <h1>3. DEVICES USED FOR COLLECTION</h1>

                  <p>
                  We collect data across various computing devices which you may use to interact with our services, including but not limited to: 
                  </p>
                  <div>
                    <p>
                     - Laptop/PCs 
                    </p>
                    <div style={{paddingLeft: 15, margin: 0}}>
                        <p>When you access our services using a desktop computer or laptop, we may collect device-specific information or usage details.</p>
                    </div>
                  </div>
                  <div>
                    <p>
                     - Mobiles/Tablets
                    </p>
                    <div style={{paddingLeft: 15, margin: 0}}>
                        <p>Our mobile applications or mobile-optimized websites may gather data specific to your usage on these devices.</p>
                    </div>
                  </div>
                  <div>
                    <p>
                     - Other Computing Devices
                    </p>
                    <div style={{paddingLeft: 15, margin: 0}}>
                        <p>This includes any other internet-enabled devices you use to engage with our services, from which we may collect usage and device-specific data.</p>
                    </div>
                  </div>
                </div>
                <div ref={privacy4}>
                  <h1>4. PURPOSE AND BASIS OF COLLECTION</h1>
                  <div>
                    <p className='privacy-teal'>Personalized Travel Services</p>
                    <p>We are dedicated to providing you with travel services tailored to your unique preferences. The basis for this data collection is the contractual obligation between us and you, the user. We require certain personal details to make and manage your travel bookings, ensuring that your travel experience is customized to your liking. </p>
                 </div>
                 <div>
                    <p className='privacy-teal'>Lifestyle Service Enhancement </p>
                    <p>Our objective is to offer you a comprehensive travel experience that extends to include your broader lifestyle choices. The collection of data for this purpose is grounded in our legitimate interest in delivering a service that goes beyond basic travel necessities, aiming to cater to your full range of travel-related preferences.</p>
                 </div>
                 <div>
                    <p className='privacy-teal'>Ancillary Services</p>
                    <p>To ensure that your travel experience is seamless, we offer additional services that may be essential to your journey. The collection of data for these ancillary services is based on the contractual obligations arising from the agreement to provide you with a complete travel package.</p>
                 </div>
                 <div>
                    <p className='privacy-teal'>User Experience Improvement</p>
                    <p>We continuously strive to improve the functionality and user-friendliness of our online platforms. Collecting data to enhance the user experience is driven by our legitimate interest in ensuring that you find our services intuitive and accessible.</p>
                 </div>
                 <div>
                    <p className='privacy-teal'>Customer Support</p>
                    <p>Providing responsive and efficient customer support requires us to collect certain information from you. This is a fundamental part of our contractual obligation to deliver excellent customer service and support throughout your interaction with our services.</p>
                 </div>
                 <div>
                    <p className='privacy-teal'>Transaction Processing</p>
                    <p>The processing of transactions, which includes bookings, payments, and issuing of travel documents, is a critical aspect of our contractual obligation to you. We collect the necessary personal information to carry out these transactions securely and efficiently.</p>
                 </div>
                 <div>
                    <p className='privacy-teal'>Marketing and Communications</p>
                    <p>We use your information to send you updates, special offers, and promotions, which is done with your consent. This consent is obtained through clear, affirmative actions, such as opting in to receive our marketing communications.</p>
                 </div>
                 <div>
                    <p className='privacy-teal'>Legal Compliance</p>
                    <p>Adhering to legal requirements is imperative for our operations. Thus, we collect and process data to fulfill our legal obligations, such as tax laws and anti-fraud measures. This ensures that we operate within the bounds of the law, specifically under the regulations set forth by the Data Privacy Act of 2012 and other relevant legislation.</p>
                 </div>
                </div>
                <div ref={privacy5}>
                  <h1>5. METHOD OF USER</h1>
                  <p>
                    Personal information shall be exclusively utilized for the purpose stated therein and shall not be used for any other unrelated purposes.
                  </p>
                </div>
                <div ref={privacy6}>
                  <h1>6. LOCATION OF PERSONAL INFORMATION</h1>
                  <p>
                  The privacy and security of our user’s personal information are of paramount importance. We have implemented comprehensive measures to ensure that your data is stored securely and accessed only for authorized purposes in accordance with our Data Privacy Policy.
                  </p>
                  <div>
                    <p className='privacy-teal'>Manual Records</p>
                    <p>Some personal data may be stored in physical form ("Manual") in secure locations within our office premises. These physical records are safeguarded in locked filing systems and are only accessible to authorized personnel who are bound by confidentiality obligations. The access to these manual records is monitored and controlled, ensuring that only necessary data is recorded and maintained in physical form to minimize risks associated with manual data storage.</p>
                 </div>
                 <div>
                    <p className='privacy-teal'>Cloud-Based Storage</p>
                    <p>We utilize cloud-based storage services provided by Alibaba Cloud ("Cloud – Alibaba") to store electronic personal data. Alibaba Cloud is known for its robust security measures, which include encryption, firewalls, and access controls. The data stored on Alibaba Cloud is protected against unauthorized access, disclosure, alteration, and destruction. We have chosen Alibaba Cloud for its adherence to international standards of data protection and its commitment to maintaining the confidentiality and integrity of the data entrusted to it.</p>
                 </div>
                 <div>
                    <p className='privacy-teal'>Internal Servers</p>
                    <p>Certain personal information may be stored on our internal servers ("Internal servers"). These servers are located in secure, access-controlled environments within our facilities. We employ industry-standard security measures, including encryption and firewall protection, to ensure that the data stored on these servers is safeguarded against unauthorized access or cyber threats. Regular security audits are conducted to maintain the high level of protection required for our internal data storage systems.</p>
                 </div>
                 <div>
                    <p className='privacy-teal'>Data Access and Transfer</p>
                    <p>Personal information stored across these various locations may be accessed by our authorized employees and data processors when necessary for the provision of our services. We ensure that such access is subject to strict organizational procedures and security protocols to maintain the integrity and confidentiality of the data.</p>
                    <p>When transferring data between manual records, cloud storage, and internal servers, we employ secure transfer methods to prevent any unauthorized interception of personal data. All data transfers are logged and monitored as part of our data governance practices.</p>
                 </div>
                 <div>
                    <p className='privacy-teal'>Compliance with Legal Requirements</p>
                    <p>We comply with the Data Privacy Act of 2012 and are committed to aligning our data storage practices with the Act's regulatory requirements and international best practices. We conduct periodic reviews and updates of our data storage facilities and methods to ensure ongoing compliance with legal and regulatory standards.</p>
                 </div>
                 <div>
                    <p className='privacy-teal'>Data Retention</p>
                    <p>The retention of personal information across all storage mediums is guided by our data retention policy, which considers the legal, regulatory, and operational requirements of retaining personal data. Personal information is retained only for as long as necessary to fulfill the purposes for which it was collected, after which it is securely disposed of or anonymized.</p>
                 </div>
                </div>
                <div ref={privacy7}>
                  <h1>7. SHARE OF YOUR INFORMATION</h1>
                  <p>
                    We may share your personal information with the following third parties:
                  </p>
                  <p className='privacy-teal'>
                  Service Providers, Partners, and Affiliates 
                  </p>
                  <p>
                  Some personal data may be stored in physical form ("Manual") in secure locations within our office premises. These physical records are safeguarded in locked filing systems and are only accessible to authorized personnel who are bound by confidentiality obligations. The access to these manual records is monitored and controlled, ensuring that only necessary data is recorded and maintained in physical form to minimize risks associated with manual data storage.
                  </p>
                  <p>
                  All these entities, including our affiliate companies, are granted access to the personal information they need to perform their functions and are bound by contractual obligations to uphold the confidentiality and security of that information. Strict limitations are imposed on their use of your information: they are expressly prohibited from using, selling, distributing, or altering your data in any manner other than in the pursuit of providing the designated services directly associated with our App. 
                  </p>
                  <p className='privacy-teal'>
                  Compliance and Harm Prevention 
                  </p>
                  <p>We may disclose your information to third parties if we determine that such disclosure is reasonably necessary to: </p>
                  <p>a. Comply with any applicable law, regulation, legal process, or governmental request. </p>
                  <p>b. Protect against harm to the rights, property, or safety of WalkThePlanet, our users, or the public as required or permitted by law. </p>
                </div>

                <div ref={privacy8}>
                  <h1>8. DATA SECURITY</h1>
                  <p>We implement appropriate technical and organizational measures to ensure a level of security appropriate to the risk to the personal information we process. These measures aim to ensure the integrity and confidentiality of personal information. </p>
                </div>

                <div ref={privacy9}>
                  <h1>9. RETENTION PERIOD OF INFORMATION.</h1>
                  <div>
                    <p className='privacy-teal'>Data Retention Principles </p>
                    <p>We adhere to strict data retention principles to ensure the privacy and security of our user's personal information. We retain personal data for no longer than is necessary for the purposes for which the data was collected and processed. </p>
                 </div>
                 <div>
                    <p className='privacy-teal'>Standard Retention Duration </p>
                    <p>The standard retention period for personal information collected by WalkThePlanet Inc. is three years from the date of the last transaction with our user. This period aligns with our operational requirements and legal obligations, providing us sufficient time to refer to the data for post-transactional activities, including customer service, accounting, and legal issues. </p>
                 </div>
                 <div>
                    <p className='privacy-teal'>Exceptions and Contested Cases </p>
                    <p>If the validity of the information is contested, we will retain the personal data for as long as it is necessary to resolve the issue. During this period, the data will be handled according to the nature of the contestation and in compliance with the applicable legal requirements. </p>
                 </div>
                 <div>
                    <p className='privacy-teal'>Deletion and Data Subject Requests </p>
                    <p>Following the resolution of any contestation or legal proceedings, we will delete the personal information from our records securely and promptly. </p>
                 </div>
                 <div>
                    <p className='privacy-teal'>Right to Request Deletion </p>
                    <p>User have the right to request the deletion of their personal data. Upon such a request, we will evaluate the necessity of retaining the data against the client's request and proceed accordingly. We will honor the deletion request if no legal or operational reasons require us to keep the data. </p>
                 </div>
                 <div>
                    <p className='privacy-teal'>Data Retention Policy Review </p>
                    <p>WalkThePlanet Inc. regularly reviews its data retention policy to ensure it remains in compliance with Philippine laws and international best practices. This includes the Data Privacy Act of 2012, which provides the framework for data handling procedures, including the retention and disposal of personal data. </p>
                 </div>
                 <div>
                    <p className='privacy-teal'>Secure Disposal </p>
                    <p>Upon the expiration of the retention period or following a deletion request, data disposal will be conducted securely and in such a manner that the information cannot be reconstructed or read. </p>
                 </div>
                </div>
                <div ref={privacy10}>
                  <h1>10. HOW TO PARTICIPATION OF DATA SUBJECT</h1>
                  <p>
                  You have the right to access, correct, or delete your personal information. You may also have the right to restrict or object to the processing of your personal information and, where applicable, the right to data portability. If you wish to exercise these rights or have any concerns about the processing of your personal information, please contact our Data Protection Officer (DPO) at the contact details provided below. 
                  </p>
                 
                </div>
                <div ref={privacy11}>
                  <h1>11. HOW TO INQUIRE</h1>
                  <p>
                  WalkThePlanet Inc.
                  </p>
                  <p>
                  1803-1804 The Philippine Stock Exchange Tower, 5th corner 28th Avenue, Bonifacio Global City, Taguig 
                  </p>
                  <p>
                  privacy1@walktheplanet.com 
                  </p>
                </div>
              </div>
            </div>
            <div className="privacy-table card shadow-sm mb-5 bg-white rounded-lg drop-shadow-md">
              <div className="card-body" id="card1">
                <div className="privacy-card-title">Table of Contents</div>
                <p style={{ color: activeButton === 1 ? '006FA9' : '' }} onClick={() => scrollToSection(privacy1, 1)}>
                  1. WHAT WE COLLECT
                </p>
                <p style={{ color: activeButton === 2 ? '006FA9' : '' }} onClick={() => scrollToSection(privacy2, 2)}>
                  2. HOW WE COLLECT
                </p>
                <p style={{ color: activeButton === 3 ? '006FA9' : '' }} onClick={() => scrollToSection(privacy3, 3)}>
                  3. DEVICES USED FOR COLLECTION
                </p>
                <p style={{ color: activeButton === 4 ? '006FA9' : '' }} onClick={() => scrollToSection(privacy4, 4)}>
                  4. PURPOSE AND BASIS OF COLLECTION
                </p>
                <p style={{ color: activeButton === 5 ? '006FA9' : '' }} onClick={() => scrollToSection(privacy5, 5)}>
                  5. METHOD OF USER
                </p>
                <p style={{ color: activeButton === 6 ? '006FA9' : '' }} onClick={() => scrollToSection(privacy6, 6)}>
                  6. LOCATION OF PERSONAL INFORMATION
                </p>
                <p
                  style={{ color: activeButton === 7 ? '006FA9' : '' }}
                  onClick={() => {
                    scrollToSection(privacy7, 7);
                  }}
                >
                  7. SHARE OF YOUR INFORMATION
                </p>
                <p style={{ color: activeButton === 8 ? '006FA9' : '' }} onClick={() => scrollToSection(privacy8, 8)}>
                  8. DATA SECURITY
                </p>
                <p style={{ color: activeButton === 9 ? '006FA9' : '' }} onClick={() => scrollToSection(privacy9, 9)}>
                  9. RETENTION PERIOD OF INFORMATION
                </p>
                <p style={{ color: activeButton === 10 ? '006FA9' : '' }} onClick={() => scrollToSection(privacy10, 10)}>
                  10. HOW TO PARTICIPATION OF DATA SUBJECT
                </p>
                <p style={{ color: activeButton === 11 ? '006FA9' : '' }} onClick={() => scrollToSection(privacy11, 11)}>
                  11. HOW TO INQUIRE
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyAndPolicy;
