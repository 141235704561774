import React from 'react';
import { SvgProps } from './SvgProps';

export const DoorOpenSvg = ({ _width = 14, _height = 15, _color = '#006FA9' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11071_49614)">
        <path
          d="M10.9995 1.89574C11.2148 1.9268 11.3749 2.11317 11.3749 2.33324V13.0607C11.3749 13.2808 11.2148 13.4672 10.9995 13.4982L5.7495 14.2551C5.6871 14.2638 5.62355 14.259 5.56317 14.241C5.50279 14.223 5.447 14.1922 5.3996 14.1507C5.35219 14.1092 5.31428 14.0579 5.28845 14.0005C5.26261 13.943 5.24946 13.8806 5.24988 13.8176V12.947H3.08513C2.96324 12.9466 2.84644 12.8981 2.76022 12.812C2.67399 12.7258 2.62534 12.6091 2.62488 12.4872V2.90724C2.62488 2.65349 2.83182 2.44655 3.08513 2.44655H5.24988V1.57636C5.24988 1.30774 5.48569 1.10124 5.7495 1.1393L10.9995 1.89574ZM6.56238 8.13449C6.67841 8.13449 6.78969 8.08839 6.87174 8.00635C6.95378 7.9243 6.99988 7.81302 6.99988 7.69699C6.99988 7.58095 6.95378 7.46967 6.87174 7.38763C6.78969 7.30558 6.67841 7.25949 6.56238 7.25949C6.44635 7.25949 6.33507 7.30558 6.25302 7.38763C6.17097 7.46967 6.12488 7.58095 6.12488 7.69699C6.12488 7.81302 6.17097 7.9243 6.25302 8.00635C6.33507 8.08839 6.44635 8.13449 6.56238 8.13449ZM3.49988 12.072H5.24988V3.32199H3.49988V12.072Z"
          fill={_color}
        />
      </g>
      <defs>
        <clipPath id="clip0_11071_49614">
          <rect width="14" height="14" fill="white" transform="translate(0 0.697266)" />
        </clipPath>
      </defs>
    </svg>
  );
};
