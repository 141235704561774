import NavBarTransparent from "../../../components/NavBarTransparent";
import { ChevronRightSvg } from "../../../resources/svg/ChevronRightSvg";
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import {LinkedInSvg} from '../../../resources/svg/LinkedinSvg';
import {TwitterSvg} from '../../../resources/svg/TwitterSvg';
import {LinkSvg} from '../../../resources/svg/LinkSvg';
import React from "react";
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';

const NavigatingRainySeason = () =>{
  const [fbHover, setFbHover] = React.useState('#ADADAD');
  const [liHover, setLiHover] = React.useState('#ADADAD');
  const [twtHover, setTwtHover] = React.useState('#ADADAD');
  const [linkHover, setLinkHover] = React.useState('#ADADAD');

  return (
    <>
      <NavBarTransparent />
      <div className="hero_sec">
        <img
          src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Navigating+the+Rainy+Season/Blog+Header.jpg"
          alt=""
          loading="lazy"
          onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
        />
        <div className="gradient_overlay">
          <h1>Travel Tips</h1>

          <div className="bread_crumbs">
            <a href="/#/blogs">Blogs</a>
            <ChevronRightSvg _color="#ffffff" _width={20} _height={20} />
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
              Navigating the Rainy Season: Tips for Traveling in the Philippines
            </a>
          </div>
        </div>
      </div>

      <div className="articleBody">
        <div className="articleHeader">
          <h1>Navigating the Rainy Season: Tips for Traveling in the Philippine</h1>

          <div className="info">
            <p className="authorSec">
              {' '}
              By <u>Anthea Reyes</u>
            </p>
            <p className="separator">•</p>
            <p className="articleDate">Nov 11, 2023</p>
            <p className="separator">•</p>
            <p className="article-read">4.5 min read</p>
          </div>

          <div className="shareBtnContainer">
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setFbHover('#006FA9')} onMouseLeave={() => setFbHover('#ADADAD')}>
              <FacebookSvg _color={fbHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLiHover('#006FA9')} onMouseLeave={() => setLiHover('#ADADAD')}>
              <LinkedInSvg _color={liHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setTwtHover('#006FA9')} onMouseLeave={() => setTwtHover('#ADADAD')}>
              <TwitterSvg _color={twtHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLinkHover('#006FA9')} onMouseLeave={() => setLinkHover('#ADADAD')}>
              <LinkSvg _color={linkHover} _width={24} _height={24} />
            </a>
          </div>
        </div>

        <div className="content">
          <div className="articleDescription">
            <p>
              As the archipelagic gem of Southeast Asia, the Philippines is known for sunny days at the beach, breathtaking sunrise views while hiking up a mountain, and warm hospitality from
              welcoming locals. The Philippines may be known as a summer tropical paradise, but it doesn’t stop being a piece of heaven once the monsoon season arrives.
            </p>

            <p>
              For those planning a trip, you can enjoy a whole new side to the pearl of the orient during the country's wet season, which typically runs from June to November. Here are some tips and
              tricks for travelers keen on embracing the beauty of the Philippines during the rainy season.
            </p>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Navigating+the+Rainy+Season/02+Photo+by+Mario+von+Rotz+via+Unsplash.png"
              alt=""
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by Mario von Rotz via Unsplash</p>
          </div>

          <div className="extensive_story">
            <h2>Tips for traveling in the Philippines during the rainy season</h2>
            <p>
              From packing waterproof gear to exploring indoor cultural gems, here are some essential tips to ensure a memorable adventure despite the occasional downpour during the Philippines’ wet
              season.
            </p>

            <div className="tip_container">
              <div className="tip">
                <h3>Pack Smart</h3>
                <p>
                  With unpredictable weather on the horizon, packing strategically is crucial. Opt for lightweight, quick-drying clothing and waterproof accessories such as ponchos, umbrellas, and
                  waterproof backpacks. If you’re traveling within a city, it’s also important to bring rain boots as many areas are prone to momentary flooding.{' '}
                </p>
                <p>Don't forget to safeguard electronics and important documents in waterproof pouches, ensuring your valuables stay dry during sudden downpours.</p>
              </div>

              <div className="tip">
                <h3>Choose Your Destinations Wisely</h3>
                <p>
                  While some areas may experience intense rainfall, others may offer a reprieve. Consider destinations like Palawan and Cebu, which often see milder weather during the rainy season.
                </p>
                <p>Exploring these regions allows you to enjoy the Philippines' natural beauty without being constantly drenched.</p>
              </div>
            </div>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Navigating+the+Rainy+Season/03+Photo+by+Gabriele+Ibba+via+Unsplash.png"
              alt=""
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by Gabriele Ibba via Unsplash</p>
          </div>

          <div className="extensive_story">
            <div className="tip_container">
              <div className="tip">
                <h3>Engage in Rainy Season Activities</h3>
                <p>
                  Use the rain showers as an opportunity. Embrace the unique activities you can do during the wet seasons. For the outdoorsy type, you can explore surfing, white water rafting, or
                  wakeboarding if the rain is just a light shower. For the indoor-lover, the Philippines has a rich offering of museums to explore, indoor sports activities to try, and indulgent spas
                  to relax in.
                </p>
              </div>
            </div>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Navigating+the+Rainy+Season/04+Photo+by+Roselle+Miranda+via+Yummy.png"
              alt=""
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by Roselle Miranda via Yummy.PH</p>
          </div>

          <div className="extensive_story">
            <div className="tip_container">
              <div className="tip">
                <h3>Culinary Exploration</h3>
                <p>
                  The rainy season is also a wonderful opportunity to get familiar with the Philippines’ own version of comfort food. Warm yourself with a steaming bowl of bulalo (beef bone marrow
                  soup) or enjoy a comforting serving of lugaw (rice porridge) at a local eatery. For the sweet-toothed traveler, bilo-bilo is a must try among other uniquely heartwarming (almost
                  literally) dishes in the country.
                </p>
                <p>Exploring the diverse culinary landscape is an excellent way to experience the warmth of Filipino culture amid the raindrops.</p>
              </div>

              <div className="tip">
                <h3>Stay Flexible</h3>
                <p>
                  Flexibility is key when traveling during the rainy season. Be open to adjusting your itinerary based on weather conditions. Can’t go spelunking? Enjoy a relaxing lunch in a heritage
                  nipa hut instead. Can’t push through with your food crawl? Use the freed up time to learn how to make a Filipino dish from scratch at your accommodations.
                </p>
                <p>This adaptability ensures that your travel plans remain enjoyable despite the occasional showers.</p>
              </div>
            </div>
          </div>

          <div className="footnote">
            <div className="title_container">
              <h2>No rain on your GalaGO! Parade</h2>
            </div>

            <div className="info">
              <p>
                Traveling during the rainy season in the Philippines may present its challenges, but with the right mindset and preparation, it becomes an opportunity for a unique and memorable
                adventure.{' '}
              </p>
              <p>With the right preparations and mindset, you'll not only navigate the wet season but also create lasting memories of a truly authentic Filipino experience. </p>
              <p>
                So, grab your phone (or laptop or tablet) and visit GalaGO! now to find your next Philippine travel destination. Embark on a journey that reveals the Philippines in a different light –
                one that shines even in the rain.
              </p>
            </div>
          </div>
        </div>

        <hr />

        <div className="featured_blogs">
          <h2>Featured Blogs</h2>
          <div className="card_container">
            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg"
                  alt="Discover the Best Things to Do in Bohol All in One Tour!"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Discover the Best Things to Do in Bohol All in One Tour!</h3>

                <a href="/#/discover-the-best-things-to-do-in-bohol-all-in-one-tour" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#006FA9" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>

            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg"
                  alt="Guide to Travel Requirements in the Philippines"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Guide to Travel Requirements in the Philippines</h3>

                <a href="/#/guide-to-travel-requirements-in-the-philippines" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#006FA9" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NavigatingRainySeason;