import { SvgProps } from './SvgProps';

export const InfoCircle = ({ _width = 15, _height = 15, _color = '#006FA9' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 48095693">
        <circle id="Ellipse 1113" cx="7.49998" cy="9.99888" r="0.6" transform="rotate(-180 7.49998 9.99888)" fill={_color} />
        <path
          id="Vector"
          d="M7.5 8.19878L7.5 3.99878M13.5 6.99878C13.5 10.3125 10.8137 12.9988 7.5 12.9988C4.18629 12.9988 1.5 10.3125 1.5 6.99878C1.5 3.68507 4.18629 0.998779 7.5 0.998779C10.8137 0.998779 13.5 3.68507 13.5 6.99878Z"
          stroke={_color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
