import React from 'react';
import { SvgProps } from './SvgProps';

export const PersonSvg = ({ _width = 12, _height = 13, _color = '#006FA9' }: SvgProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 4.73684C5 7.34842 7.24333 9.47368 10 9.47368C12.7567 9.47368 15 7.34842 15 4.73684C15 2.12526 12.7567 0 10 0C7.24333 0 5 2.12526 5 4.73684ZM18.8889 20H20V18.9474C20 14.8853 16.51 11.5789 12.2222 11.5789H7.77778C3.48889 11.5789 0 14.8853 0 18.9474V20H18.8889Z"
        fill="#006FA9"
      />
    </svg>
  );
};
