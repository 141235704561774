import NavBarTransparent from '../../../components/NavBarTransparent';
import { ChevronRightSvg } from '../../../resources/svg/ChevronRightSvg';
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import { LinkedInSvg } from '../../../resources/svg/LinkedinSvg';
import { TwitterSvg } from '../../../resources/svg/TwitterSvg';
import { LinkSvg } from '../../../resources/svg/LinkSvg';
import React from 'react';
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';

function BoholTours() {
  const [fbHover, setFbHover] = React.useState('#ADADAD');
  const [liHover, setLiHover] = React.useState('#ADADAD');
  const [twtHover, setTwtHover] = React.useState('#ADADAD');
  const [linkHover, setLinkHover] = React.useState('#ADADAD');

  return (
    <>
      <NavBarTransparent />

      <div className="hero_sec">
        <img
          src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Things+to+do+in+bohol/00+COVER.png"
          alt="GalaGO! Guide: Things to Do in Bohol"
          loading="lazy"
          onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
        />
        <div className="gradient_overlay">
          <h1>Top Destination</h1>

          <div className="bread_crumbs">
            <a href="/#/blogs">Blogs</a>
            <ChevronRightSvg _color="#ffffff" _width={20} _height={20} />
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
              GalaGO! Guide: Things to Do in Bohol
            </a>
          </div>
        </div>
      </div>

      <div className="articleBody">
        <div className="articleHeader">
          <h1>GalaGO! Guide: Things to Do in Bohol</h1>

          <div className="info">
            <p className="authorSec">
              {' '}
              By <u>Zoe Alcazaren</u>
            </p>
            <p className="separator">•</p>
            <p className="articleDate">Oct 9, 2023</p>
            <p className="separator">•</p>
            <p className="article-read">8 min read</p>
          </div>

          <div className="shareBtnContainer">
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setFbHover('#006FA9')} onMouseLeave={() => setFbHover('#ADADAD')}>
              <FacebookSvg _color={fbHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLiHover('#006FA9')} onMouseLeave={() => setLiHover('#ADADAD')}>
              <LinkedInSvg _color={liHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setTwtHover('#006FA9')} onMouseLeave={() => setTwtHover('#ADADAD')}>
              <TwitterSvg _color={twtHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLinkHover('#006FA9')} onMouseLeave={() => setLinkHover('#ADADAD')}>
              <LinkSvg _color={linkHover} _width={24} _height={24} />
            </a>
          </div>
        </div>

        <div className="content">
          <div className="articleDescription">
            <p>
              Whether you’re a first-timer or a returning traveler, a trip to Bohol is never dull. Beyond the typical Chocolate Hills day trips and Tarsier sanctuary tours is a plethora of unearthed
              gems waiting to be discovered.
            </p>
            <p>If your Bohol to-do list is scarce, this guide will provide an impressive arsenal of sights and activities.</p>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Things+to+do+in+bohol/01+eduardo-casajus-gorostiaga+unsplash.png"
              alt="Bohol"
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by Eduardo Casajús Gorostiaga via Unsplash</p>
          </div>

          <div className="textual_story">
            <h2>When should you visit Bohol?</h2>
            <p>While hot year-round, Bohol’s weather can be unforgiving. Thus, knowing when the rainy and peak seasons are can help narrow your timeline.</p>
            <p>
              Locals say the best times to visit are between January and late March. While December and April are popular times to visit, you don’t want to get caught amid the holiday rush—unless you
              have speedy booking fingers.
            </p>
            <p>
              From April to July, Bohol's weather becomes increasingly rainy. While temperatures are cooler and more bearable for those with sun sensitivities, you may feel assaulted by the humidity.
            </p>
            <p>Regardless of when you visit, practicing thorough sun protection and packing according to the weather is essential.</p>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Things+to+do+in+bohol/02+Hitoshi_Namura.png"
              alt="Bohol"
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by Hitoshi Namura via Unsplash</p>
          </div>

          <div className="textual_story">
            <h2>What is Bohol known for?</h2>
            <p>Besides its white-sand beaches and five-star resorts, Bohol is famed for its unique attractions, natural diversity, and delicious food.</p>
            <p>If you’re a fan of historical and religious sites, you’ll be pleased to know Bohol is rife with stunning churches—some date as far back as the Spanish colonial period!</p>
            <p>Are you an animal lover? Then Bohol is the perfect place to be, as it’s home to spinner dolphins, whale sharks, tarsiers, sunbirds, and other marine life.</p>
            <p>Adventure-seekers will also love what Bohol offers—stand-up paddleboarding, river cruises, cycling, and a rich collection of water sports.</p>
            <p>Overall, Bohol has something in store for everyone, whether you prefer to immerse yourself in nature, watch the sunset with a book, or go on an unforgettable food trip.</p>
          </div>

          <div className="storieswithTitleImageParagraphs">
            <div className="head">
              <h2>Things to do in Bohol</h2>

              <div className="head_desc">
                <p>Are you ready to put your Bohol itinerary together? Consider these Bohol tourist spots and activities.</p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Half-Day Panglao Tour</h2>
                <div className="img_container">
                  <img
                    className="image"
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Things+to+do+in+bohol/03+lorenz+narbs+via+Unsplash.png"
                    alt=""
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by lorenz narbs via Unsplash</p>
                </div>
              </div>

              <div className="info-grp">
                <div className="bullet_grp">
                  <div className="bullet_list">
                    <p>Bohol’s signature half-day Panglao tour covers every aspect of the island, from churches to caves. Expect to see the following stops on your tour:</p>

                    <ul>
                      <li>
                        <span>Dauis Church:</span> Alternatively known as Our Lady of the Assumption, the Dauis Church is a National Historic Landmark with neoclassical exteriors. More on this below,
                        when we talk about the Bohol Pilgrimage Tour!
                      </li>

                      <li>
                        <span> Nova Shell Museum:</span> As its name suggests, the Nova Shell Museum features Bohol’s enchanting shells, which you won’t find anywhere else in the world! Plus, owner
                        Quirino Hora’s collection is the most varied in the South Pacific. Here, you’ll see the world’s smallest shell, Rotaovula Hirohitoi.
                      </li>

                      <li>
                        <span> Hinagdanan Cave:</span> Accessible from the Chocolate Hills, this stunning cave features a pool of crystal-clear water under mesmerizing stalagmites. The cave is named
                        for how it was discovered—a farmer once built a ladder to access it. In World War II, Hinagdanan Cave was a hideout for locals escaping from the Japanese Imperial Army.
                      </li>

                      <li>
                        <span> St. Augustine Parish:</span> Also known as the Panglao Church, the St. Augustine Parish is known for its beautifully painted ceilings and marble floors.
                      </li>

                      <li>
                        <span>Panglao Watchtower:</span> Built in the 1850s, the Panglao Watchtower was the tallest built during the Spanish colonial period. It has five stories with a pitched roof
                        cover and was part of the colonial military defense system against raiders. Viewing the Panglao Watchtower feels like revisiting a piece of history.
                      </li>

                      <li>
                        <span>Alona Beach:</span> As Bohol’s top beach destination, Alona Beach is unmissable. It’s known for its white sand, clear waters, and access to luxury resorts. The beach was
                        named after local actress Alona Alegre, who shot a film on location in the 1970s.
                      </li>
                    </ul>

                    <p>With your GalaGO! tour package, you’ll get a tourist driver, fully air-conditioned transport, and entrance fees covered.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Island Hopping</h2>
                <div className="img_container">
                  <img
                    alt=""
                    className="image"
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Things+to+do+in+bohol/04+Rene+Padillo+on+Unsplash.png"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by Rene Padillo on Unsplash</p>
                </div>
              </div>

              <div className="info-grp">
                <p>
                  Especially when visiting in the summer, an island tour should be top on your list of things to do in Bohol! With your GalaGO! tour package, you’ll see <span>Balicasag Island</span>,
                  a prime diving spot and home to the <span>Balicasag Marine Sanctuary</span>.
                </p>

                <p>
                  At the sanctuary, you can swim with the Philippines’ friendliest marine animals, including sea turtles and impressive schools of jacks. Nearby is Turtle Point, where many turtles
                  hide in caves and occasionally sneak a peek at passersby.
                </p>

                <p>This area also offers a generous handful of diving options, with spots for beginners and veterans.</p>

                <p>
                  Another stop is the <span>Virgin Islands</span>, a stunning moon-shaped sandbar perfect for sunbathing, swimming, or posing meticulously for the ‘Gram. While it isn’t known for
                  over-the-top water sports or a hotspot for marine animals, it’s the best place to sit back and relax with your fresh cup of buko juice.
                </p>

                <p>
                  <span>Pamilacan Island</span> will likely be your favorite stop if you're up for some dolphin-watching. Plus, if you’re lucky, you’ll see some rays and whales. Like Balicasag Island,
                  Pamilacan Island is home to many diving sites, with its most notable being Dakit-Dakit. Other options include Snake Island and Cervera Shoal. Expect the current in these areas to be
                  more challenging and rife with sea snakes, turtles, and moray eels.
                </p>

                <p>
                  Thanks to the GalaGO! tour package, you can make the most of your island-hopping day in air-conditioned transportation to and from the boat-docking areas, a tourist driver, a local
                  dive guide, light snacks, and all your fees covered (boat, entrance, environmental).
                </p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Abatan River Firefly Watching</h2>
                <div className="img_container">
                  <img
                    className="image"
                    alt=""
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Things+to+do+in+bohol/05+Hitoshi+Namura+on+Unsplash.png"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by Hitoshi Namura on Unsplash</p>
                </div>
              </div>

              <div className="info-grp">
                <p>
                  Are you a night owl? If so, a Bohol night excursion might be more to your taste. Fireflies often overtake Abatan’s centuries-old mangrove forest, lighting up the entire 20 km
                  stretch!
                </p>

                <p>Throughout the tour, you’ll glimpse several small villages and 32 mangrove species. The tour lasts roughly one hour.</p>

                <p>The GalaGO! tour includes all entrance fees, transportation, a Palina ritual and orientation upon arrival, a local guide, and a kayak or bandong boat ride.</p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Firefly & Full Moon Stand-Up Paddle Tour</h2>
              </div>

              <div className="info-grp">
                <p>
                  So you’ve seen the Abatan fireflies. Are you ready to witness the same fascinating species in Loboc on a stand-up paddleboard? This unique nighttime tour takes you to the mother
                  tree, where all fireflies retire after a night of lighting up the river. The tour takes up to two hours.
                </p>

                <p>Your GalaGO! tour package covers your entrance fee, transportation to and from the tour location, a local guide, and a paddle board with a life vest.</p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Sikatuna’s Mirror of the World & Botanical Garden</h2>
              </div>

              <div className="info-grp">
                <p>
                  There is nothing quite as bizarrely unique as Sikatuna’s Mirror of the World and Botanical Garden. Here, you’ll see the world’s most famous tourist attractions on a miniature
                  scale—the Statue of Liberty, the Eiffel Tower, the Golden Gate Bridge, the Redeemer Statue, the Acropolis, and many more!
                </p>

                <p>
                  The property was once a 3.2-acre farmland and is now an Instagram-worthy wonderland. There’s also a botanical garden, home to dozens of endemic flower species. If you’re traveling
                  with children, they’ll surely enjoy the playground and koi pond.
                </p>

                <p>Your GalaGO! tour package includes air-conditioned transport, entrance fees, and a tourist driver.</p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Farm Tours</h2>
                <div className="img_container">
                  <img
                    className="image"
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Things+to+do+in+bohol/06+Rene+Padillo+via+unsplash.png"
                    loading="lazy"
                    alt=""
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by Rene Padillo via Unsplash</p>
                </div>
              </div>

              <div className="info-grp">
                <p>Bohol is one of the most sustainable regions in the Philippines, so it shouldn’t come as a surprise that the island is rife with farm tours.</p>

                <p>
                  Through GalaGO, you can experience the <span>Isola Leisure Farm</span>, home to an organic farm where you can pick herbs and vegetables. You’ll meet Hubbard chickens, turkeys, goats,
                  rabbits, and quails on the same tour. Then, you’ll enjoy a fresh island meal of high-protein seafood and signature Visayan dishes.
                </p>

                <p>
                  After lunch, your tour guide will take you on a quick boat ride to visit the nearby aquaculture farm, which hosts a variety of local fish. You’ll also get a taste of fresh sea greens
                  from the aquaculture seaweed farm.
                </p>

                <p>
                  Speaking of food lovers, the next stop on the farm tour is the <span>Green Thumb Farm</span>, home to gastronomic delights that feature mushrooms. The restaurant is al fresco, and
                  its menu is, of course, mushroom-forward. So, whether you’re a pizza, siomai, steak, or sisig lover, you can bet you’ll find it in mushroom form at the Green Thumb Farm.
                </p>

                <p>
                  The last stop on the farm tour is the <span>South Farm</span>, a 9-hectare property featuring family-oriented tourism committed to sustainability. Here, you can take a leisurely farm
                  walk, picking herbs and spices to sprinkle onto a delectable dish.
                </p>

                <p>
                  Guests can also try their hand at multigenerational workshops like clay pot crafting, native basket weaving, and coconut oil making. If you’re an animal lover, you’re in luck—there
                  are plenty to feed and pet!
                </p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Strawberry Farm & Alicia Panoramic Tour</h2>
                <div className="img_container">
                  <img
                    className="image"
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Things+to+do+in+bohol/07+C%C3%A9sar+Badilla+Miranda+via+Unsplash.png"
                    loading="lazy"
                    alt=""
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by César Badilla Miranda via Unsplash</p>
                </div>
              </div>

              <div className="info-grp">
                <p>
                  Baguio and Benguet aren’t the only strawberry hotspots in the country! The <span>Bohol Organic and Strawberry Farm</span>, run by the{' '}
                  <span>Mayana Upper Sampong Farmers Association (MUFSA)</span> offers succulent sweets and other sustainable vegetables. Even better, you’ll get a spectacular view of the verdant
                  hills, Bohol’s highest peak. After a morning of strawberry picking, you can enjoy a much-needed coffee at the on-site cafe!
                </p>

                <p>
                  The <span>Alicia Panoramic Tour</span> poses a highly-Instagrammable challenge for the more active tourists. It makes for a strenuous hike, but guests will be rewarded with an
                  unforgettable view of Alicia Panoramic Park.
                </p>

                <p>
                  Other highlights from this specific tour package include visiting the <span>Cadapdapan Rice Terraces</span>, often likened to the stunning rice fields in Bali. Another stop is{' '}
                  <span>Calamay County</span>, where guests will enjoy hand-making kalamay, a signature delicacy made from well-milled glutinous rice with coconut milk and sugar.
                </p>

                <p>Of course, your GalaGO! tour package will cover all entrance fees and transportation.</p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Pilgrimage Tour</h2>
                <div className="img_container">
                  <img
                    className="image"
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Things+to+do+in+bohol/08+Ros+via+unsplash.png"
                    loading="lazy"
                    alt=""
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Pilgrimage Tour</p>
                </div>
              </div>

              <div className="info-grp">
                <div className="bullet_grp">
                  <p>
                    As a predominantly Catholic country, the Philippines is familiar with faith tourism, and you’ll get to witness the best parts of it in Bohol. The GalaGO! pilgrimage tour package
                    for the spiritual traveler is a goldmine of the country’s most beautiful churches.
                  </p>

                  <div className="bullet_list">
                    <p>On this tour, you’ll visit the following highlights:</p>

                    <ul>
                      <li>
                        <span>St. Joseph the Worker Cathedral (Tagbilaran):</span>
                        Established in the late 1500s, the St. Joseph the Worker Cathedral, also known as the Tagbilaran Cathedral, boasts a beautiful Neo-Romanesque structure with streamlined
                        windows. Its inside walls don historical images of St. Joseph and St. Vincent Ferrer. You’ll get a stunning view of the Tagbilaran Bay behind the cathedral, perfect for a
                        moment of reflection.
                      </li>

                      <li>
                        <span>St. Augustine Parish Church (Panglao):</span>
                        As mentioned earlier, it’s also known as the Baliwag Church! The St. Augustine Parish Church was established in 1733 and it has a beautiful Baroque style and massive reredos.
                        It also boasts a commanding bell tower with a relief of Moses on its lower portion. The church also hosts one of the longest Lenten precessions in the Philippines.
                      </li>

                      <li>
                        <span>Our Lady of the Assumption Parish Church (Dauis):</span>
                        The Dauis Church rests upon a strip of land that offers beautiful views of Tagbilaran City. The building combines Gothic and neoclassical architecture with a temple-like
                        interior that houses the image of Our Lady of the Assumption.
                      </li>

                      <li>
                        <span>Our Lady of Immaculate Conception Parish Church (Baclayon):</span>
                        Declared a National Cultural Treasure by the National Museum of the Philippines, the Baclayon Church is a historical landmark built of coral stones. Unfortunately, the church
                        was heavily impacted by the 2013 Bohol earthquake, damaging much of its original structure. However, you can still glimpse its original murals peeking underneath restored
                        sections.
                      </li>

                      <li>
                        <span>Sta. Monica Parish Church (Albuquerque):</span>
                        In 2013, the National Museum of the Philippines declared Sta. Monica Parish Church an Important Cultural Property. By 2014, the church had received its historical marker. While
                        its original altar no longer exists, many of the original structure still stands.
                      </li>

                      <li>
                        <span>Holy Trinity Parish Church (Loay):</span>
                        The Holy Trinity Parish Church is located near the mouth of the Loboc River which hosts two facades. This immaculate church with stunning ceiling murals was once a formidable
                        fortress built to withstand rebellious attacks.
                      </li>

                      <li>
                        <span>St. Nicholas Parish Church (Dimiao):</span>
                        The Dimiao Church blends Baroque and Muslim influences, donning blind arch windows and ornate pilasters. Its pipe organ dates back to the 19th century and has neoclassical
                        altars. If you’re visiting in May, don’t miss out on the Pamakwan Festival, during which you’ll explore the city’s vibrant culture and traditions within the church.
                      </li>
                    </ul>

                    <p>During your pilgrimage tour, you’ll get an assigned DOT tour guide and lunch at Rio Verde, the famous Loay Floating Restaurant.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footnote">
            <div className="title_container">
              <h2>See the Best of Bohol with GalaGO!</h2>
            </div>

            <div className="info">
              <p>
                Bohol isn’t just a picturesque province you’ll see on postcards and Philippine tourism calendars. It’s a destination filled to the brim with natural wonders, a unique history, and
                endless activities. With a travel partner like GalaGO, enjoying what Bohol offers is as easy as booking your trip online!
              </p>
            </div>
          </div>
        </div>

        <hr />

        <div className="featured_blogs">
          <h2>Featured Blogs</h2>
          <div className="card_container">
            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg"
                  alt="Discover the Best Things to Do in Bohol All in One Tour!"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Discover the Best Things to Do in Bohol All in One Tour!</h3>

                <a href="/#/discover-the-best-things-to-do-in-bohol-all-in-one-tour" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#006FA9" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>

            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg"
                  alt="Guide to Travel Requirements in the Philippines"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Guide to Travel Requirements in the Philippines</h3>

                <a href="/#/guide-to-travel-requirements-in-the-philippines" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#006FA9" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default BoholTours;
