import React, { useState } from 'react';
import { AirlineLogo } from '../../../utils/airlines-logo';
import { GalagoButton } from '../../../components/Button';
// import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';
import { FlightResultsHook } from '../../../hooks/FlightResultsHook';
import { dateFormat, formatPriceNoRound } from '../../../utils/data.utils';
import { BaggageSvg } from '../../../resources/svg/BaggageSvg';
import { FlightInfoStore } from '../../../store/FlightInfoStore';
// import { RoundTripFlightHook } from '../../../hooks/RoundTripFlightHook';
import { Col, Container, Row } from 'react-bootstrap';
import { CheckInSvg } from '../../../resources/svg/CheckInSvg';
import { LongArrowSvg } from '../../../resources/svg/longArrow.svg';
import { QuestionCircleSvg } from '../../../resources/svg/questionCircleSvg';

type FlightListProps = {
  info?: any;
  seeFlightButtonClicked?: () => void;
  // flightType?: 'one-way' | 'round-trip';
  // isRoundTripReturnFlights?: boolean;
  // roundTripFlights: { [index: string]: any } | undefined;
  fromAirportCode?: any;
  toAirportCode?: any;
};

// eslint-disable-next-line
export const OneWayFlightList = ({ info, seeFlightButtonClicked, fromAirportCode, toAirportCode }: FlightListProps) => {
  // eslint-disable-next-line
  const { setFlightInfo, setShowFlightInfoModal } = FlightInfoStore();
  const { flightSegments, flightFares } = FlightResultsHook();

  // eslint-disable-next-line
  // const { roundTripFlightSegment } = RoundTripFlightHook();

  const { departureTime, arrivalTime, stops, departureCode, arrivalCode, carrierCode, flightNumber, flightDuration, departureDate, arrivalDate } = flightSegments(info?.OriginDestinations);
  // console.log(flightDuration);
  const formattedDepartureDate = dateFormat(departureDate);
  const formattedArrivalDate = dateFormat(arrivalDate);

  const { MarketingCarriercode, MarketingFlightNumber } = flightSegments(info?.OriginDestinations);
  // console.log(info.OriginDestinations, 'mam ilin');

  // const { SeatsRemaining } = info?.OriginDestinations[0].ItineraryReference;

  // console.log(SeatsRemaining, 'seatsRemaining');

  const { adult, currency } = flightFares(info?.FlightFares);
  // console.log({ adult });
  const { ValidatingCarrier } = info;
  const airline = AirlineLogo?.find((airline: { [index: string]: any }) => airline.id === ValidatingCarrier);
  // console.log(info);

  const price = adult.adultBaseFare + adult.adultTax;
  const Baggage = info?.OriginDestinations?.[0]?.ItineraryReference || null;

  // State to track baggage hover
  const [isBaggageTooltipVisible, setIsBaggageTooltipVisible] = useState(false);

  const handleBaggageMouseEnter = () => setIsBaggageTooltipVisible(true);
  const handleBaggageMouseLeave = () => setIsBaggageTooltipVisible(false);

  return (
    <>
      {/* <div className='flight-filter-result-container flex gap-2'> */}

      <div className="flight-result-border arrival-flights-list flex align-items-center" key={info.FareSourceCode}>
        <div className="flight-result-item arrival-flights-list flex flex-col align-items-start">
          <div className="w-100">
            <div className="airline-logo-holder">
              <div className="image-container" style={{ width: '50', height: '50', borderRadius: '50%', overflow: 'hidden' }}>
                <img src={airline?.logo} alt="airline logo" loading="lazy" width="50" className="img-fluid" />
              </div>

              <p className="w-100 generalsans-regular flex flex-col" style={{ fontFamily: 'Satoshi', fontSize: '13px' }}>
                {airline?.name}
                {/* <p style={{ color: '#BD0A00', fontSize: '12px', fontWeight: '700' }}>{SeatsRemaining} seats remaining!</p> */}
              </p>
            </div>

            {/* Hidden */}
            <div className="airline-info d-none">
              <span className="w-100 generalsans-regular text-secondary data-head-text">Flight Number</span>
              <span className="w-100 data-text">
                {MarketingCarriercode}
                {MarketingFlightNumber}
              </span>
            </div>

            <div className="departure-location">
              <span className="w-100 data-head-text">{departureTime} </span>
              <span className="w-100 data-text">
                {departureCode} <span className="text-secondary">|</span> {formattedDepartureDate}
              </span>
            </div>

            <div className="duration-stops">
              <span className="w-100 data-text">{flightDuration}</span>
              <LongArrowSvg _width={100} _height={16} />
              <span className="w-100 data-head-text">{stops}</span>
            </div>

            <div className="arrival-location">
              <span className="w-100 data-head-text">{arrivalTime}</span>
              <span className="w-100 data-text">
                {arrivalCode} <span className="text-secondary">|</span> {formattedArrivalDate}
              </span>
            </div>
          </div>
        </div>

        <div className="flight-price-info ">
          <div
            className="inclusion-section"
            onMouseEnter={handleBaggageMouseEnter}
            onMouseLeave={handleBaggageMouseLeave}
            style={{ border: isBaggageTooltipVisible ? '2px solid #C8E2E8' : '2px solid white' }}
          >
            <span className="d-block">
              <BaggageSvg _width={16} _height={16} _color="#006FA9" />
            </span>
            {Baggage?.CheckinBaggage?.[0]?.Value !== '0PC' && (
              <span className="d-block">
                <CheckInSvg _width={16} _height={16} _color="#006FA9" />
              </span>
            )}
            <p className="included mt-1">included</p>
            <span>
              <QuestionCircleSvg _width={16} _height={16} _color="#006FA9" />
            </span>
          </div>

          {/* Baggage Tooltip */}
          {isBaggageTooltipVisible && (
            <div className="baggage-tooltip">
              <div>
                <span>
                  <BaggageSvg _width={24} _height={24} _color="#006FA9" />
                </span>
                <p>
                  The flight includes <span>Cabin Baggage</span>
                </p>
              </div>
              {Baggage?.CheckinBaggage?.[0]?.Value !== '0PC' && (
                <div>
                  <span>
                    <CheckInSvg _width={24} _height={24} _color="#006FA9" />
                  </span>
                  <p>
                    The flight includes <span>Checked-in baggage</span>
                  </p>
                </div>
              )}
            </div>
          )}

          <div className="font-satoshi text-price">
            {currency} {formatPriceNoRound(price)} / pax
          </div>
          <div>
            <GalagoButton
              btn="primary"
              size="sm"
              label={'Select Flight'}
              onClick={() => {
                setFlightInfo(info);
                setShowFlightInfoModal(true);
              }}
            />
          </div>
        </div>
      </div>
      {/* </div> */}

      {/* Mobile */}
      <div
        className="oneway-result-item"
        onClick={() => {
          setFlightInfo(info);
          setShowFlightInfoModal(true);
        }}
      >
        <div className="oneway-card">
          <div id="airline-details">
            <div className="airlogo">
              <img src={airline?.logo} alt="airline logo" loading="lazy" />
            </div>
            <p>{airline?.name}</p>
            <span>&#x2022;</span>
            <p>{carrierCode}</p>
            <span>&#x2022;</span>
            <p>{flightNumber}</p>
          </div>
          <hr />
          <Container fluid id="flight-details">
            <Row>
              <Col className="first-Col">
                <p id="from">{departureCode} (Manila)</p>
                <p id="time">06:00</p>
                <p id="day">Sun, Nov 27</p>
              </Col>
              <Col className="second-Col">
                <p id="trip-type">Direct</p>
                <p id="arrow">--------------</p>
                <p id="duration">3h 45m</p>
              </Col>
              <Col className="third-Col">
                <p id="to">{arrivalCode}</p>
                <p id="time">06:00</p>
                <p id="day">Sun, Nov 27</p>
              </Col>
            </Row>
          </Container>
          <hr className="dashed" />
          <div id="baggage-total">
            <div>
              <BaggageSvg _width={15} _height={15} _color="#006FA9" />
            </div>
            <p>
              {currency} {formatPriceNoRound(adult.adultBaseFare)} /pax
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
